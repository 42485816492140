import { TableConfig } from 'lib/tables/TableConfig';
import { TableTab } from 'lib/tables/TableTab';
import { HybridView } from 'lib/tables/HybridView';

import { MOVEMENT_EVENTS_APPROVED_UBNS } from 'store/entities/referenceTables/movementEventsApprovedUbns/config';

const { columns, view, serialized } = MOVEMENT_EVENTS_APPROVED_UBNS.MovementEventsApprovedUbns;

export const movementEventsApprovedUbnsTableConfig = new TableConfig(serialized, [
  new TableTab({
    tableOptions: {
      sorting: true,
      search: false,
    },
    label: view,
    dataKey: serialized,
    rowMenu: () => null,
    rowContextMenu: undefined,
    headers: [
      new HybridView({
        settings: {
          title: columns.ubn.view,
          field: columns.ubn.serialized,
        },
      }),
    ],
  }),
]);
