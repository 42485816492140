import { MOVEMENT_EVENTS_APPROVED_UBNS_FIELDS } from 'lib/excel/config/referenceTables/movementEventsApprovedUbns/structure';
import { DocumentData } from 'lib/excel/serilizers/Sheet';
import { DocumentViewer } from 'lib/excel/view/Document';
import { SheetViewer } from 'lib/excel/view/Sheet';
import { CellViewer } from 'lib/excel/view/Cell';

const { columns, serialized, view } = MOVEMENT_EVENTS_APPROVED_UBNS_FIELDS.MovementEventsApprovedUbns;

export const getMovementEventsApprovedUbnsView = (data: DocumentData) => {
  return new DocumentViewer(data, [
    new SheetViewer(serialized, view, [
      new CellViewer({ serializedPropName: columns.ubn.serialized, viewPropName: columns.ubn.view })
    ]),
  ]);
};
