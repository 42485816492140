import { IntlKeys } from 'lib/localization/keys/__keys';

enum DeliveriesAuthorisedSuppliersOriginal {
  DeliveriesAuthorisedSuppliers = 'Deliveries authorised suppliers',
}

enum DeliveriesAuthorisedSuppliersSerialized {
  DeliveriesAuthorisedSuppliers = 'DeliveriesAuthorisedSuppliers',
}

enum DeliveriesAuthorisedSuppliersUnserialized {
  DeliveriesAuthorisedSuppliers = 'DeliveriesAuthorisedSuppliers',
}

enum DeliveriesAuthorisedSuppliersOriginalHeaders {
  'feedSupplierName' = 'Feedsupplier name',
  'supplierNumber' = 'Supplier number',
}

enum DeliveriesAuthorisedSuppliersSerializedHeaders {
  'feedSupplierName' = 'feedSupplierName',
  'supplierNumber' = 'supplierNumber',
}
enum DeliveriesAuthorisedSuppliersUnserializedHeaders {
  'feedSupplierName' = 'feedSupplier_name',
  'supplierNumber' = 'supplier_number',
}

export const DELIVERIES_AUTHORISED_SUPPLIERS_FIELDS = {
  [DeliveriesAuthorisedSuppliersUnserialized.DeliveriesAuthorisedSuppliers]: {
    original: DeliveriesAuthorisedSuppliersOriginal.DeliveriesAuthorisedSuppliers,
    serialized: DeliveriesAuthorisedSuppliersSerialized.DeliveriesAuthorisedSuppliers,
    unserialized: DeliveriesAuthorisedSuppliersUnserialized.DeliveriesAuthorisedSuppliers,
    view: IntlKeys.referenceTablesDeliveriesAuthorisedSuppliers, //
    viewName: 'Deliveries authorised suppliers',

    columns: {
      [DeliveriesAuthorisedSuppliersSerializedHeaders.feedSupplierName]: {
        original: DeliveriesAuthorisedSuppliersOriginalHeaders.feedSupplierName,
        serialized: DeliveriesAuthorisedSuppliersSerializedHeaders.feedSupplierName,
        unserialized: DeliveriesAuthorisedSuppliersUnserializedHeaders.feedSupplierName,
        view: IntlKeys.referenceTablesDeliveriesAuthorisedSuppliersFeedSupplierName,
      },
      [DeliveriesAuthorisedSuppliersSerializedHeaders.supplierNumber]: {
        original: DeliveriesAuthorisedSuppliersOriginalHeaders.supplierNumber,
        serialized: DeliveriesAuthorisedSuppliersSerializedHeaders.supplierNumber,
        unserialized: DeliveriesAuthorisedSuppliersUnserializedHeaders.supplierNumber,
        view: IntlKeys.referenceTablesDeliveriesAuthorisedSuppliersSupplierNumber,
      },
    },
  },
};
