import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';
import { ResourceWrapper } from 'lib/http/utils';

import { REFERENCE_TABLES_DASHBOARD } from './config';

export type ReferenceTablesDashboardPayload = {
  statuses_by_type: {
    pen_level_upload: string;
    pen_type_sow_barn_upload: string;
    answer_code_upload: string;
    co2_emission_factor_upload: string;
    carbon_foot_print_upload: string;
    ipcc_emission_factor_upload: string;
    feed_print_upload: string;
    content_of_nitric_oxide_upload: string;
    pig_housing_emission_factor_upload: string;
    electricity_consumption_rate_upload: string;
    gas_consumption_rate_upload: string;
    water_consumption_rate_upload: string;
    rav_code_upload: string;
    gwp100_factor_upload: string;
    wur_mcf_efem_upload: string;
    pig_disease_code_upload: string;
    charge_or_premium_code_type_upload: string;
    group_charge_or_premium_code_upload: string;
    animal_id_code_type_upload: string;
    supplier_number_upload: string;
    country_code_upload: string;
    rav_reference_list_upload: string;
    allocation_manure_upload: string;
    greenhouse_gas_emission_upload: string;
    entities_list_upload: string;
    deliveries_authorised_supplier_upload: string;
    movement_events_approved_ubns_upload: string;
    slaughterhouse_data_values_upload: string;
  };
};

export type ReferenceTablesDashboardSerialized = {
  pensLevel: string;
  pensTypeSowBarn: string;
  yesNo: string;
  cfpCo2EmissionFactor: string;
  cvbTable: string;
  cfpGfliFeedprintTable: string;
  ipccEmissionFactors: string;
  contentOfNitricOxide: string;
  pigHousingEmissionFactors: string;
  electricityConsumptionRates: string;
  gasConsumptionRates: string;
  waterConsumptionRates: string;
  ravCodes: string;
  gwp100Factors: string;
  wurMcfEfem: string;
  cl550: string;
  cl649: string;
  cl650: string;
  cl607: string;
  suppliersNumbers: string;
  countryCodes: string;
  ravReferenceList: string;
  allocationManure: string;
  greenhouseGasEmission: string;
  entitiesList: string;
  deliveriesAuthorisedSuppliers: string;
};

const { columns, serialized, unserialized } = REFERENCE_TABLES_DASHBOARD.ReferenceTablesDashboard;

const getDefaultSerializer = (data: DocumentData<ReferenceTablesDashboardPayload[]>) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(unserialized, serialized, [
      new CellSerializer(columns.pensLevel.unserialized, columns.pensLevel.serialized),
      new CellSerializer(columns.pensTypeSowBarn.unserialized, columns.pensTypeSowBarn.serialized),
      new CellSerializer(columns.yesNo.unserialized, columns.yesNo.serialized),
      new CellSerializer(columns.cfpCo2EmissionFactor.unserialized, columns.cfpCo2EmissionFactor.serialized),
      new CellSerializer(columns.cvbTable.unserialized, columns.cvbTable.serialized),
      new CellSerializer(columns.cfpGfliFeedprintTable.unserialized, columns.cfpGfliFeedprintTable.serialized),
      new CellSerializer(columns.ipccEmissionFactors.unserialized, columns.ipccEmissionFactors.serialized),
      new CellSerializer(columns.contentOfNitricOxide.unserialized, columns.contentOfNitricOxide.serialized),
      new CellSerializer(columns.pigHousingEmissionFactors.unserialized, columns.pigHousingEmissionFactors.serialized),
      new CellSerializer(
        columns.electricityConsumptionRates.unserialized,
        columns.electricityConsumptionRates.serialized,
      ),
      new CellSerializer(columns.gasConsumptionRates.unserialized, columns.gasConsumptionRates.serialized),
      new CellSerializer(columns.waterConsumptionRates.unserialized, columns.waterConsumptionRates.serialized),
      new CellSerializer(columns.ravCodes.unserialized, columns.ravCodes.serialized),
      new CellSerializer(columns.gwp100Factors.unserialized, columns.gwp100Factors.serialized),
      new CellSerializer(columns.wurMcfEfem.unserialized, columns.wurMcfEfem.serialized),
      new CellSerializer(columns.cl550.unserialized, columns.cl550.serialized),
      new CellSerializer(columns.cl649.unserialized, columns.cl649.serialized),
      new CellSerializer(columns.cl650.unserialized, columns.cl650.serialized),
      new CellSerializer(columns.cl607.unserialized, columns.cl607.serialized),
      new CellSerializer(columns.suppliersNumbers.unserialized, columns.suppliersNumbers.serialized),
      new CellSerializer(columns.countryCodes.unserialized, columns.countryCodes.serialized),
      new CellSerializer(columns.ravReferenceList.unserialized, columns.ravReferenceList.serialized),
      new CellSerializer(columns.allocationManure.unserialized, columns.allocationManure.serialized),
      new CellSerializer(columns.greenhouseGasEmission.unserialized, columns.greenhouseGasEmission.serialized),
      new CellSerializer(columns.entitiesList.unserialized, columns.entitiesList.serialized),
      new CellSerializer(columns.deliveriesAuthorisedSuppliers.unserialized, columns.deliveriesAuthorisedSuppliers.serialized),
      new CellSerializer(columns.slaughterhouseDataValues.unserialized, columns.slaughterhouseDataValues.serialized),
    ]),
  ]);
};

export const serializeReferenceTablesDashboard = (serverData: ResourceWrapper<ReferenceTablesDashboardPayload>) => {
  const data = {
    [unserialized]: [serverData.data.resource],
  };

  const serializer = getDefaultSerializer(data);

  return serializer.serialize()[serialized][0];
};
