import { SLAUGHTERHOUSE_DATA_VALUES_FIELDS } from 'lib/excel/config/referenceTables/slaughterhouseDataValues/structure';
import { DocumentData } from 'lib/excel/serilizers/Sheet';
import { DocumentViewer } from 'lib/excel/view/Document';
import { SheetViewer } from 'lib/excel/view/Sheet';
import { CellViewer } from 'lib/excel/view/Cell';

const { columns, serialized, view } = SLAUGHTERHOUSE_DATA_VALUES_FIELDS.SlaughterhouseDataValues;

export const getSlaughterhouseDataValuesView = (data: DocumentData) => {
  return new DocumentViewer(data, [
    new SheetViewer(serialized, view, [
      new CellViewer({ serializedPropName: columns.value.serialized, viewPropName: columns.value.view }),
      new CellViewer({ serializedPropName: columns.unit.serialized, viewPropName: columns.unit.view }),
    ]),
  ]);
};
