import { MOVEMENT_EVENTS_APPROVED_UBNS_FIELDS } from 'lib/excel/config/referenceTables/movementEventsApprovedUbns/structure';
import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';

const { columns, serialized, original } = MOVEMENT_EVENTS_APPROVED_UBNS_FIELDS.MovementEventsApprovedUbns;

export const serializeMovementEventsApprovedUbnsData = (data: DocumentData) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(original, serialized, [
      new CellSerializer(columns.ubn.original, columns.ubn.serialized)
    ]),
  ]);
};
