import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';
import { ResourcesWrapper } from 'lib/http/utils';

import { MOVEMENT_EVENTS_APPROVED_UBNS } from './config';
import { ManageUploadPayload } from '../manageUploads';

export type MovementEventsApprovedUbnsPayload = {
  id: number;
  ubn: string | null;
  movement_events_approved_ubns_upload: ManageUploadPayload;
};

export type MovementEventsApprovedUbnsSerialized = {
  id: number;
  referenceUploadID: number;
  ubn: string;
  uploadType: string;
  status: 'active' | 'inactive';
  filename: string;
  validDateFrom: string | null;
  validDateTo: string | null;
  notes: string;
  concepts: string[];
  uploadedAt: string;
  uploadedBy: string;
};

const { columns, serialized, unserialized } = MOVEMENT_EVENTS_APPROVED_UBNS.MovementEventsApprovedUbns;

const getDefaultSerializer = (data: DocumentData<MovementEventsApprovedUbnsPayload[]>) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(unserialized, serialized, [
      new CellSerializer(columns.id.unserialized, columns.id.serialized),
      new CellSerializer(columns.referenceUploadID.unserialized, columns.referenceUploadID.serialized),
      new CellSerializer(columns.ubn.unserialized, columns.ubn.serialized),
      new CellSerializer(columns.uploadType.unserialized, columns.uploadType.serialized),
      new CellSerializer(columns.status.unserialized, columns.status.serialized),
      new CellSerializer(columns.filename.unserialized, columns.filename.serialized),
      new CellSerializer(
        columns.validDateFrom.unserialized,
        columns.validDateFrom.serialized,
        undefined,
        undefined,
        null,
      ),
      new CellSerializer(columns.validDateTo.unserialized, columns.validDateTo.serialized, undefined, undefined, null),
      new CellSerializer(columns.notes.unserialized, columns.notes.serialized),
      new CellSerializer(columns.concepts.unserialized, columns.concepts.serialized),
      new CellSerializer(columns.uploadedAt.unserialized, columns.uploadedAt.serialized),
      new CellSerializer(columns.uploadedBy.unserialized, columns.uploadedBy.serialized),
    ]),
  ]);
};

export const serializeMovementEventsApprovedUbns = (serverData: ResourcesWrapper<MovementEventsApprovedUbnsPayload>) => {
  const data = {
    [unserialized]: serverData.data.resources,
  };

  const serializer = getDefaultSerializer(data);

  return serializer.serialize();
};
