import { IntlKeys } from 'lib/localization/keys';

enum UserPassportsOriginal {
  UserPassports = 'user_passports',
}

enum UserPassportsSerialized {
  UserPassports = 'UserPassports',
}

enum UserPassportsUnserialized {
  UserPassports = 'UserPassports',
}

export enum UserPassportsOriginalHeaders {
  concepts = 'concepts',
  concept = 'concept',
  kvk = 'kvk',
  ubn = 'ubn',
  ubnAliases = 'passport_aliases',
  customUbnName = 'custom_ubn_name',
  registrationName = 'registration_name',
  typeOfCompany = 'type_of_company',
  organization = 'organization',
  passportOwners = 'passport_owners',
  permissionProfileTemplateId = 'permission_profile_template_id',
  organizationPermissionProfile = 'organization_permission_profile',
  labels = 'labels',
  companyAvatar = 'logo',
}

export enum UserPassportsSerializedHeaders {
  concepts = 'concepts',
  concept = 'concept',
  kvk = 'kvk',
  ubn = 'ubn',
  ubnAliases = 'ubnAliases',
  customUbnName = 'customUbnName',
  registrationName = 'registrationName',
  typeOfCompany = 'typeOfCompany',
  organization = 'organization',
  passportOwners = 'passportOwners',
  permissionProfileTemplateId = 'permissionProfileTemplateId',
  organizationPermissionProfile = 'organizationPermissionProfile',
  labels = 'labels',
  companyAvatar = 'companyAvatar',
}

export enum UserPassportsUnserializedHeaders {
  concepts = 'concepts',
  concept = 'concept',
  kvk = 'kvk',
  ubn = 'ubn',
  ubnAliases = 'passport_aliases',
  customUbnName = 'custom_ubn_name',
  registrationName = 'registration_name',
  typeOfCompany = 'type_of_company',
  organization = 'organization',
  passportOwners = 'passport_owners',
  permissionProfileTemplateId = 'permission_profile_template_id',
  organizationPermissionProfile = 'organization_permission_profile',
  labels = 'labels',
  companyAvatar = 'logo',
}

export const USER_PASSPORTS = {
  [UserPassportsSerialized.UserPassports]: {
    original: UserPassportsOriginal.UserPassports,
    serialized: UserPassportsSerialized.UserPassports,
    unserialized: UserPassportsUnserialized.UserPassports,
    view: IntlKeys.titleUserPassports,
    viewName: 'Organization Passports',

    columns: {
      [UserPassportsSerializedHeaders.concepts]: {
        original: UserPassportsOriginalHeaders.concepts,
        serialized: UserPassportsSerializedHeaders.concepts,
        unserialized: UserPassportsUnserializedHeaders.concepts,
        view: IntlKeys.organizationsConcepts,
      },
      [UserPassportsSerializedHeaders.concept]: {
        original: UserPassportsOriginalHeaders.concept,
        serialized: UserPassportsSerializedHeaders.concept,
        unserialized: UserPassportsUnserializedHeaders.concept,
        view: IntlKeys.concept,
      },
      [UserPassportsSerializedHeaders.kvk]: {
        original: UserPassportsOriginalHeaders.kvk,
        serialized: UserPassportsSerializedHeaders.kvk,
        unserialized: UserPassportsUnserializedHeaders.kvk,
        view: IntlKeys.kvk,
      },
      [UserPassportsSerializedHeaders.ubn]: {
        original: UserPassportsOriginalHeaders.ubn,
        serialized: UserPassportsSerializedHeaders.ubn,
        unserialized: UserPassportsUnserializedHeaders.ubn,
        view: IntlKeys.primeUbn,
      },
      [UserPassportsSerializedHeaders.ubnAliases]: {
        original: UserPassportsOriginalHeaders.ubnAliases,
        serialized: UserPassportsSerializedHeaders.ubnAliases,
        unserialized: UserPassportsUnserializedHeaders.ubnAliases,
        view: IntlKeys.ubnAliases,
      },
      [UserPassportsSerializedHeaders.customUbnName]: {
        original: UserPassportsOriginalHeaders.customUbnName,
        serialized: UserPassportsSerializedHeaders.customUbnName,
        unserialized: UserPassportsUnserializedHeaders.customUbnName,
        view: IntlKeys.userPassportUbnCustomName,
      },
      [UserPassportsSerializedHeaders.registrationName]: {
        original: UserPassportsOriginalHeaders.registrationName,
        serialized: UserPassportsSerializedHeaders.registrationName,
        unserialized: UserPassportsUnserializedHeaders.registrationName,
        view: IntlKeys.organizationsRegistrationName,
      },
      [UserPassportsSerializedHeaders.typeOfCompany]: {
        original: UserPassportsOriginalHeaders.typeOfCompany,
        serialized: UserPassportsSerializedHeaders.typeOfCompany,
        unserialized: UserPassportsUnserializedHeaders.typeOfCompany,
        view: IntlKeys.organizationsTypeOfCompany,
      },
      [UserPassportsSerializedHeaders.organization]: {
        original: UserPassportsOriginalHeaders.organization,
        serialized: UserPassportsSerializedHeaders.organization,
        unserialized: UserPassportsUnserializedHeaders.organization,
        view: IntlKeys.id,
      },
      [UserPassportsSerializedHeaders.passportOwners]: {
        original: UserPassportsOriginalHeaders.passportOwners,
        serialized: UserPassportsSerializedHeaders.passportOwners,
        unserialized: UserPassportsUnserializedHeaders.passportOwners,
        view: IntlKeys.id,
      },
      [UserPassportsSerializedHeaders.permissionProfileTemplateId]: {
        original: UserPassportsOriginalHeaders.permissionProfileTemplateId,
        serialized: UserPassportsSerializedHeaders.permissionProfileTemplateId,
        unserialized: UserPassportsUnserializedHeaders.permissionProfileTemplateId,
        view: IntlKeys.adminPermissionProfileTemplate,
      },
      [UserPassportsSerializedHeaders.organizationPermissionProfile]: {
        original: UserPassportsOriginalHeaders.organizationPermissionProfile,
        serialized: UserPassportsSerializedHeaders.organizationPermissionProfile,
        unserialized: UserPassportsUnserializedHeaders.organizationPermissionProfile,
        view: IntlKeys.organizationsPermissionProfile,
      },
      [UserPassportsSerializedHeaders.labels]: {
        original: UserPassportsOriginalHeaders.labels,
        serialized: UserPassportsSerializedHeaders.labels,
        unserialized: UserPassportsUnserializedHeaders.labels,
        view: IntlKeys.titleLabels,
      },
      [UserPassportsSerializedHeaders.companyAvatar]: {
        original: UserPassportsOriginalHeaders.companyAvatar,
        serialized: UserPassportsSerializedHeaders.companyAvatar,
        unserialized: UserPassportsUnserializedHeaders.companyAvatar,
        view: IntlKeys.companyAvatar,
      },
    },
  },
};
