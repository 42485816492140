import { SLAUGHTERHOUSE_DATA_VALUES_FIELDS } from 'lib/excel/config/referenceTables/slaughterhouseDataValues/structure';
import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';

const { columns, serialized, original } = SLAUGHTERHOUSE_DATA_VALUES_FIELDS.SlaughterhouseDataValues;

export const serializeSlaughterhouseDataValuesData = (data: DocumentData) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(original, serialized, [
      new CellSerializer(columns.value.original, columns.value.serialized),
      new CellSerializer(columns.unit.original, columns.unit.serialized),
    ]),
  ]);
};
