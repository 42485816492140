import { MOVEMENT_EVENTS_APPROVED_UBNS_FIELDS } from 'lib/excel/config/referenceTables/movementEventsApprovedUbns/structure';
import { DocumentValidator, ExcelSheets } from 'lib/excel/validators/Document';
import { SheetValidator } from 'lib/excel/validators/Sheet';
import { CellValidator } from 'lib/excel/validators/Cell';

const { original, columns, viewName } = MOVEMENT_EVENTS_APPROVED_UBNS_FIELDS.MovementEventsApprovedUbns;

export const getMovementEventsApprovedUbnsDocumentValidator = (data: ExcelSheets) => {
  return new DocumentValidator(data, [
    new SheetValidator(original, viewName, [
      new CellValidator(columns.ubn.original)
    ]),
  ]);
};
