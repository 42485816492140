import { IntlKeys } from 'lib/localization/keys/__keys';

enum MovementEventsApprovedUbnsOriginal {
  MovementEventsApprovedUbns = 'Movement events approved UBNs',
}

enum MovementEventsApprovedUbnsSerialized {
  MovementEventsApprovedUbns = 'MovementEventsApprovedUbns',
}

enum MovementEventsApprovedUbnsUnserialized {
  MovementEventsApprovedUbns = 'MovementEventsApprovedUbns',
}

enum MovementEventsApprovedUbnsOriginalHeaders {
  'ubn' = 'UBN'
}

enum MovementEventsApprovedUbnsSerializedHeaders {
  'ubn' = 'ubn',
}
enum MovementEventsApprovedUbnsUnserializedHeaders {
  'ubn' = 'ubn',
}

export const MOVEMENT_EVENTS_APPROVED_UBNS_FIELDS = {
  [MovementEventsApprovedUbnsUnserialized.MovementEventsApprovedUbns]: {
    original: MovementEventsApprovedUbnsOriginal.MovementEventsApprovedUbns,
    serialized: MovementEventsApprovedUbnsSerialized.MovementEventsApprovedUbns,
    unserialized: MovementEventsApprovedUbnsUnserialized.MovementEventsApprovedUbns,
    view: IntlKeys.referenceTablesMovementEventsApprovedUbns, //
    viewName: 'Movement events approved UBNs',

    columns: {
      [MovementEventsApprovedUbnsSerializedHeaders.ubn]: {
        original: MovementEventsApprovedUbnsOriginalHeaders.ubn,
        serialized: MovementEventsApprovedUbnsSerializedHeaders.ubn,
        unserialized: MovementEventsApprovedUbnsUnserializedHeaders.ubn,
        view: IntlKeys.referenceTablesMovementEventsApprovedUbnsUbn,
      },
    },
  },
};
