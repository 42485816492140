import { get } from 'lodash';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { CellSerializer, PropertyPath } from 'lib/excel/serilizers/Cell';
import { dateToFormattedString, serializeObjectSnakeCaseToCamelCase } from 'lib/excel/serilizers/utils';
import { ResourcesWrapper, ResourceWrapper } from 'lib/http/utils';
import { SERVICE_FIELDS } from 'lib/excel/config/service';
import { USER_PASSPORTS, UserPassportsUnserializedHeaders } from 'lib/tables/config/userPassports/config';
import { AdminUserSerialized, AdminUserUnserialized, serializeAdminUsers } from 'store/entities/admin/users';
import { ADMIN_USERS } from 'lib/tables/config/admin/users/config';
import {
  PermissionProfileResponseEntry,
  PermissionProfileResponseEntrySerialized,
  serializePermissionProfile,
} from '../admin/entities';
import { LabelPayload, LabelSerialized } from './monitoringDashboard/labels';
import {
  serializeUserPassportConcepts,
  UserPassportConceptPayload, UserPassportConcepts,
  UserPassportConceptSerialized
} from './userPassportConcepts';

const { columns, serialized, unserialized } = USER_PASSPORTS.UserPassports;

export interface UbnAliasesSerialized {
  value: string;
}

export interface UserPassportPayload {
  id: number;
  registration_name: string;
  kvk: string;
  concepts: UserPassportConceptPayload[];
  ubn: string;
  type_of_company: string;
  passport_aliases: UbnAliasesSerialized[];
  custom_ubn_name: string | null;

  passport_owners: Array<{
    id: number;
    user_type: string;
    email: string;
    name: string;
  }>;
  organization: {
    id: number;
    email: string;
    kvk: string;
    kvk_company_name: string;
    logo: {
      url: string;
    };
  };

  [UserPassportsUnserializedHeaders.permissionProfileTemplateId]: number | null;
  [UserPassportsUnserializedHeaders.organizationPermissionProfile]: PermissionProfileResponseEntry | null;

  labels: LabelPayload[];

  created_at: string;
  updated_at: string;
  updated_by: {
    email: string;
    name: string;
  };
}

export interface UserPassportTransformedPayload {
  id: number;
  registration_name: string;
  kvk: string;
  concepts: UserPassportConceptPayload[];
  ubn: string;
  custom_ubn_name: string | null;
  type_of_company: string;

  organization: {
    id: number;
    email: string;
    kvk: string;
    kvk_company_name: string;
    logo: {
      url: string;
    };
  };

  [UserPassportsUnserializedHeaders.permissionProfileTemplateId]: number | null;
  [UserPassportsUnserializedHeaders.organizationPermissionProfile]: PermissionProfileResponseEntry | null;

  user_type: string;
  user_name: string;
  user_email: string;

  created_at: string;
  updated_at: string;
}

export interface UserPassportSerialized {
  id: number;
  registrationName: string;
  kvk: string;
  concepts: UserPassportConceptSerialized[];
  ubn: string;
  ubnAliases: string[];
  customUbnName: string;
  typeOfCompany: string;

  organization: {
    id: number;
    email: string;
    kvk: string;
    kvkCompanyName: string;
    logo: {
      url: string;
    };
  };
  passportOwners: AdminUserSerialized[];

  permissionProfileTemplateId: number;
  organizationPermissionProfile: PermissionProfileResponseEntrySerialized;

  labels: LabelSerialized[];

  createdAt: string;
  updatedAt: string;
  updatedBy: {
    email: string;
    full_name: string;
  };
  blockchainTimestamp: string;
  blockchainHashKey: string;
}

export const getSerializer = (data: DocumentData) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(unserialized, serialized, [
      new CellSerializer(SERVICE_FIELDS.id.unserialized, SERVICE_FIELDS.id.serialized),
      new CellSerializer(columns.registrationName.unserialized, columns.registrationName.serialized),
      new CellSerializer(columns.kvk.unserialized, columns.kvk.serialized),
      new CellSerializer(columns.ubn.unserialized, columns.ubn.serialized),
      new CellSerializer(columns.customUbnName.unserialized, columns.customUbnName.serialized),
      new CellSerializer(columns.typeOfCompany.unserialized, columns.typeOfCompany.serialized),

      new CellSerializer(
        columns.concepts.unserialized,
        columns.concepts.serialized,
        (data, sheetData, excelRow) => {
          const pseudoResponse = ({
            data: { resources: excelRow?.concepts || [] },
          } as unknown) as ResourcesWrapper<UserPassportConceptPayload>;

          return serializeUserPassportConcepts(pseudoResponse)[UserPassportConcepts];
        },
      ),

      new CellSerializer(
        columns.organization.unserialized,
        columns.organization.serialized,
        serializeObjectSnakeCaseToCamelCase,
      ),

      new CellSerializer(
        columns.passportOwners.unserialized,
        columns.passportOwners.serialized,
        (data, sheetData, excelRow) => {
          const pseudoResponse = ({
            data: { resources: excelRow?.passport_owners || [] },
          } as unknown) as ResourcesWrapper<AdminUserUnserialized>;

          return serializeAdminUsers(pseudoResponse)[ADMIN_USERS.AdminUsers.serialized];
        },
      ),

      new CellSerializer(
        columns.ubnAliases.unserialized,
        columns.ubnAliases.serialized,
        (
          data: DocumentData,
          sheetData: Array<object>,
          excelRow: { [index: string]: any },
          propertyOriginalPath: PropertyPath,
        ) => {
          const value = get(excelRow, propertyOriginalPath);
          return value?.map((item: UbnAliasesSerialized) => item?.value);
        },
      ),

      new CellSerializer(
        columns.permissionProfileTemplateId.unserialized,
        columns.permissionProfileTemplateId.serialized,
      ),

      new CellSerializer(
        columns.organizationPermissionProfile.unserialized,
        columns.organizationPermissionProfile.serialized,
        (data, sheetData, excelRow, propertyOriginalName) => {
          const permissionProfile = excelRow[propertyOriginalName as string] || {
            id: 0,
            title: '',
            notes: '',
            name: '',
            permission_rules: [],
          };

          return serializePermissionProfile(permissionProfile);
        },
      ),

      new CellSerializer(columns.labels.unserialized, columns.labels.serialized),

      new CellSerializer(
        SERVICE_FIELDS.createdAt.unserialized,
        SERVICE_FIELDS.createdAt.serialized,
        dateToFormattedString,
      ),
      new CellSerializer(SERVICE_FIELDS.updatedAt.unserialized, SERVICE_FIELDS.updatedAt.serialized),
      new CellSerializer(SERVICE_FIELDS.updatedBy.unserialized, SERVICE_FIELDS.updatedBy.serialized),
      new CellSerializer(SERVICE_FIELDS.blockchainHashKey.unserialized, SERVICE_FIELDS.blockchainHashKey.serialized),
      new CellSerializer(
        SERVICE_FIELDS.blockchainTimestamp.unserialized,
        SERVICE_FIELDS.blockchainTimestamp.serialized,
      ),
    ]),
  ]);
};

export const serializeUserPassport = (serverData: ResourceWrapper<UserPassportTransformedPayload>) => {
  const data = {
    [unserialized]: [serverData.data.resource],
  };

  const serializer = getSerializer(data);

  return serializer.serialize()[unserialized][0] as UserPassportSerialized;
};

export const serializeUserPassports = (serverData: ResourcesWrapper<UserPassportTransformedPayload>) => {
  const data = {
    [unserialized]: serverData.data.resources,
  };

  const serializer = getSerializer(data);

  return serializer.serialize();
};
