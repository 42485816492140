enum ReferenceTablesDashboardSerialized {
  ReferenceTablesDashboard = 'ReferenceTablesDashboard',
}

enum ReferenceTablesDashboardUnserialized {
  ReferenceTablesDashboard = 'ReferenceTablesDashboard',
}

export enum ReferenceTablesDashboardSerializedHeaders {
  pensLevel = 'pensLevel',
  pensTypeSowBarn = 'pensTypeSowBarn',
  yesNo = 'yesNo',
  cfpCo2EmissionFactor = 'cfpCo2EmissionFactor',
  cvbTable = 'cvbTable',
  cfpGfliFeedprintTable = 'cfpGfliFeedprintTable',
  ipccEmissionFactors = 'ipccEmissionFactors',
  contentOfNitricOxide = 'contentOfNitricOxide',
  pigHousingEmissionFactors = 'pigHousingEmissionFactors',
  electricityConsumptionRates = 'electricityConsumptionRates',
  gasConsumptionRates = 'gasConsumptionRates',
  waterConsumptionRates = 'waterConsumptionRates',
  ravCodes = 'ravCodes',
  gwp100Factors = 'gwp100Factors',
  wurMcfEfem = 'wurMcfEfem',
  cl550 = 'cl550',
  cl649 = 'cl649',
  cl650 = 'cl650',
  cl607 = 'cl607',
  suppliersNumbers = 'suppliersNumbers',
  countryCodes = 'countryCodes',
  ravReferenceList = 'ravReferenceList',
  allocationManure = 'allocationManure',
  greenhouseGasEmission = 'greenhouseGasEmission',
  entitiesList = 'entitiesList',
  deliveriesAuthorisedSuppliers = 'deliveriesAuthorisedSuppliers',
  movementEventsApprovedUbns = 'movementEventsApprovedUbns',
  slaughterhouseDataValues = 'slaughterhouseDataValues',
}

export const ReferenceTablesDashboardUnserializedHeaders = {
  pensLevel: ['statuses_by_type', 'pen_level_upload'],
  pensTypeSowBarn: ['statuses_by_type', 'pen_type_sow_barn_upload'],
  yesNo: ['statuses_by_type', 'answer_code_upload'],
  cfpCo2EmissionFactor: ['statuses_by_type', 'co2_emission_factor_upload'],
  cvbTable: ['statuses_by_type', 'carbon_foot_print_upload'],
  cfpGfliFeedprintTable: ['statuses_by_type', 'feed_print_upload'],
  ipccEmissionFactors: ['statuses_by_type', 'ipcc_emission_factor_upload'],
  contentOfNitricOxide: ['statuses_by_type', 'content_of_nitric_oxide_upload'],
  pigHousingEmissionFactors: ['statuses_by_type', 'pig_housing_emission_factor_upload'],
  electricityConsumptionRates: ['statuses_by_type', 'electricity_consumption_rate_upload'],
  gasConsumptionRates: ['statuses_by_type', 'gas_consumption_rate_upload'],
  waterConsumptionRates: ['statuses_by_type', 'water_consumption_rate_upload'],
  ravCodes: ['statuses_by_type', 'rav_code_upload'],
  gwp100Factors: ['statuses_by_type', 'gwp100_factor_upload'],
  wurMcfEfem: ['statuses_by_type', 'wur_mcf_efem_upload'],
  cl550: ['statuses_by_type', 'pig_disease_code_upload'],
  cl649: ['statuses_by_type', 'charge_or_premium_code_type_upload'],
  cl650: ['statuses_by_type', 'group_charge_or_premium_code_upload'],
  cl607: ['statuses_by_type', 'animal_id_code_type_upload'],
  suppliersNumbers: ['statuses_by_type', 'supplier_number_upload'],
  countryCodes: ['statuses_by_type', 'country_code_upload'],
  ravReferenceList: ['statuses_by_type', 'rav_reference_list_upload'],
  allocationManure: ['statuses_by_type', 'allocation_manure_upload'],
  greenhouseGasEmission: ['statuses_by_type', 'greenhouse_gas_emission_upload'],
  entitiesList: ['statuses_by_type', 'entities_list_upload'],
  deliveriesAuthorisedSuppliers: ['statuses_by_type', 'deliveries_authorised_supplier_upload'],
  movementEventsApprovedUbns: ['statuses_by_type', 'movement_events_approved_ubns_upload'],
  slaughterhouseDataValues: ['statuses_by_type', 'slaughterhouse_data_values_upload'],
} as const;

export const REFERENCE_TABLES_DASHBOARD = {
  [ReferenceTablesDashboardSerialized.ReferenceTablesDashboard]: {
    serialized: ReferenceTablesDashboardSerialized.ReferenceTablesDashboard,
    unserialized: ReferenceTablesDashboardUnserialized.ReferenceTablesDashboard,

    columns: {
      [ReferenceTablesDashboardSerializedHeaders.pensLevel]: {
        serialized: ReferenceTablesDashboardSerializedHeaders.pensLevel,
        unserialized: ReferenceTablesDashboardUnserializedHeaders.pensLevel,
      },
      [ReferenceTablesDashboardSerializedHeaders.pensTypeSowBarn]: {
        serialized: ReferenceTablesDashboardSerializedHeaders.pensTypeSowBarn,
        unserialized: ReferenceTablesDashboardUnserializedHeaders.pensTypeSowBarn,
      },
      [ReferenceTablesDashboardSerializedHeaders.yesNo]: {
        serialized: ReferenceTablesDashboardSerializedHeaders.yesNo,
        unserialized: ReferenceTablesDashboardUnserializedHeaders.yesNo,
      },
      [ReferenceTablesDashboardSerializedHeaders.cfpCo2EmissionFactor]: {
        serialized: ReferenceTablesDashboardSerializedHeaders.cfpCo2EmissionFactor,
        unserialized: ReferenceTablesDashboardUnserializedHeaders.cfpCo2EmissionFactor,
      },
      [ReferenceTablesDashboardSerializedHeaders.cvbTable]: {
        serialized: ReferenceTablesDashboardSerializedHeaders.cvbTable,
        unserialized: ReferenceTablesDashboardUnserializedHeaders.cvbTable,
      },
      [ReferenceTablesDashboardSerializedHeaders.cfpGfliFeedprintTable]: {
        serialized: ReferenceTablesDashboardSerializedHeaders.cfpGfliFeedprintTable,
        unserialized: ReferenceTablesDashboardUnserializedHeaders.cfpGfliFeedprintTable,
      },
      [ReferenceTablesDashboardSerializedHeaders.ipccEmissionFactors]: {
        serialized: ReferenceTablesDashboardSerializedHeaders.ipccEmissionFactors,
        unserialized: ReferenceTablesDashboardUnserializedHeaders.ipccEmissionFactors,
      },
      [ReferenceTablesDashboardSerializedHeaders.contentOfNitricOxide]: {
        serialized: ReferenceTablesDashboardSerializedHeaders.contentOfNitricOxide,
        unserialized: ReferenceTablesDashboardUnserializedHeaders.contentOfNitricOxide,
      },
      [ReferenceTablesDashboardSerializedHeaders.pigHousingEmissionFactors]: {
        serialized: ReferenceTablesDashboardSerializedHeaders.pigHousingEmissionFactors,
        unserialized: ReferenceTablesDashboardUnserializedHeaders.pigHousingEmissionFactors,
      },
      [ReferenceTablesDashboardSerializedHeaders.electricityConsumptionRates]: {
        serialized: ReferenceTablesDashboardSerializedHeaders.electricityConsumptionRates,
        unserialized: ReferenceTablesDashboardUnserializedHeaders.electricityConsumptionRates,
      },
      [ReferenceTablesDashboardSerializedHeaders.gasConsumptionRates]: {
        serialized: ReferenceTablesDashboardSerializedHeaders.gasConsumptionRates,
        unserialized: ReferenceTablesDashboardUnserializedHeaders.gasConsumptionRates,
      },
      [ReferenceTablesDashboardSerializedHeaders.waterConsumptionRates]: {
        serialized: ReferenceTablesDashboardSerializedHeaders.waterConsumptionRates,
        unserialized: ReferenceTablesDashboardUnserializedHeaders.waterConsumptionRates,
      },
      [ReferenceTablesDashboardSerializedHeaders.ravCodes]: {
        serialized: ReferenceTablesDashboardSerializedHeaders.ravCodes,
        unserialized: ReferenceTablesDashboardUnserializedHeaders.ravCodes,
      },
      [ReferenceTablesDashboardSerializedHeaders.gwp100Factors]: {
        serialized: ReferenceTablesDashboardSerializedHeaders.gwp100Factors,
        unserialized: ReferenceTablesDashboardUnserializedHeaders.gwp100Factors,
      },
      [ReferenceTablesDashboardSerializedHeaders.wurMcfEfem]: {
        serialized: ReferenceTablesDashboardSerializedHeaders.wurMcfEfem,
        unserialized: ReferenceTablesDashboardUnserializedHeaders.wurMcfEfem,
      },
      [ReferenceTablesDashboardSerializedHeaders.cl550]: {
        serialized: ReferenceTablesDashboardSerializedHeaders.cl550,
        unserialized: ReferenceTablesDashboardUnserializedHeaders.cl550,
      },
      [ReferenceTablesDashboardSerializedHeaders.cl649]: {
        serialized: ReferenceTablesDashboardSerializedHeaders.cl649,
        unserialized: ReferenceTablesDashboardUnserializedHeaders.cl649,
      },
      [ReferenceTablesDashboardSerializedHeaders.cl650]: {
        serialized: ReferenceTablesDashboardSerializedHeaders.cl650,
        unserialized: ReferenceTablesDashboardUnserializedHeaders.cl650,
      },
      [ReferenceTablesDashboardSerializedHeaders.cl607]: {
        serialized: ReferenceTablesDashboardSerializedHeaders.cl607,
        unserialized: ReferenceTablesDashboardUnserializedHeaders.cl607,
      },
      [ReferenceTablesDashboardSerializedHeaders.suppliersNumbers]: {
        serialized: ReferenceTablesDashboardSerializedHeaders.suppliersNumbers,
        unserialized: ReferenceTablesDashboardUnserializedHeaders.suppliersNumbers,
      },
      [ReferenceTablesDashboardSerializedHeaders.countryCodes]: {
        serialized: ReferenceTablesDashboardSerializedHeaders.countryCodes,
        unserialized: ReferenceTablesDashboardUnserializedHeaders.countryCodes,
      },
      [ReferenceTablesDashboardSerializedHeaders.ravReferenceList]: {
        serialized: ReferenceTablesDashboardSerializedHeaders.ravReferenceList,
        unserialized: ReferenceTablesDashboardUnserializedHeaders.ravReferenceList,
      },
      [ReferenceTablesDashboardSerializedHeaders.allocationManure]: {
        serialized: ReferenceTablesDashboardSerializedHeaders.allocationManure,
        unserialized: ReferenceTablesDashboardUnserializedHeaders.allocationManure,
      },
      [ReferenceTablesDashboardSerializedHeaders.greenhouseGasEmission]: {
        serialized: ReferenceTablesDashboardSerializedHeaders.greenhouseGasEmission,
        unserialized: ReferenceTablesDashboardUnserializedHeaders.greenhouseGasEmission,
      },
      [ReferenceTablesDashboardSerializedHeaders.entitiesList]: {
        serialized: ReferenceTablesDashboardSerializedHeaders.entitiesList,
        unserialized: ReferenceTablesDashboardUnserializedHeaders.entitiesList,
      },
      [ReferenceTablesDashboardSerializedHeaders.deliveriesAuthorisedSuppliers]: {
        serialized: ReferenceTablesDashboardSerializedHeaders.deliveriesAuthorisedSuppliers,
        unserialized: ReferenceTablesDashboardUnserializedHeaders.deliveriesAuthorisedSuppliers,
      },
      [ReferenceTablesDashboardSerializedHeaders.movementEventsApprovedUbns]: {
        serialized: ReferenceTablesDashboardSerializedHeaders.movementEventsApprovedUbns,
        unserialized: ReferenceTablesDashboardUnserializedHeaders.movementEventsApprovedUbns,
      },
      [ReferenceTablesDashboardSerializedHeaders.slaughterhouseDataValues]: {
        serialized: ReferenceTablesDashboardSerializedHeaders.slaughterhouseDataValues,
        unserialized: ReferenceTablesDashboardUnserializedHeaders.slaughterhouseDataValues,
      },
    },
  },
};
