import { PERMISSIONS } from 'store/auth/permissions';
import { getUrl, getUrlWithParams } from 'routes';

import { NestedMenuItem } from '../../NestedMenu';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import {
  Assessment,
  Book,
  ChromeReaderMode,
  CompareArrows,
  Dashboard,
  DonutSmall,
  ExitToApp,
  FindInPage,
  Folder,
  LocalPharmacy,
  Polymer,
  Publish,
  Receipt,
  Settings,
  Store,
  PlaylistPlay,
  Update,
  VerticalSplit,
  StoreMallDirectory,
  CloudCircle,
  Error,
  Laptop,
  FileCopy
} from '@material-ui/icons';

import { IntlKeys } from 'lib/localization/keys/__keys';

import { signOutAction } from 'store/auth/reducer';
import { store } from 'store/store';

import { AnimalPassportEntities } from 'pages/animalPassport/config';
import { REFERENCE_TABLES_TYPES } from 'pages/referenceTables/utils';
import { FarmManagementCategories } from 'pages/farmManagementData/categories/config';
import { TitlesKeys } from 'lib/localization/keys';

interface NestedMenuSection {
  name: string;
  key?: string;
  items: Array<NestedMenuItem>;
  hideable?: boolean;
  restrictions?: { [key in PERMISSIONS]?: boolean };
}

// For repetitive menu entries or just to make menuConfig more readable
const menuRegistry = {
  participations: {
    title: IntlKeys.participationPlural,
    icon: ChromeReaderMode,
    url: getUrl('joindataParticipations'),
    hideable: true,
    subItems: [
      {
        title: IntlKeys.participationPlural,
        icon: ChromeReaderMode,
        url: getUrl('joindataParticipations'),
      },
      {
        title: IntlKeys.requestedParticipations,
        icon: ChromeReaderMode,
        url: getUrl('joindataRequestedParticipations'),
        restrictions: { [PERMISSIONS.LEVEL1]: true },
        hideable: true,
      },
      {
        title: IntlKeys.unmatchedOrganizations,
        icon: ChromeReaderMode,
        url: getUrl('joindataUnmatchedOrganizations'),
        restrictions: { [PERMISSIONS.LEVEL1]: true },
        hideable: true,
      },
    ],
  },
};

export const constant: NestedMenuSection = {
  name: '',
  key: 'constant',
  items: [{ title: IntlKeys.titleDashboard, icon: Dashboard, url: getUrl() }],
};

const dataset: NestedMenuSection = {
  name: IntlKeys.dashboardDataset,
  restrictions: { [PERMISSIONS.ACCESS_ADMIN_PANEL]: false },
  items: [
    {
      title: IntlKeys.titleCertificates,
      icon: Book,
      url: getUrl('certificatesUrlRoot'),
      hideable: true,
    },
    {
      title: IntlKeys.titleInvoices,
      icon: Receipt,
      url: getUrl('invoices'),
      hideable: true,
    },
    {
      title: IntlKeys.titleSlaughter,
      icon: Assessment,
      url: getUrl('slaughter'),
      hideable: true,
    },
    {
      title: IntlKeys.slaughterPriceCorrections,
      icon: Assessment,
      url: getUrl('slaughterPriceCorrections'),
      hideable: true,
    },
    { title: IntlKeys.titleGenetics, icon: Polymer, url: getUrl('genetic'), hideable: true },
    { title: IntlKeys.titleDataCatalogue, icon: ChromeReaderMode, url: getUrl('dataCatalogue'), hideable: true },
    { title: IntlKeys.titleDeliveryMessages, icon: LocalShippingIcon, url: getUrl('deliveryMessages'), hideable: true },
    { title: IntlKeys.titleVeterinarianData, icon: LocalPharmacy, url: getUrl('veterinarianData'), hideable: true },
    { title: IntlKeys.titleMovementEvents, icon: CompareArrows, url: getUrl('movementEvents'), hideable: true },
    { title: IntlKeys.transports, icon: LocalShippingIcon, url: getUrl('transportsBase'), hideable: true },
    {
      title: IntlKeys.titleRawMaterialsOrigin,
      icon: VerticalSplit,
      url: getUrl('rawMaterialsOrigin'),
      hideable: true,
    },
    {
      title: IntlKeys.titleReferenceTables,
      icon: VerticalSplit,
      url: getUrl('manageUploads'),
      hideable: true,
      subItems: [
        {
          title: IntlKeys.titleManageUploads,
          icon: VerticalSplit,
          url: getUrl('manageUploads'),
          hideable: true,
        },
        {
          title: IntlKeys.referenceTablesAsPensLevel,
          icon: VerticalSplit,
          url: `${getUrl('manageUploads')}?filter[category]=${REFERENCE_TABLES_TYPES.pensLevel}`,
          hideable: true,
        },
        {
          title: IntlKeys.referenceTablesAsPensTypeSowBarn,
          icon: VerticalSplit,
          url: `${getUrl('manageUploads')}?filter[category]=${REFERENCE_TABLES_TYPES.pensTypeSowBarn}`,
          hideable: true,
        },
        {
          title: IntlKeys.referenceTablesAsYesNo,
          icon: VerticalSplit,
          url: `${getUrl('manageUploads')}?filter[category]=${REFERENCE_TABLES_TYPES.yesNo}`,
          hideable: true,
        },
        {
          title: IntlKeys.referenceTablesCfpCo2EmissionFactors,
          icon: VerticalSplit,
          url: `${getUrl('manageUploads')}?filter[category]=${REFERENCE_TABLES_TYPES.cfpCo2EmissionFactor}`,
          hideable: true,
        },
        {
          title: IntlKeys.referenceTablesCvbTable,
          icon: VerticalSplit,
          url: `${getUrl('manageUploads')}?filter[category]=${REFERENCE_TABLES_TYPES.cvbTable}`,
          hideable: true,
        },
        {
          title: IntlKeys.referenceTablesCfpGfliFeedprintTable,
          icon: VerticalSplit,
          url: `${getUrl('manageUploads')}?filter[category]=${REFERENCE_TABLES_TYPES.cfpGfliFeedprintTable}`,
          hideable: true,
        },
        {
          title: IntlKeys.referenceTablesIpccEmissionFactors,
          icon: VerticalSplit,
          url: `${getUrl('manageUploads')}?filter[category]=${REFERENCE_TABLES_TYPES.ipccEmissionFactors}`,
          hideable: true,
        },
        {
          title: IntlKeys.referenceTablesContentOfNitricOxide,
          icon: VerticalSplit,
          url: `${getUrl('manageUploads')}?filter[category]=${REFERENCE_TABLES_TYPES.contentOfNitricOxide}`,
          hideable: true,
        },
        {
          title: IntlKeys.referenceTablesPigHousingEmissionFactors,
          icon: VerticalSplit,
          url: `${getUrl('manageUploads')}?filter[category]=${REFERENCE_TABLES_TYPES.pigHousingEmissionFactors}`,
          hideable: true,
        },
        {
          title: IntlKeys.referenceTablesElectricityConsumptionRates,
          icon: VerticalSplit,
          url: `${getUrl('manageUploads')}?filter[category]=${REFERENCE_TABLES_TYPES.electricityConsumptionRates}`,
          hideable: true,
        },
        {
          title: IntlKeys.referenceTablesGasConsumptionRates,
          icon: VerticalSplit,
          url: `${getUrl('manageUploads')}?filter[category]=${REFERENCE_TABLES_TYPES.gasConsumptionRates}`,
          hideable: true,
        },
        {
          title: IntlKeys.referenceTablesWaterConsumptionRates,
          icon: VerticalSplit,
          url: `${getUrl('manageUploads')}?filter[category]=${REFERENCE_TABLES_TYPES.waterConsumptionRates}`,
          hideable: true,
        },
        {
          title: IntlKeys.referenceTablesRavCodes,
          icon: VerticalSplit,
          url: `${getUrl('manageUploads')}?filter[category]=${REFERENCE_TABLES_TYPES.ravCodes}`,
          hideable: true,
        },
        {
          title: IntlKeys.referenceTablesGwp100Factors,
          icon: VerticalSplit,
          url: `${getUrl('manageUploads')}?filter[category]=${REFERENCE_TABLES_TYPES.gwp100Factors}`,
          hideable: true,
        },
        {
          title: IntlKeys.referenceTablesWurMfcEfem,
          icon: VerticalSplit,
          url: `${getUrl('manageUploads')}?filter[category]=${REFERENCE_TABLES_TYPES.wurMcfEfem}`,
          hideable: true,
        },
        {
          title: IntlKeys.referenceTablesCl550,
          icon: VerticalSplit,
          url: `${getUrl('manageUploads')}?filter[category]=${REFERENCE_TABLES_TYPES.cl550}`,
          hideable: true,
        },
        {
          title: IntlKeys.referenceTablesCl649,
          icon: VerticalSplit,
          url: `${getUrl('manageUploads')}?filter[category]=${REFERENCE_TABLES_TYPES.cl649}`,
          hideable: true,
        },
        {
          title: IntlKeys.referenceTablesCl650,
          icon: VerticalSplit,
          url: `${getUrl('manageUploads')}?filter[category]=${REFERENCE_TABLES_TYPES.cl650}`,
          hideable: true,
        },
        {
          title: IntlKeys.referenceTablesCl607,
          icon: VerticalSplit,
          url: `${getUrl('manageUploads')}?filter[category]=${REFERENCE_TABLES_TYPES.cl607}`,
          hideable: true,
        },
        {
          title: IntlKeys.referenceTablesSuppliersNumbers,
          icon: VerticalSplit,
          url: `${getUrl('manageUploads')}?filter[category]=${REFERENCE_TABLES_TYPES.suppliersNumbers}`,
          hideable: true,
        },
        {
          title: IntlKeys.referenceTablesCountryCodes,
          icon: VerticalSplit,
          url: `${getUrl('manageUploads')}?filter[category]=${REFERENCE_TABLES_TYPES.countryCodes}`,
          hideable: true,
        },
        {
          title: IntlKeys.referenceTablesRavReferenceList,
          icon: VerticalSplit,
          url: `${getUrl('manageUploads')}?filter[category]=${REFERENCE_TABLES_TYPES.ravReferenceList}`,
          hideable: true,
        },
        {
          title: IntlKeys.referenceTablesAllocationManure,
          icon: VerticalSplit,
          url: `${getUrl('manageUploads')}?filter[category]=${REFERENCE_TABLES_TYPES.allocationManure}`,
          hideable: true,
        },
        {
          title: IntlKeys.referenceTablesGreenhouseGasEmission,
          icon: VerticalSplit,
          url: `${getUrl('manageUploads')}?filter[category]=${REFERENCE_TABLES_TYPES.greenhouseGasEmission}`,
          hideable: true,
        },
        {
          title: IntlKeys.referenceTablesEntitiesList,
          icon: VerticalSplit,
          url: `${getUrl('manageUploads')}?filter[category]=${REFERENCE_TABLES_TYPES.entitiesList}`,
          hideable: true,
        },
        {
          title: IntlKeys.referenceTablesDeliveriesAuthorisedSuppliers,
          icon: VerticalSplit,
          url: `${getUrl('manageUploads')}?filter[category]=${REFERENCE_TABLES_TYPES.deliveriesAuthorisedSuppliers}`,
          hideable: true,
        },
        {
          title: IntlKeys.referenceTablesDeliveriesAuthorisedSuppliers,
          icon: VerticalSplit,
          url: `${getUrl('manageUploads')}?filter[category]=${REFERENCE_TABLES_TYPES.deliveriesAuthorisedSuppliers}`,
          hideable: true,
        },
        {
          title: IntlKeys.referenceTablesMovementEventsApprovedUbns,
          icon: VerticalSplit,
          url: `${getUrl('manageUploads')}?filter[category]=${REFERENCE_TABLES_TYPES.movementEventsApprovedUbns}`,
          hideable: true,
        },
        {
          title: IntlKeys.referenceTablesSlaughterhouseDataValues,
          icon: VerticalSplit,
          url: `${getUrl('manageUploads')}?filter[category]=${REFERENCE_TABLES_TYPES.slaughterhouseDataValues}`,
          hideable: true,
        },
      ],
    },
    {
      title: IntlKeys.titleFarmManagement,
      icon: StoreMallDirectory,
      url: getUrl('farmManagement'),
      hideable: true,
      subItems: [
        {
          title: IntlKeys.titleFtpUpdates,
          icon: StoreMallDirectory,
          url: getUrl('farmManagement'),
          hideable: true,
        },
        {
          title: IntlKeys.titleCodesAccount,
          icon: StoreMallDirectory,
          url: getUrlWithParams('farmManagementCategories', {
            category: FarmManagementCategories.codesAccount,
          }),
          hideable: true,
        },
        {
          title: IntlKeys.titleCodesAnimalIdentityType,
          icon: StoreMallDirectory,
          url: getUrlWithParams('farmManagementCategories', {
            category: FarmManagementCategories.codesAnimalIdentityType,
          }),
          hideable: true,
        },
        {
          title: IntlKeys.titleCodesAnimalRemarks,
          icon: StoreMallDirectory,
          url: getUrlWithParams('farmManagementCategories', {
            category: FarmManagementCategories.codesAnimalRemarks,
          }),
          hideable: true,
        },
        {
          title: IntlKeys.titleCodesAnimalScore,
          icon: StoreMallDirectory,
          url: getUrlWithParams('farmManagementCategories', {
            category: FarmManagementCategories.codesAnimalScore,
          }),
          hideable: true,
        },
        {
          title: IntlKeys.titleCodesBarn,
          icon: StoreMallDirectory,
          url: getUrlWithParams('farmManagementCategories', {
            category: FarmManagementCategories.codesBarn,
          }),
          hideable: true,
        },
        {
          title: IntlKeys.titleCodesDefects,
          icon: StoreMallDirectory,
          url: getUrlWithParams('farmManagementCategories', {
            category: FarmManagementCategories.codesDefects,
          }),
          hideable: true,
        },
        {
          title: IntlKeys.titleCodesDiagnose,
          icon: StoreMallDirectory,
          url: getUrlWithParams('farmManagementCategories', {
            category: FarmManagementCategories.codesDiagnose,
          }),
          hideable: true,
        },
        {
          title: IntlKeys.titleCodesEventTypes,
          icon: StoreMallDirectory,
          url: getUrlWithParams('farmManagementCategories', {
            category: FarmManagementCategories.codesEventType,
          }),
          hideable: true,
        },
        {
          title: IntlKeys.titleCodesFarmOption,
          icon: StoreMallDirectory,
          url: getUrlWithParams('farmManagementCategories', {
            category: FarmManagementCategories.codesFarmOption,
          }),
          hideable: true,
        },
        {
          title: IntlKeys.titleCodesInsemination,
          icon: StoreMallDirectory,
          url: getUrlWithParams('farmManagementCategories', {
            category: FarmManagementCategories.codesInsemination,
          }),
          hideable: true,
        },
        {
          title: IntlKeys.titleCodesMatingType,
          icon: StoreMallDirectory,
          url: getUrlWithParams('farmManagementCategories', {
            category: FarmManagementCategories.codesMatingType,
          }),
          hideable: true,
        },
        {
          title: IntlKeys.titleCodesPen,
          icon: StoreMallDirectory,
          url: getUrlWithParams('farmManagementCategories', {
            category: FarmManagementCategories.codesPen,
          }),
          hideable: true,
        },
        {
          title: IntlKeys.titleCodesRemovalType,
          icon: StoreMallDirectory,
          url: getUrlWithParams('farmManagementCategories', {
            category: FarmManagementCategories.codesRemovalType,
          }),
          hideable: true,
        },
        {
          title: IntlKeys.titleCodesRoom,
          icon: StoreMallDirectory,
          url: getUrlWithParams('farmManagementCategories', {
            category: FarmManagementCategories.codesRoom,
          }),
          hideable: true,
        },
        {
          title: IntlKeys.titleCodesTreatments,
          icon: StoreMallDirectory,
          url: getUrlWithParams('farmManagementCategories', {
            category: FarmManagementCategories.codesTreatments,
          }),
          hideable: true,
        },
        {
          title: IntlKeys.titleCodesWeightType,
          icon: StoreMallDirectory,
          url: getUrlWithParams('farmManagementCategories', {
            category: FarmManagementCategories.codesWeightType,
          }),
          hideable: true,
        },
        {
          title: IntlKeys.titleGroupExpense,
          icon: StoreMallDirectory,
          url: getUrlWithParams('farmManagementCategories', {
            category: FarmManagementCategories.groupExpense,
          }),
          hideable: true,
        },
        {
          title: IntlKeys.titleGroupFinancialEvents,
          icon: StoreMallDirectory,
          url: getUrlWithParams('farmManagementCategories', {
            category: FarmManagementCategories.groupFinancialEvents,
          }),
          hideable: true,
        },
        {
          title: IntlKeys.titleGroupPurchasedFood,
          icon: StoreMallDirectory,
          url: getUrlWithParams('farmManagementCategories', {
            category: FarmManagementCategories.groupPurchasedFood,
          }),
          hideable: true,
        },
        {
          title: IntlKeys.titleGroupUsedFood,
          icon: StoreMallDirectory,
          url: getUrlWithParams('farmManagementCategories', {
            category: FarmManagementCategories.groupUsedFood,
          }),
          hideable: true,
        },
        {
          title: IntlKeys.titleGroupGroups,
          icon: StoreMallDirectory,
          url: getUrlWithParams('farmManagementCategories', {
            category: FarmManagementCategories.groupGroups,
          }),
          hideable: true,
        },
        {
          title: IntlKeys.titleGroupsArrival,
          icon: StoreMallDirectory,
          url: getUrlWithParams('farmManagementCategories', {
            category: FarmManagementCategories.groupGroupsArrival,
          }),
          hideable: true,
        },
        {
          title: IntlKeys.titleGroupMoves,
          icon: StoreMallDirectory,
          url: getUrlWithParams('farmManagementCategories', {
            category: FarmManagementCategories.groupGroupMoves,
          }),
          hideable: true,
        },
        {
          title: IntlKeys.titleGroupRemovals,
          icon: StoreMallDirectory,
          url: getUrlWithParams('farmManagementCategories', {
            category: FarmManagementCategories.groupGroupRemoval,
          }),
          hideable: true,
        },
        {
          title: IntlKeys.titleGroupWeights,
          icon: StoreMallDirectory,
          url: getUrlWithParams('farmManagementCategories', {
            category: FarmManagementCategories.groupGroupWeight,
          }),
          hideable: true,
        },
      ],
    },
    {
      title: IntlKeys.titleErrorsLog,
      icon: Error,
      url: getUrl('errorsLog'),
      hideable: true,
    },
  ],
};

const applications: NestedMenuSection = {
  name: IntlKeys.dashboardApplications,
  restrictions: { [PERMISSIONS.ACCESS_ADMIN_PANEL]: false },
  // hideable: true,
  items: [
    { title: IntlKeys.titleMyAlerts, icon: Assessment, url: getUrl('myAlerts'), hideable: true },
    { title: IntlKeys.titleAllOrganizations, icon: ChromeReaderMode, url: getUrl('organizationPassports'), hideable: true },
    {
      title: IntlKeys.titleMyCompany,
      icon: Store,
      url: getUrl('farmUpdates'),
      subItems: [{ title: IntlKeys.titleFarmUpdates, icon: Folder, url: getUrl('farmUpdates') }],
      hideable: true,
    },
    { title: IntlKeys.titleFarmerInput, icon: Store, url: getUrl('companyData'), hideable: true },
    { title: IntlKeys.titleFarmerInputSurveyJs, icon: Store, url: getUrl('companyDataSurveyJs'), hideable: true },
    {
      title: IntlKeys.titleVri,
      icon: FindInPage,
      url: getUrl('vriHome'),
      hideable: true,
      subItems: [
        { title: IntlKeys.titleVriEpg, icon: Folder, url: getUrl('vriEpg') },
        { title: IntlKeys.titleVriHealthBalance, icon: Folder, url: getUrl('vriHealthBalance') },
        { title: IntlKeys.titleVriBalanceBase, icon: Folder, url: getUrl('vriBalanceBase') },
        { title: IntlKeys.titleVriReportingTool, icon: Folder, url: getUrl('vriReportingTool') },
        {
          title: IntlKeys.titleVriDatabase,
          icon: Folder,
          url: getUrl('uploads'),
          restrictions: { [PERMISSIONS.ACCESS_VRI_DATABASE_UPLOADS]: true },
        },
      ],
    },
    {
      title: IntlKeys.titleVriCalculated,
      icon: FindInPage,
      url: getUrl('vriCalculatedHome'),
      hideable: true,
      subItems: [
        { title: IntlKeys.titleVriEpg, icon: Folder, url: getUrl('vriCalculatedEpg') },
        { title: IntlKeys.titleVriHealthBalance, icon: Folder, url: getUrl('vriCalculatedHealthBalance') },
        { title: IntlKeys.titleVriBalanceBase, icon: Folder, url: getUrl('vriCalculatedBalanceBase') },
        { title: IntlKeys.titleVriCalculatedReportingTool, icon: Folder, url: getUrl('vriCalculatedReportingTool') },
      ],
    },
    {
      title: IntlKeys.titleAnimalPassport,
      icon: DonutSmall,
      url: getUrlWithParams('animalPassport', { entity: AnimalPassportEntities.FOOD_SAFETY }),
      hideable: true,
    },
    menuRegistry.participations,
    {
      title: IntlKeys.titleCarbonFootprints,
      icon: CloudCircle,
      url: getUrl('carbonFootprints'),
      hideable: true,
    },
    { title: IntlKeys.titleCarbonFootprintRuns, icon: PlaylistPlay, url: getUrl('carbonFootprintRuns'), hideable: true },
    {
      title: IntlKeys.titleHFMOutput,
      icon: CloudCircle,
      url: getUrl('hfmOutputs'),
      hideable: true,
    },
    {
      title: IntlKeys.titleMonitoringDashboard,
      icon: DonutSmall,
      url: getUrl('monitoringDashboardSuppliers'),
      hideable: true,
    },
    {
      title: IntlKeys.titleVriComparisonTool,
      icon: FindInPage,
      url: getUrl('vriComparisonTool'),
      hideable: true,
      subItems: [
        { title: IntlKeys.epg, icon: Folder, url: getUrl('vriComparisonToolEpg') },
        { title: IntlKeys.healthBalance, icon: Folder, url: getUrl('vriComparisonToolHealthBalance') },
        { title: IntlKeys.balanceWrtBase, icon: Folder, url: getUrl('vriComparisonToolBalanceBase') },
        { title: IntlKeys.vriTool, icon: Folder, url: getUrl('vriComparisonToolVriTool') },
      ],
    },
    {
      title: IntlKeys.titleUploadDocument,
      icon: Publish,
      url: getUrl('uploadExcel'),
      restrictions: { [PERMISSIONS.UPLOAD_DOCUMENTS]: true },
    },
  ],
};

const UserAdminDashboardItems: NestedMenuSection = {
  name: '',
  key: 'dashboard',
  restrictions: { [PERMISSIONS.ACCESS_ADMIN_PANEL]: true },
  items: [
    {
      title: IntlKeys.titleAdmin,
      icon: Settings,
      url: getUrl('adminOrganizations'),
    },
    {
      title: IntlKeys.titleFarmUpdates,
      icon: Update,
      url: getUrl('adminUpdateLogs'),
    },
    {
      title: IntlKeys.titleUploadDocument,
      icon: Publish,
      url: getUrlWithParams('adminUploads', { entity: 'organizations-passports-users' }),
    },
    menuRegistry.participations,
    {
      title: TitlesKeys.feedProvenanceValidityTool,
      icon: Laptop,
      url: getUrl('feedProvenanceFileValidityTool'),
    },
    {
      title: IntlKeys.titleTemplateConfigs,
      icon: FileCopy,
      url: getUrl('templateConfigs'),
    },
  ],
};

const logout: NestedMenuSection = {
  name: '',
  key: 'logout',
  items: [{ title: IntlKeys.titleSignOut, icon: ExitToApp, onClick: () => store.dispatch(signOutAction()) }],
};

export const menuItems: Array<NestedMenuSection> = [UserAdminDashboardItems, dataset, applications, logout];
