import { DELIVERIES_AUTHORISED_SUPPLIERS_FIELDS } from 'lib/excel/config/referenceTables/deliveriesAutorisedSuppliers/structure';
import { DocumentValidator, ExcelSheets } from 'lib/excel/validators/Document';
import { SheetValidator } from 'lib/excel/validators/Sheet';
import { CellValidator } from 'lib/excel/validators/Cell';

const { original, columns, viewName } = DELIVERIES_AUTHORISED_SUPPLIERS_FIELDS.DeliveriesAuthorisedSuppliers;

export const getDeliveriesAuthorisedSuppliersDocumentValidator = (data: ExcelSheets) => {
  return new DocumentValidator(data, [
    new SheetValidator(original, viewName, [
      new CellValidator(columns.feedSupplierName.original),
      new CellValidator(columns.supplierNumber.original),
    ]),
  ]);
};
