import { camelCase, get, set } from 'lodash';
import moment from 'moment';
import 'moment/locale/nl';

import { DocumentData } from './Sheet';
import { TypedObjectKeys } from '../view/Document';
import { Languages } from 'lib/localization';
import { PropertyPath } from './Cell';
import { roundTo3Digits } from '../../helpers/renderHelpers';

export const shortDateFormat = 'D MMM YYYY';
export const dateTimeFormat = 'D MMM YYYY HH:mm';
export const shortDateFormatToParse = /^\d{4}-\d{2}-\d{2}$/;

// TODO MOVE THIS TO SAGA ON LOCALE CHANGE
moment.locale('en');

const formatDate = (validMomentDate: moment.Moment, format: string) => {
  return validMomentDate.utc().format(format);
};

export const renderToDateFormat = (
  fullTimeString = '',
  format: string = shortDateFormat,
  locale: keyof typeof Languages = 'en',
) => {
  let timeObj
  if (shortDateFormatToParse.test(fullTimeString)) {
    timeObj = moment.utc(fullTimeString);
  } else {
    timeObj = moment(fullTimeString);
  }
  // TODO MOVE THIS TO SAGA ON LOCALE CHANGE ?
  timeObj.locale(locale);
  return timeObj.isValid() ? formatDate(timeObj, format) : fullTimeString;
};

export const objectSnakeCaseToCamelCase = <T extends object>(val: T): T[] | T =>
  typeof val !== 'object' || val === null ? val : Array.isArray(val) ? val.map(renameKeys) : renameKeys(val);

const renameKeys = <T extends object>(obj: T): T => {
  if (!obj || typeof obj !== 'object') {
    return obj;
  }
  return Object.fromEntries(
    Object.entries(obj).map(([key, val]) => [camelCase(key), objectSnakeCaseToCamelCase(val)]),
  ) as T;
};

export const serializeObjectSnakeCaseToCamelCase = (
  data: DocumentData,
  sheetData: Array<object>,
  excelRow: { [index: string]: any },
  propertyOriginalPath: PropertyPath,
) => {
  const value = get(excelRow, propertyOriginalPath);
  return objectSnakeCaseToCamelCase(value);
};

export const dateToFormattedString = (
  data: DocumentData,
  sheetData: Array<object>,
  excelRow: { [index: string]: any },
  propertyOriginalPath: PropertyPath,
) => {
  const value = get(excelRow, propertyOriginalPath);

  return renderToDateFormat(value, shortDateFormat);
};

export const dateTimeString = (
  data: DocumentData,
  sheetData: Array<object>,
  excelRow: { [index: string]: any },
  propertyOriginalPath: PropertyPath,
) => {
  const value = get(excelRow, propertyOriginalPath);

  return renderToDateFormat(value, dateTimeFormat);
};

export const getFileName = (url: string) => {
  return url.slice(url.lastIndexOf('/') + 1, url.length);
};

const normalizeRelativeFilePath = (filePath: string) => {
  return filePath.startsWith('/') ? filePath.slice(1, filePath.length) : filePath;
};

export const getDownloadUrl = (url: string) => {
  return `${process.env.REACT_APP_API_URL}${normalizeRelativeFilePath(url)}`.replace('/api_v1', '');
};

const nullifyTime = (validMomentDate: moment.Moment) => {
  return validMomentDate.hours(0).minutes(0).seconds(0).milliseconds(0).toLocaleString();
};

export const dateToString = (
  data: DocumentData,
  sheetData: Array<object>,
  excelRow: { [index: string]: any },
  propertyOriginalPath: PropertyPath,
) => {
  const value = get(excelRow, propertyOriginalPath);
  const date = moment(value);

  return date.isValid() ? nullifyTime(date) : value;
};

export const valueToBoolean = (
  data: DocumentData,
  sheetData: Array<object>,
  excelRow: { [index: string]: any },
  propertyOriginalPath: PropertyPath,
) => {
  const value = get(excelRow, propertyOriginalPath);

  return !/^(?:f(?:alse)?|no?|0+)$/i.test(value) && !!value;
};

export const valueToInteger = (
  data: DocumentData,
  sheetData: Array<object>,
  excelRow: { [index: string]: any },
  propertyOriginalPath: PropertyPath,
) => {
  const value = get(excelRow, propertyOriginalPath);

  return parseInt(value);
};

export const propToString = (
  data: DocumentData,
  sheetData: Array<object>,
  excelRow: { [index: string]: any },
  propertyOriginalPath: PropertyPath,
) => {
  const value = get(excelRow, propertyOriginalPath);
  return `${value}`;
};

export const createPropStringArraySerializer = (separator?: string) => (
  data: DocumentData,
  sheetData: Array<object>,
  excelRow: { [index: string]: any },
  propertyOriginalPath: PropertyPath,
) => {
  const value = get(excelRow, propertyOriginalPath, '');
  const result = value?.split(separator);
  if (result) {
    return result.map(r => r.trim());
  } else {
    return result;
  }
};

export const formatUploadDate = (
  dataRow: TypedObjectKeys<any>,
  sheetData: Array<TypedObjectKeys<any>>,
  viewPropName: string,
  serializedPropName: string,
) => {
  const value = dataRow[serializedPropName];

  return {
    [viewPropName]: renderToDateFormat(value, shortDateFormat),
  };
};

export const deserializeRow = (
  columns: Record<
    string,
    {
      serialized: PropertyPath;
      unserialized: PropertyPath;
      original: PropertyPath;
      view?: string;
    }
  >,
  entry: Record<string, any>,
) => {
  return Object.entries(entry).reduce<Record<string, any>>((acc, [keySerialized, value]) => {
    if (columns[keySerialized]) {
      return set(acc, columns[keySerialized].unserialized, typeof value === 'string' ? value.trim() : value);
    }
    return acc;
  }, {});
};

export const formatViewNumber = (
  dataRow: TypedObjectKeys<any>,
  sheetData: Array<TypedObjectKeys<any>>,
  viewPropName: string,
  serializedPropName: string,
  parseValue: (value: string, radix?: number) => number,
  formatFunction: (value: number) => any,
) => {
  const value = dataRow[serializedPropName];
  const parsedValue = parseValue(value, 10);
  const formattedValue = !isNaN(parsedValue) && isFinite(parsedValue) ? formatFunction(parsedValue) : '';

  return {
    [viewPropName]: formattedValue,
  };
};
