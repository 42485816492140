import { IntlKeys } from 'lib/localization/keys';

enum SlaughterhouseDataValuesSerialized {
  SlaughterhouseDataValues = 'SlaughterhouseDataValues',
}

enum SlaughterhouseDataValuesUnserialized {
  SlaughterhouseDataValues = 'SlaughterhouseDataValues',
}

export enum SlaughterhouseDataValuesSerializedHeaders {
  id = 'id',
  referenceUploadID = 'referenceUploadID',
  value = 'value',
  unit = 'unit',
  uploadType = 'uploadType',
  filename = 'filename',
  status = 'status',
  validDateFrom = 'validDateFrom',
  validDateTo = 'validDateTo',
  notes = 'notes',
  concepts = 'concepts',
  uploadedAt = 'uploadedAt',
  uploadedBy = 'uploadedBy',
}

export const SlaughterhouseDataValuesUnserializedHeaders = {
  id: 'id',
  referenceUploadID: ['slaughterhouse_data_values_upload', 'id'],
  value: 'value',
  unit: 'unit',
  businessUnit: 'business_unit',
  uploadType: ['slaughterhouse_data_values_upload', 'upload_type'],
  filename: ['slaughterhouse_data_values_upload', 'file_name'],
  status: ['slaughterhouse_data_values_upload', 'status'],
  validDateFrom: ['slaughterhouse_data_values_upload', 'valid_from'],
  validDateTo: ['slaughterhouse_data_values_upload', 'valid_to'],
  notes: ['slaughterhouse_data_values_upload', 'notes'],
  concepts: ['slaughterhouse_data_values_upload', 'concepts'],
  uploadedAt: ['slaughterhouse_data_values_upload', 'uploaded_at'],
  uploadedBy: ['slaughterhouse_data_values_upload', 'uploaded_by', 'full_name'],
} as const;

export const SLAUGHTERHOUSE_DATA_VALUES = {
  [SlaughterhouseDataValuesSerialized.SlaughterhouseDataValues]: {
    serialized: SlaughterhouseDataValuesSerialized.SlaughterhouseDataValues,
    unserialized: SlaughterhouseDataValuesUnserialized.SlaughterhouseDataValues,
    view: IntlKeys.referenceTablesSlaughterhouseDataValues,

    columns: {
      [SlaughterhouseDataValuesSerializedHeaders.id]: {
        serialized: SlaughterhouseDataValuesSerializedHeaders.id,
        unserialized: SlaughterhouseDataValuesUnserializedHeaders.id,
      },
      [SlaughterhouseDataValuesSerializedHeaders.referenceUploadID]: {
        serialized: SlaughterhouseDataValuesSerializedHeaders.referenceUploadID,
        unserialized: SlaughterhouseDataValuesUnserializedHeaders.referenceUploadID,
      },
      [SlaughterhouseDataValuesSerializedHeaders.value]: {
        serialized: SlaughterhouseDataValuesSerializedHeaders.value,
        unserialized: SlaughterhouseDataValuesUnserializedHeaders.value,
        view: IntlKeys.referenceTablesSlaughterhouseDataValuesValue,
      },
      [SlaughterhouseDataValuesSerializedHeaders.unit]: {
        serialized: SlaughterhouseDataValuesSerializedHeaders.unit,
        unserialized: SlaughterhouseDataValuesUnserializedHeaders.unit,
        view: IntlKeys.referenceTablesSlaughterhouseDataValuesUnit,
      },
      [SlaughterhouseDataValuesSerializedHeaders.status]: {
        serialized: SlaughterhouseDataValuesSerializedHeaders.status,
        unserialized: SlaughterhouseDataValuesUnserializedHeaders.status,
      },
      [SlaughterhouseDataValuesSerializedHeaders.uploadType]: {
        serialized: SlaughterhouseDataValuesSerializedHeaders.uploadType,
        unserialized: SlaughterhouseDataValuesUnserializedHeaders.uploadType,
      },
      [SlaughterhouseDataValuesSerializedHeaders.notes]: {
        serialized: SlaughterhouseDataValuesSerializedHeaders.notes,
        unserialized: SlaughterhouseDataValuesUnserializedHeaders.notes,
      },
      [SlaughterhouseDataValuesSerializedHeaders.concepts]: {
        serialized: SlaughterhouseDataValuesSerializedHeaders.concepts,
        unserialized: SlaughterhouseDataValuesUnserializedHeaders.concepts,
        view: IntlKeys.concepts,
      },
      [SlaughterhouseDataValuesSerializedHeaders.filename]: {
        serialized: SlaughterhouseDataValuesSerializedHeaders.filename,
        unserialized: SlaughterhouseDataValuesUnserializedHeaders.filename,
      },
      [SlaughterhouseDataValuesSerializedHeaders.validDateFrom]: {
        serialized: SlaughterhouseDataValuesSerializedHeaders.validDateFrom,
        unserialized: SlaughterhouseDataValuesUnserializedHeaders.validDateFrom,
      },
      [SlaughterhouseDataValuesSerializedHeaders.validDateTo]: {
        serialized: SlaughterhouseDataValuesSerializedHeaders.validDateTo,
        unserialized: SlaughterhouseDataValuesUnserializedHeaders.validDateTo,
      },
      [SlaughterhouseDataValuesSerializedHeaders.uploadedAt]: {
        serialized: SlaughterhouseDataValuesSerializedHeaders.uploadedAt,
        unserialized: SlaughterhouseDataValuesUnserializedHeaders.uploadedAt,
      },
      [SlaughterhouseDataValuesSerializedHeaders.uploadedBy]: {
        serialized: SlaughterhouseDataValuesSerializedHeaders.uploadedBy,
        unserialized: SlaughterhouseDataValuesUnserializedHeaders.uploadedBy,
      },
    },
  },
};
