import { Method as AxiosMethod } from 'axios';
import { Axios } from './HttpClient';

import { api } from './utils';
import { makeUrl } from 'routes/utils';

// All lowercase
export type Method = Exclude<
  AxiosMethod,
  'GET' | 'DELETE' | 'HEAD' | 'OPTIONS' | 'POST' | 'PUT' | 'PATCH' | 'LINK' | 'UNLINK'
>;

class ApiClient {
  private constructor(readonly httpClient: typeof Axios) {}

  static getInstance(httpClient: typeof Axios) {
    return new ApiClient(httpClient);
  }

  getDeliveryContents = api(
    'get',
    makeUrl`/delivery_messages/${'deliveryMessageId'}/delivery_segments/${'deliverySegmentId'}/delivery_segment_contents`,
  );
  getDeliveryMessages = api('get', '/delivery_messages');
  getDeliveryMessagesAlerts = api('get', '/delivery_messages/alerts');
  updateDeliveryMessagesAlerts = api('post', makeUrl`/delivery_messages/alerts/${'id'}`);
  getDeliverySegment = api('get', makeUrl`/delivery_messages/${'deliveryMessageId'}/delivery_segments`);
  signIn = api('post', '/auth/sign_in');
  userByToken = api('post', '/auth/invitations/user_by_token');
  resetPassword = api('post', '/auth/password');
  changePassword = api('put', '/auth/password');
  inviteUser = api('post', '/auth/invitations/invite');
  authReinviteUser = api('put', makeUrl`/auth/invitations/resend_invitation/${'userId'}`);
  acceptInvitation = api('post', '/auth/invitations/accept_invitation');
  getUser = api('get', '/auth/validate_token');
  signOut = api('delete', '/auth/sign_out');
  // user
  updateUser = api('put', makeUrl`/users/${'id'}`);
  // dashboard total
  dashboardTotal = api('get', '/dashboard/total');
  getDashboardAppInfo = api('get', makeUrl`/application_infos/${'app_name'}`);

  //
  getBatch = api('get', makeUrl`${'endpoint'}/${'id'}`);
  postPubNodeEndpoint = api('post', makeUrl`${'endpoint'}`);
  downloadFile = api('get', makeUrl`${'endpoint'}`, { responseType: 'arraybuffer' });

  getCertificates = api('get', '/certificates');
  getSlaughterMessages = api('get', '/slaughter_messages');
  getSlaughterDetails = api('get', '/slaughters');
  getSlaughterPriceCorrections = api('get', '/slaughter_price_corrections');
  getSlaughterChargeOrPremiums = api('get', makeUrl`/slaughter_price_corrections/${'id'}/slaughter_charge_or_premiums`);
  getInvoiceMessages = api('get', '/invoice_messages');
  getInvoiceDetails = api('get', '/deliveries');
  getFarms = api('get', '/farms');
  getRecognitions = api('get', '/recognitions');
  getDeviations = api('get', '/deviations');
  getHistory = api('get', '/file_uploads');
  getGenetics = api('get', '/genetics');
  getVeterinarianData = api('get', '/veterinarian_data');

  getCertificatesIKB = api('get', '/ikb_validity_certificates');
  getCertificatesWelfare = api('get', '/welfare_validity_certificates');

  getCertificatesGrouped = api('get', '/certificates/grouped_index');
  getIkbGrouped = api('get', '/ikb_validity_certificates/grouped_index');
  getWelfareGrouped = api('get', '/welfare_validity_certificates/grouped_index');

  getMovementEvents = api('get', '/movement_events');
  getMovementEventsAlerts = api('get', '/movement_events/alerts');
  updateMovementEventsAlerts = api('post', makeUrl`/movement_events/alerts/${'id'}`);
  getMovementEventDetails = api('get', makeUrl`/movement_events/${'id'}`);
  getUserPassports = api('get', '/user_passports');
  getUserPassportsStatuses = api('get', '/user_passports/statuses');
  getOrganizationList = api('get', '/organizations');
  getOrganizationKvkList = api('get', '/organizations/kvks');

  getUbnRelatedImpersonalUsers = api('get', makeUrl`/user_passports/ubn_related/${'ubn'}`);

  uploadFile = api('post', '/file_uploads');
  reUploadFile = api('post', makeUrl`/file_uploads/${'fileId'}/reload-file`);

  uploadWeeklyDocument = api('post', '/farms/upload');
  uploadDailyDocument = api('post', '/certificates/upload');
  uploadVRIDatabaseDocument = api('post', '/admin/reports/upload');
  uploadCarbonFootprintDocument = api('post', '/admin/carbon_dioxide_footprints/upload');
  uploadHFMOutputDocument = api('post', '/admin/hfm_outputs/upload');
  // Data Catalogue
  getDataCatalogueCategories = api('get', '/entities');
  createDataCatalogueCategory = api('post', '/entities');
  deleteDataCatalogueCategory = api('delete', makeUrl`/entities/${'id'}`);
  updateDataCatalogueCategory = api('put', makeUrl`/entities/${'id'}`);
  // VRI
  getFeedSuppliers = api('get', '/user_passports/feed_suppliers');
  getUbnList = api('get', '/user_passports/ubn_list');
  getReportsUbnList = api('get', '/reports/ubn_list'); /* /reports/ubn_list?search_query=:ubn or :companyName */
  adminGetReportsUbnList = api('get', '/admin/reports/ubn_list');
  getReportUploads = api('get', '/report_uploads');
  adminGetReportUploads = api('get', '/admin/report_uploads');
  adminDeleteReportUpload = api('delete', makeUrl`/admin/report_uploads/${'id'}`);
  getReports = api('get', '/reports');
  adminGetReports = api('get', '/admin/reports');
  adminDeleteReport = api('delete', makeUrl`/admin/reports/${'id'}`);
  getReportsConstants = api('get', '/reports/constants');
  adminGetReportsConstants = api('get', '/admin/reports/constants');
  getReportsStatistics = api('get', '/reports/statistics');
  adminGetReportsStatistics = api('get', '/admin/reports/statistics');
  getUserPassportUbnList = api('get', '/user_passports/ubn_list');
  adminGetUserPassportUbnList = api('get', '/admin/user_passports/ubn_list');
  getCalculatedReports = api('get', '/calculated_reports');
  adminGetCalculatedReports = api('get', '/admin/calculated_reports');
  updateCalculatedReports = api('put', makeUrl`/calculated_reports/${'id'}`);
  adminUpdateCalculatedReports = api('put', makeUrl`/admin/calculated_reports/${'id'}`);
  getCalculatedReportsStatistics = api('get', '/calculated_reports/statistics');
  adminGetCalculatedReportsStatistics = api('get', '/admin/calculated_reports/statistics');
  visitVriTool = api('get', '/calculated_reports/visit');
  visitVri = api('get', '/report_uploads/visit');
  visitVriComparisonTool = api('get', '/vri_comparison_tool/visit');
  visitMyJoinDataLink = api('get', '/join_data_link/visit');
  getVriBalanceBaseData = api('get', '/mock/balance-base');
  getVriHealthBalanceData = api('get', '/mock/health-balance');
  // view config / user settings
  saveViewConfig = api('put', makeUrl`/view_configs/${'configName'}`);
  getViewConfig = api('get', makeUrl`/view_configs/${'configName'}`);
  deleteConfig = api('delete', makeUrl`/view_configs/${'configName'}`);
  updateUserProfileSettings = api('put', '/user_profile_settings/own');
  adminUpdateUserProfileSettings = api('put', '/admin/user_profile_settings/own');
  // farm
  getFarmStables = api('get', makeUrl`/stables_groups/${'ubn'}`);
  updateFarmStables = api('put', makeUrl`/stables_groups/${'ubn'}`);
  deleteFarmStable = api('delete', makeUrl`/stables_groups/${'ubn'}/stables/${'id'}`);
  // farm reports create
  createFarmDeathReport = api('post', '/farm_reports/deaths_reports');
  createFarmDeliveryReport = api('post', '/farm_reports/delivery_reports');
  createFarmSupportReport = api('post', '/farm_reports/support_reports');
  createFarmFeedConsumptionReport = api('post', '/farm_reports/feed_consumption_reports');
  // farm reports show
  getFarmDeathReport = api('get', makeUrl`/farm_reports/deaths_reports/${'id'}`);
  getFarmDeliveryReport = api('get', makeUrl`/farm_reports/delivery_reports/${'id'}`);
  getFarmSupportReport = api('get', makeUrl`/farm_reports/support_reports/${'id'}`);
  getFarmFeedConsumptionReport = api('get', makeUrl`/farm_reports/feed_consumption_reports/${'id'}`);
  // farm reports update
  updateFarmDeathReport = api('put', makeUrl`/farm_reports/deaths_reports/${'id'}`);
  updateFarmDeliveryReport = api('put', makeUrl`/farm_reports/delivery_reports/${'id'}`);
  updateFarmSupportReport = api('put', makeUrl`/farm_reports/support_reports/${'id'}`);
  updateFarmFeedConsumptionReport = api('put', makeUrl`/farm_reports/feed_consumption_reports/${'id'}`);
  // farm reports delete
  deleteFarmDeathReport = api('delete', makeUrl`/farm_reports/deaths_reports/${'id'}`);
  deleteFarmDeliveryReport = api('delete', makeUrl`/farm_reports/delivery_reports/${'id'}`);
  deleteFarmSupportReport = api('delete', makeUrl`/farm_reports/support_reports/${'id'}`);
  deleteFarmFeedConsumptionReport = api('delete', makeUrl`/farm_reports/feed_consumption_reports/${'id'}`);
  //
  getProductReportsStoredValuesList = api('get', '/farm_reports/product_reports/stored_values');
  getFermentationReportsStoredValuesList = api('get', '/farm_reports/fermentation_reports/stored_values');
  // farm events
  getFarmEvents = api('get', '/farm_events');
  // animal passports
  getAnimalPassports = api('get', makeUrl`/animal_passports/${'ubn'}`);
  //
  getPurposesList = api('get', '/admin/purposes');
  getMyParticipations = api('get', '/participations');
  getGroupedParticipations = api('get', '/participations/grouped');
  adminGetParticipations = api('get', '/admin/participations');
  adminGetUnmatchedOrganizations = api('get', '/admin/organizations/unmatched_by_participations');
  adminGetRequestedParticipations = api('get', '/admin/participation_store_requests');
  adminRequestParticipation = api('post', '/admin/participation_store_requests');
  adminSynchronizeJoindata = api('post', '/admin/tasks/execute/participations/sync_records');
  adminGetJoindataLastSync = api('get', '/admin/task_logs/last_log/participations/sync_records');
  //
  getTransports = api('get', '/transports');
  getTransportLoadings = api('get', makeUrl`/transports/${'id'}/transport_loadings`);
  getTransportUnloadings = api('get', makeUrl`/transports/${'id'}/transport_unloadings`);
  // admin
  getAdminUpdateLogs = api('get', '/admin/update_logs');
  getAdminOrganizationList = api('get', '/admin/organizations');
  getAdminOrganizationKvkList = api('get', '/admin/organizations/kvks');
  getAdminConcepts = api('get', '/admin/concepts');
  createAdminConcept = api('post', '/admin/concepts');
  updateAdminConcept = api('put', makeUrl`/admin/concepts/${'id'}`);
  deleteAdminConcept = api('delete', makeUrl`/admin/concepts/${'id'}`);
  getAdminUsers = api('get', '/admin/users');
  changeAdminUserStatus = api('patch', makeUrl`/admin/users/${'id'}`);
  deleteAdminUser = api('delete', makeUrl`/admin/users/${'id'}`);
  updateAdminUser = api('put', makeUrl`/admin/users/${'id'}`);
  getAdminUserPassport = api('get', '/admin/user_passports');
  getAdminSuppliers = api('get', '/admin/user_passports/suppliers');
  getSpecificUserPassport = api('get', makeUrl`/user_passports/${'id'}`);
  adminGetSpecificUserPassport = api('get', makeUrl`/admin/user_passports/${'id'}`);
  createAdminUserPassport = api('post', makeUrl`/admin/user_passports`);
  updateAdminUserPassport = api('put', makeUrl`/admin/user_passports/${'id'}`);
  updateUserUserPassport = api('put', makeUrl`/user_passports/${'id'}`);
  updateUserPassportUbnCustomName = api('put', makeUrl`/user_passports/${'id'}/custom_ubn_name`);
  deleteAdminUserPassport = api('delete', makeUrl`/admin/user_passports/${'id'}`);
  cloneAdminUserPassport = api('post', makeUrl`/admin/user_passports/${'id'}/clone`);

  adminCreateOrganization = api('post', '/admin/organizations');
  adminDeleteOrganization = api('delete', makeUrl`/admin/organizations/${'id'}`);
  adminUpdateOrganization = api('put', makeUrl`/admin/organizations/${'id'}`);
  // admin bulk uploads
  adminUploadOrgPassportUsers = api('post', makeUrl`/admin/organization_passport_users/bulk_create`);
  adminUploadOrganizations = api('post', makeUrl`/admin/organizations/bulk_create`);
  adminUploadUsers = api('post', makeUrl`/auth/invitations/bulk_invite`);
  adminUploadUserPassports = api('post', makeUrl`/admin/user_passports/bulk_create`);

  adminPermissionProfileTemplates = api('get', makeUrl`/admin/permission_profile_templates`);
  adminPermissionRules = api('get', makeUrl`/admin/permission_rules`);
  adminGetPermissionProfileTemplate = api('get', makeUrl`/admin/permission_profile_templates/${'id'}`);
  adminCreatePermissionProfileTemplates = api('post', makeUrl`/admin/permission_profile_templates`);
  adminUpdatePermissionProfileTemplates = api('put', makeUrl`/admin/permission_profile_templates/${'id'}`);
  adminDeletePermissionProfileTemplates = api('delete', makeUrl`/admin/permission_profile_templates/${'id'}`);
  adminUpdateOrganizationPermissionProfile = api('put', makeUrl`/admin/organization_permission_profiles/${'id'}`);
  adminUserPassportUbnRelated = api('get', makeUrl`/admin/user_passports/ubn_related/${'ubn'}`);
  adminUserTypesList = api('get', '/admin/users/user_type_description_list');
  adminOrgPassportOptions = api('get', makeUrl`/admin/user_passports/autocomplete_tips/${'id'}`);
  adminDefaultOrgPassportOptions = api('get', '/admin/user_passports/default_autocomplete_tips');
  getLastChanges = api('get', makeUrl`/admin/update_logs/last_changes/${'id'}`);
  //
  updateOrganization = api('put', makeUrl`/organizations/${'id'}`);
  getFarmerInstructions = api('get', '/file_resources/farmer_ui_instructions');
  //
  createCompanyReport = api('post', '/farm_reports/company_reports');
  updateCompanyReport = api('put', makeUrl`/farm_reports/company_reports/${'id'}`);
  getCompanyReport = api('get', makeUrl`/farm_reports/company_reports/${'id'}`);
  adminGetCompanyReport = api('get', makeUrl`/admin/farm_reports/company_reports/${'id'}`);
  getCompanyReports = api('get', '/farm_reports/company_reports');
  getCompanyReportPreviews = api('get', '/farm_reports/company_reports/preview');
  adminGetCompanyReports = api('get', '/admin/farm_reports/company_reports');
  cloneCompanyReport = api('post', makeUrl`/farm_reports/company_reports/${'id'}/clone`);
  deleteCompanyReport = api('delete', makeUrl`/farm_reports/company_reports/${'id'}`);
  getCompanyReportManureTemplate = api('get', '/farm_reports/company_reports/manure_template');
  getCompanyReportManureFile = (id) => api('get', `/farm_reports/company_reports/${id}/manure_file`);
  //
  createByProductReport = api('post', '/farm_reports/product_reports');
  updateByProductReport = api('put', makeUrl`/farm_reports/product_reports/${'id'}`);
  getByProductReport = api('get', makeUrl`/farm_reports/product_reports/${'id'}`);
  adminGetByProductReport = api('get', makeUrl`/admin/farm_reports/product_reports/${'id'}`);
  getByProductReports = api('get', '/farm_reports/product_reports');
  adminGetByProductReports = api('get', '/admin/farm_reports/product_reports');
  cloneByProductReport = api('post', makeUrl`/farm_reports/product_reports/${'id'}/clone`);
  deleteByProductReport = api('delete', makeUrl`/farm_reports/product_reports/${'id'}`);
  //
  createFermentationReport = api('post', '/farm_reports/fermentation_reports');
  updateFermentationReport = api('put', makeUrl`/farm_reports/fermentation_reports/${'id'}`);
  getFermentationReport = api('get', makeUrl`/farm_reports/fermentation_reports/${'id'}`);
  adminGetFermentationReport = api('get', makeUrl`/admin/farm_reports/fermentation_reports/${'id'}`);
  getFermentationReports = api('get', '/farm_reports/fermentation_reports');
  adminGetFermentationReports = api('get', '/admin/farm_reports/fermentation_reports');
  cloneFermentationReport = api('post', makeUrl`/farm_reports/fermentation_reports/${'id'}/clone`);
  deleteFermentationReport = api('delete', makeUrl`/farm_reports/fermentation_reports/${'id'}`);
  //
  getTemplateConfigs = api('get', '/template_configs');
  getTemplateConfig = api('get', makeUrl`/template_configs/${'id'}`);
  createTemplateConfig = api('post', '/template_configs');
  updateTemplateConfig = api('put', makeUrl`/template_configs/${'id'}`);
  deleteTemplateConfig= api('delete', makeUrl`/template_configs/${'id'}`);
  cloneTemplateConfig= api('post', makeUrl`/template_configs/${'id'}/clone`);
  //
  createManureReport = api('post', '/farm_reports/manure_reports');
  updateManureReport = api('put', makeUrl`/farm_reports/manure_reports/${'id'}`);
  getManureReport = api('get', makeUrl`/farm_reports/manure_reports/${'id'}`);
  adminGetManureReport = api('get', makeUrl`/admin/farm_reports/manure_reports/${'id'}`);
  getManureReports = api('get', '/farm_reports/manure_reports');
  adminGetManureReports = api('get', '/admin/farm_reports/manure_reports');
  cloneManureReport = api('post', makeUrl`/farm_reports/manure_reports/${'id'}/clone`);
  deleteManureReport = api('delete', makeUrl`/farm_reports/manure_reports/${'id'}`);
  //
  adminGetReferenceTablesDashboard = api('get', '/admin/reference_tables/reference_uploads/dashboard');
  adminGetReferenceTablesConcepts = api('get', '/admin/reference_tables/reference_uploads/concepts');
  //
  adminGetManageUploads = api('get', '/admin/reference_tables/reference_uploads');
  adminUpdateManageUpload = api('put', makeUrl`/admin/reference_tables/reference_uploads/${'id'}`);
  adminDeleteManageUpload = api('delete', makeUrl`/admin/reference_tables/reference_uploads/${'id'}`);
  adminDownloadManageUpload = api('get', makeUrl`/admin/reference_tables/reference_uploads/${'id'}/download_file`, {
    responseType: 'blob',
  });
  //
  uploadPenLevelDocument = api('post', '/admin/reference_tables/pen_levels/upload');
  adminGetPenLevels = api('get', '/admin/reference_tables/pen_levels');
  //
  uploadPensTypeSowBarnDocument = api('post', '/admin/reference_tables/pen_type_sow_barns/upload');
  adminGetPensTypeSowBarn = api('get', '/admin/reference_tables/pen_type_sow_barns');
  //
  uploadYesNoDocument = api('post', '/admin/reference_tables/answer_codes/upload');
  adminGetYesNo = api('get', '/admin/reference_tables/answer_codes');
  //
  uploadCfpCo2EmissionFactorsDocument = api('post', '/admin/reference_tables/co2_emission_factors/upload');
  adminGetCfpCo2EmissionFactors = api('get', '/admin/reference_tables/co2_emission_factors');
  //
  uploadCvbTableDocument = api('post', '/admin/reference_tables/carbon_foot_prints/upload');
  adminGetCvbTable = api('get', '/admin/reference_tables/carbon_foot_prints');
  //
  uploadIpccEmissionFactorsDocument = api('post', '/admin/reference_tables/ipcc_emission_factors/upload');
  adminGetIpccEmissionFactors = api('get', '/admin/reference_tables/ipcc_emission_factors');
  //
  uploadcfpGfliFeedprintTableDocument = api('post', '/admin/reference_tables/feed_prints/upload');
  adminGetcfpGfliFeedprintTable = api('get', '/admin/reference_tables/feed_prints');
  //
  uploadContentOfNitricOxideDocument = api('post', '/admin/reference_tables/content_of_nitric_oxides/upload');
  adminGetContentOfNitricOxide = api('get', '/admin/reference_tables/content_of_nitric_oxides');
  //
  uploadPigHousingEmissionFactorsDocument = api('post', '/admin/reference_tables/pig_housing_emission_factors/upload');
  adminGetPigHousingEmissionFactors = api('get', '/admin/reference_tables/pig_housing_emission_factors');
  //
  uploadElectricityConsumptionRates = api('post', '/admin/reference_tables/electricity_consumption_rates/upload');
  adminGetElectricityConsumptionRates = api('get', '/admin/reference_tables/electricity_consumption_rates');
  //
  uploadGasConsumptionRates = api('post', '/admin/reference_tables/gas_consumption_rates/upload');
  adminGetGasConsumptionRates = api('get', '/admin/reference_tables/gas_consumption_rates');
  //
  uploadWaterConsumptionRates = api('post', '/admin/reference_tables/water_consumption_rates/upload');
  adminGetWaterConsumptionRates = api('get', '/admin/reference_tables/water_consumption_rates');
  //
  uploadRavCodes = api('post', '/admin/reference_tables/rav_codes/upload');
  adminGetRavCodes = api('get', '/admin/reference_tables/rav_codes');
  //
  uploadGwp100Factors = api('post', '/admin/reference_tables/gwp100_factors/upload');
  adminGetGwp100Factors = api('get', '/admin/reference_tables/gwp100_factors');
  //
  uploadWurMfcEfem = api('post', '/admin/reference_tables/wur_mcf_efems/upload');
  adminGetWurMfcEfem = api('get', '/admin/reference_tables/wur_mcf_efems');
  //
  uploadCl550 = api('post', '/admin/reference_tables/pig_disease_codes/upload');
  adminGetCl550 = api('get', '/admin/reference_tables/pig_disease_codes');
  //
  uploadCl649 = api('post', '/admin/reference_tables/charge_or_premium_code_types/upload');
  adminGetCl649 = api('get', '/admin/reference_tables/charge_or_premium_code_types');
  //
  uploadCl650 = api('post', '/admin/reference_tables/group_charge_or_premium_codes/upload');
  adminGetCl650 = api('get', '/admin/reference_tables/group_charge_or_premium_codes');
  //
  uploadCl607 = api('post', '/admin/reference_tables/animal_id_code_types/upload');
  adminGetCl607 = api('get', '/admin/reference_tables/animal_id_code_types');
  //
  uploadSuppliersNumbers = api('post', '/admin/reference_tables/supplier_numbers/upload');
  adminGetSuppliersNumbers = api('get', '/admin/reference_tables/supplier_numbers');
  //
  uploadCountryCodes = api('post', '/admin/reference_tables/country_codes/upload');
  adminGetCountryCodes = api('get', '/admin/reference_tables/country_codes');
  //
  uploadRavReferenceList = api('post', '/admin/reference_tables/rav_reference_lists/upload');
  adminGetRavReferenceList = api('get', '/admin/reference_tables/rav_reference_lists');
  //
  uploadAllocationManure = api('post', '/admin/reference_tables/allocation_manures/upload');
  adminGetAllocationManure = api('get', '/admin/reference_tables/allocation_manures');
  //
  uploadGreenhouseGasEmission = api('post', '/admin/reference_tables/greenhouse_gas_emissions/upload');
  adminGetGreenhouseGasEmission = api('get', '/admin/reference_tables/greenhouse_gas_emissions');
  //
  uploadEntitiesList = api('post', '/admin/reference_tables/entities_lists/upload');
  adminGetEntitiesList = api('get', '/admin/reference_tables/entities_lists');
  //
  uploadDeliveriesAuthorisedSuppliers = api('post', '/admin/reference_tables/deliveries_authorised_suppliers/upload');
  adminGetDeliveriesAuthorisedSuppliers = api('get', '/admin/reference_tables/deliveries_authorised_suppliers');
  //
  uploadMovementEventsApprovedUbns = api('post', '/admin/reference_tables/movement_events_approved_ubns/upload');
  adminGetMovementEventsApprovedUbns = api('get', '/admin/reference_tables/movement_events_approved_ubns');
  //
  uploadSlaughterhouseDataValues = api('post', '/admin/reference_tables/slaughterhouse_data_values/upload');
  adminGetSlaughterhouseDataValues = api('get', '/admin/reference_tables/slaughterhouse_data_values');
  //
  adminGetFarmGroups = api('get', '/admin/farm_managements/farm_groups');
  adminGetGroupFinancialEvents = api('get', '/admin/farm_managements/group_financial_events');
  adminGetGroupWeights = api('get', '/admin/farm_managements/group_weights');
  adminGetGroupRemovals = api('get', '/admin/farm_managements/group_removals');
  adminGetGroupArrival = api('get', '/admin/farm_managements/group_arrivals');
  adminGetGroupMoves = api('get', '/admin/farm_managements/group_moves');
  adminGetCodesAnimalRemarks = api('get', '/admin/farm_managements/animal_remarks');
  adminGetCodesDefects = api('get', '/admin/farm_managements/defects');
  adminGetCodesDiagnose = api('get', '/admin/farm_managements/diagnoses');
  adminGetCodesAnimalScore = api('get', '/admin/farm_managements/animal_scores');
  adminGetCodesInsemination = api('get', '/admin/farm_managements/inseminations');
  adminGetCodesTreatments = api('get', '/admin/farm_managements/treatments');
  adminGetGroupPurchasedFood = api('get', '/admin/farm_managements/purchased_foods');
  adminGetGroupUsedFood = api('get', '/admin/farm_managements/used_foods');
  adminGetCodesMatingType = api('get', '/admin/farm_managements/mating_types');
  adminGetCodesWeightType = api('get', '/admin/farm_managements/weight_types');
  adminGetCodesRemovalType = api('get', '/admin/farm_managements/removal_types');
  adminGetCodesAnimalIdentityType = api('get', '/admin/farm_managements/animal_identifier_types');
  adminGetCodesFarmOption = api('get', '/admin/farm_managements/farm_options');
  adminGetCodesBarn = api('get', '/admin/farm_managements/code_barns');
  adminGetCodesRoom = api('get', '/admin/farm_managements/code_rooms');
  adminGetCodesPen = api('get', '/admin/farm_managements/code_pens');
  adminGetCodesAccount = api('get', '/admin/farm_managements/code_accounts');
  adminGetCodesEventType = api('get', '/admin/farm_managements/code_event_types');
  adminGetGroupExpense = api('get', '/admin/farm_managements/group_expenses');
  //
  adminGetFarmerInputUpdatesLog = api('get', '/admin/farm_reports/farmer_input_logs');
  getUsers = api('get', '/users');
  //
  adminGetFarmManagementFtpUpdates = api('get', '/admin/farm_managements/farm_management_data_uploads');
  adminDownloadFarmManagementFtpUpdates = api(
    'get',
    makeUrl`/admin/farm_managements/farm_management_data_uploads/${'id'}/download_file`,
    {
      responseType: 'blob',
    },
  );
  adminDeleteFarmManagementFtpUpdates = api(
    'delete',
    makeUrl`/admin/farm_managements/farm_management_data_uploads/${'id'}`,
  );
  //
  adminCreateFarmManagementPassport = api(
    'post',
    makeUrl`/admin/user_passports/${'userPassportId'}/farm_management_passports`,
  );
  adminGetFarmManagementPassports = api(
    'get',
    makeUrl`/admin/user_passports/${'userPassportId'}/farm_management_passports`,
  );
  adminUpdateFarmManagementPassport = api(
    'put',
    makeUrl`/admin/user_passports/${'userPassportId'}/farm_management_passports/${'id'}`,
  );
  adminDeleteFarmManagementPassport = api(
    'delete',
    makeUrl`/admin/user_passports/${'userPassportId'}/farm_management_passports/${'id'}`,
  );
  //
  getPassportAliases = api('get', makeUrl`/user_passports/${'userPassportId'}/passport_aliases`);
  adminGetPassportAliases = api('get', '/admin/user_passports/passport_aliases');
  adminUploadPassportAliases = api('post', '/admin/user_passports/passport_aliases/upload');
  // adminGetPassportAliases = api('get', makeUrl`/admin/user_passports/${'userPassportId'}/passport_aliases`);
  adminCreatePassportAlias = api('post', makeUrl`/admin/user_passports/${'userPassportId'}/passport_aliases`);
  adminUpdatePassportAlias = api('put', makeUrl`/admin/user_passports/${'userPassportId'}/passport_aliases/${'id'}`);
  adminDeletePassportAlias = api('delete', makeUrl`/admin/user_passports/${'userPassportId'}/passport_aliases/${'id'}`);
  //
  adminGetCarbonFootprints = api('get', '/admin/carbon_dioxide_footprints');
  getCarbonFootprints = api('get', '/carbon_dioxide_footprints');
  getCarbonFootprintsUsers = api('get', '/admin/carbon_dioxide_footprints/uploaded_by_users');
  adminGetCarbonFootprint = api('get', makeUrl`/admin/carbon_dioxide_footprints/${'id'}`);
  getCarbonFootprint = api('get', makeUrl`/carbon_dioxide_footprints/${'id'}`);
  adminUpdateCarbonFootprint = api('put', makeUrl`/admin/carbon_dioxide_footprints/${'id'}`);
  adminDeleteCarbonFootprint = api('delete', makeUrl`/admin/carbon_dioxide_footprints/${'id'}`);
  //
  adminGetHFMOutputs = api('get', '/admin/hfm_outputs');
  adminGetHFMOutput = api('get', makeUrl`/admin/hfm_outputs/${'id'}`);
  adminUpdateHFMOutput = api('put', makeUrl`/admin/hfm_outputs/${'id'}`);
  adminDeleteHFMOutput = api('delete', makeUrl`/admin/hfm_outputs/${'id'}`);
  //
  createLabel = api('post', '/labels');
  adminCreateLabel = api('post', '/admin/labels');
  getLabels = api('get', '/labels');
  adminGetLabels = api('get', '/admin/labels');
  getLabel = api('get', makeUrl`/labels/${'id'}`);
  adminGetLabel = api('get', makeUrl`/admin/labels/${'id'}`);
  updateLabel = api('put', makeUrl`/labels/${'id'}`);
  adminUpdateLabel = api('put', makeUrl`/admin/labels/${'id'}`);
  deleteLabel = api('delete', makeUrl`/labels/${'id'}`);
  adminDeleteLabel = api('delete', makeUrl`/admin/labels/${'id'}`);
  //
  adminGetRequestStatistic = api('get', '/admin/request_logs/statistics');
  getRequestStatistic = api('get', '/request_logs/statistics');
  //
  adminGetRequestLogsUsers = api('get', '/admin/request_logs/users');
  getRequestLogsUsers = api('get', '/request_logs/users');
  //
  getDataStatistics = api('get', '/data_statistics');
  getSpecificDataStatistics = api('get', '/data_statistics/specific');
  //
  adminGetConceptList = api('get', '/admin/user_passports/concept_list');
  getConceptList = api('get', '/user_passports/concept_list');
  //
  adminGetCompaniesList = api('get', '/admin/users/companies');
  getCompaniesList = api('get', '/users/companies');
  //
  getRawMaterialsOrigin = api('get', '/raw_material_origins');
  getRawMaterialsOriginDetails = api('get', makeUrl`/raw_material_origins/${'id'}`);
  getAdminRawMaterialsOrigin = api('get', '/admin/raw_material_origins');
  getAdminRawMaterialsOriginDetails = api('get', makeUrl`/admin/raw_material_origins/${'id'}`);
  //
  getByProductsCarbonValues = api('get', '/farm_reports/product_reports/get_carbon_foot_print');
  //
  getCvbInstructions = api('get', '/file_resources/cvb_instruction_template');
  //
  adminGetErrorsLog = api('get', '/admin/error_logs');

  getSftpFileInfos = api('get', '/admin/sftp_file_infos');
  syncSftpFileInfos = api('get', '/admin/sftp_file_infos/sync');
  validateSftpFileInfos = api('post', '/admin/sftp_file_infos/validate');
  processSftpFileInfos = api('post', '/admin/sftp_file_infos/process_files');

  getAdminUploadHistory = api('get', '/admin/admin_file_uploads');
  downloadAdminUploadHistory = api('get', makeUrl`/admin/admin_file_uploads/${'id'}`);

  readMemoListTx = api('post', '/blockchain/read_memo_list_tx');

  cfpCalculations = api('get', '/cfp_calculations');
  createCfpCalculation = api('post', '/cfp_calculations');
  downloadCfpCalculation = (id) => `/cfp_calculations/${id}/download`;
  startCfpCalculation = api('post', makeUrl`/cfp_calculations/${'id'}/start`);
  deleteCfpCalculation = api('delete', makeUrl`/cfp_calculations/${'id'}`);
  cfpCalculationsUbns = api('get', '/cfp_calculations/ubns');
  cfpCalculationsYears = api('get', '/cfp_calculations/years');

  getMyAlerts = api('get', '/my_alerts');
}

const apiClient = ApiClient.getInstance(Axios);

export { apiClient as ApiClient };
