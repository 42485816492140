import { call, takeEvery, put } from 'redux-saga/effects';
import {
  farmDeleteStable,
  farmGetStables,
  farmUpdateStables,
  fetchOrganizationPassport,
  farmCreateDeathReport,
  farmCreateSupportReport,
  farmCreateDeliveryReport,
  farmCreateFeedConsumptionReport,
  farmGetSupportReport,
  farmGetDeathReport,
  farmGetDeliveryReport,
  farmGetFeedConsumptionReport,
  farmUpdateSupportReport,
  farmUpdateDeathReport,
  farmUpdateDeliveryReport,
  farmUpdateFeedConsumptionReport,
  farmDeleteDeathReport,
  farmDeleteSupportReport,
  farmDeleteDeliveryReport,
  farmDeleteFeedConsumptionReport,
  farmGetEvents,
  fetchProductReportsStoredValues,
  fetchFermentationReportsStoredValues,
  fetchByProductsCarbonValues,
  fetchPassportStatuses,
} from 'store/reducers/farmReducer';
import ensure from 'lib/ensure';
import { ApiClient } from 'lib/http/ApiClient';
import { ResourcesWrapper } from 'lib/http/utils';
import {
  UserPassportPayload,
  UserPassportTransformedPayload,
  serializeUserPassports,
} from 'store/entities/userPassports';
import { ObjectWithProps } from 'lib/excel/serilizers/Cell';
import { serializeFarmStables, serializeFarmEvents } from 'store/entities/farm';
import { serializeCvbTableRecord } from 'store/entities/referenceTables/cvbTable';
import filterConfigs from 'store/filters/entities';
import { fetchBatchesAction } from 'store/reducers/batchReducer';

export const ensureGetOrganizationPassports = ensure({
  api: ApiClient.getUserPassports,
  action: fetchOrganizationPassport,
  serializeSuccessPayload: (response: ResourcesWrapper<UserPassportPayload>) => {
    const transformedRequestAlike: ResourcesWrapper<UserPassportTransformedPayload> = {
      ...response,
      data: {
        ...response.data,
        resources: response.data.resources
          .map(({ passport_owners, ...org }) => {
            const { name, user_type, email } = passport_owners?.[0] || { name: '', user_type: '', email: '' };
            return {
              ...org,
              user_type,
              user_name: name,
              user_email: email,
            };
          })
          .flat(1),
      },
    };

    return serializeUserPassports(transformedRequestAlike).UserPassports;
  },
});
export const ensureGetPassportStatuses = ensure({
  api: ApiClient.getUserPassportsStatuses,
  action: fetchPassportStatuses,
});

export const ensureGetEvents = ensure({
  api: ApiClient.getFarmEvents,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeFarmEvents,
  transformRequestData: ({ filters = {} }) => {
    return filterConfigs['farmEvents'].serializeFilters(filters);
  },
});

export const ensureGetFarmStable = ensure({
  api: ApiClient.getFarmStables,
  action: farmGetStables,
  serializeSuccessPayload: serializeFarmStables,
});

export const ensureUpdateFarmStable = ensure({
  api: ApiClient.updateFarmStables,
  action: farmUpdateStables,
  serializeSuccessPayload: serializeFarmStables,
});

export const ensureDeleteFarmStable = ensure({
  api: ApiClient.deleteFarmStable,
  action: farmDeleteStable,
});

const ensureCreateSupportReport = ensure({
  api: ApiClient.createFarmSupportReport,
  action: farmCreateSupportReport,
});

const ensureCreateDeathReport = ensure({
  api: ApiClient.createFarmDeathReport,
  action: farmCreateDeathReport,
});

const ensureCreateDeliveryReport = ensure({
  api: ApiClient.createFarmDeliveryReport,
  action: farmCreateDeliveryReport,
});

const ensureCreateFeedConsumptionReport = ensure({
  api: ApiClient.createFarmFeedConsumptionReport,
  action: farmCreateFeedConsumptionReport,
});

const ensureGetSupportReport = ensure({
  api: ApiClient.getFarmSupportReport,
  action: farmGetSupportReport,
});

const ensureGetDeathReport = ensure({
  api: ApiClient.getFarmDeathReport,
  action: farmGetDeathReport,
});

const ensureGetDeliveryReport = ensure({
  api: ApiClient.getFarmDeliveryReport,
  action: farmGetDeliveryReport,
});

const ensureGetFeedConsumptionReport = ensure({
  api: ApiClient.getFarmFeedConsumptionReport,
  action: farmGetFeedConsumptionReport,
});

const ensureUpdateSupportReport = ensure({
  api: ApiClient.updateFarmSupportReport,
  action: farmUpdateSupportReport,
});

const ensureUpdateDeathReport = ensure({
  api: ApiClient.updateFarmDeathReport,
  action: farmUpdateDeathReport,
});

const ensureUpdateDeliveryReport = ensure({
  api: ApiClient.updateFarmDeliveryReport,
  action: farmUpdateDeliveryReport,
});

const ensureUpdateFeedConsumptionReport = ensure({
  api: ApiClient.updateFarmFeedConsumptionReport,
  action: farmUpdateFeedConsumptionReport,
});

const ensureDeleteSupportReport = ensure({
  api: ApiClient.deleteFarmSupportReport,
  action: farmDeleteSupportReport,
});

const ensureDeleteDeathReport = ensure({
  api: ApiClient.deleteFarmDeathReport,
  action: farmDeleteDeathReport,
});

const ensureDeleteDeliveryReport = ensure({
  api: ApiClient.deleteFarmDeliveryReport,
  action: farmDeleteDeliveryReport,
});

const ensureDeleteFeedConsumptionReport = ensure({
  api: ApiClient.deleteFarmFeedConsumptionReport,
  action: farmDeleteFeedConsumptionReport,
});

export const ensureGetProductReportsStoredValues = ensure({
  api: ApiClient.getProductReportsStoredValuesList,
  action: fetchProductReportsStoredValues,
});

export const ensureGetFermentationReportsStoredValues = ensure({
  api: ApiClient.getFermentationReportsStoredValuesList,
  action: fetchFermentationReportsStoredValues,
});

export const ensureGetByProductsCarbonValues = ensure({
  api: ApiClient.getByProductsCarbonValues,
  action: fetchByProductsCarbonValues,
  serializeSuccessPayload: serializeCvbTableRecord,
});

const makeRequest = (ensure: any) => {
  return function* ({ payload = {} }: ObjectWithProps) {
    const { ubn, id, resource } = payload;

    yield call(ensure, { params: { ubn, id }, resource });
  };
};

const deleteReport = (ensure: any) => {
  return function* ({ payload = {} }: ObjectWithProps) {
    const { ubn, id, resource } = payload;

    yield call(ensure, { params: { ubn, id }, resource });
    yield put({ type: farmGetEvents.request });
  };
};

const getByProductCarbonValues = (ensure: any) => {
  return function* ({ payload }: ObjectWithProps) {
    const { params, ...rest } = payload;

    yield call(ensure, { params, ...rest });
  };
};

export default function* () {
  yield takeEvery(fetchOrganizationPassport.request, ensureGetOrganizationPassports);
  yield takeEvery(fetchPassportStatuses.request, ensureGetPassportStatuses);

  yield takeEvery(farmGetStables.request, makeRequest(ensureGetFarmStable));
  yield takeEvery(farmUpdateStables.request, makeRequest(ensureUpdateFarmStable));
  yield takeEvery(farmDeleteStable.request, makeRequest(ensureDeleteFarmStable));

  yield takeEvery(farmCreateSupportReport.request, makeRequest(ensureCreateSupportReport));
  yield takeEvery(farmCreateDeathReport.request, makeRequest(ensureCreateDeathReport));
  yield takeEvery(farmCreateDeliveryReport.request, makeRequest(ensureCreateDeliveryReport));
  yield takeEvery(farmCreateFeedConsumptionReport.request, makeRequest(ensureCreateFeedConsumptionReport));

  yield takeEvery(farmGetSupportReport.request, makeRequest(ensureGetSupportReport));
  yield takeEvery(farmGetDeathReport.request, makeRequest(ensureGetDeathReport));
  yield takeEvery(farmGetDeliveryReport.request, makeRequest(ensureGetDeliveryReport));
  yield takeEvery(farmGetFeedConsumptionReport.request, makeRequest(ensureGetFeedConsumptionReport));

  yield takeEvery(farmUpdateSupportReport.request, makeRequest(ensureUpdateSupportReport));
  yield takeEvery(farmUpdateDeathReport.request, makeRequest(ensureUpdateDeathReport));
  yield takeEvery(farmUpdateDeliveryReport.request, makeRequest(ensureUpdateDeliveryReport));
  yield takeEvery(farmUpdateFeedConsumptionReport.request, makeRequest(ensureUpdateFeedConsumptionReport));

  yield takeEvery(farmDeleteSupportReport.request, deleteReport(ensureDeleteSupportReport));
  yield takeEvery(farmDeleteDeathReport.request, deleteReport(ensureDeleteDeathReport));
  yield takeEvery(farmDeleteDeliveryReport.request, deleteReport(ensureDeleteDeliveryReport));
  yield takeEvery(farmDeleteFeedConsumptionReport.request, deleteReport(ensureDeleteFeedConsumptionReport));

  yield takeEvery(farmGetEvents.request, makeRequest(ensureGetEvents));

  yield takeEvery(fetchProductReportsStoredValues.request, makeRequest(ensureGetProductReportsStoredValues));
  yield takeEvery(fetchFermentationReportsStoredValues.request, makeRequest(ensureGetFermentationReportsStoredValues));
  yield takeEvery(fetchByProductsCarbonValues.request, getByProductCarbonValues(ensureGetByProductsCarbonValues));
}
