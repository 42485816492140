import { SLAUGHTERHOUSE_DATA_VALUES_FIELDS } from 'lib/excel/config/referenceTables/slaughterhouseDataValues/structure';
import { DisplayDocument } from 'lib/excel/display/DisplayDocument';
import { DisplayTab } from 'lib/excel/display/DisplayTab';
import { DisplayHeader } from 'lib/excel/display/DisplayHeader';

const { columns, view } = SLAUGHTERHOUSE_DATA_VALUES_FIELDS.SlaughterhouseDataValues;

export const getSlaughterhouseDataValuesDisplayModel = () => {
  return new DisplayDocument([
    new DisplayTab(view, [
      new DisplayHeader({ headerName: columns.value.serialized, extractPropName: columns.value.view }),
      new DisplayHeader({ headerName: columns.unit.serialized, extractPropName: columns.unit.view }),
    ]),
  ]);
};
