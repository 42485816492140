import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';
import { ResourcesWrapper } from 'lib/http/utils';

import { DELIVERIES_AUTHORISED_SUPPLIERS } from './config';
import { ManageUploadPayload } from '../manageUploads';

export type DeliveriesAuthorisedSuppliersPayload = {
  id: number;
  feed_supplier_name: string | null;
  supplier_number: string | null;
  deliveries_authorised_suppliers_upload: ManageUploadPayload;
};

export type DeliveriesAuthorisedSuppliersSerialized = {
  id: number;
  referenceUploadID: number;
  feedSupplierName: string;
  supplierNumber: string;
  uploadType: string;
  status: 'active' | 'inactive';
  filename: string;
  validDateFrom: string | null;
  validDateTo: string | null;
  notes: string;
  concepts: string[];
  uploadedAt: string;
  uploadedBy: string;
};

const { columns, serialized, unserialized } = DELIVERIES_AUTHORISED_SUPPLIERS.DeliveriesAuthorisedSuppliers;

const getDefaultSerializer = (data: DocumentData<DeliveriesAuthorisedSuppliersPayload[]>) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(unserialized, serialized, [
      new CellSerializer(columns.id.unserialized, columns.id.serialized),
      new CellSerializer(columns.referenceUploadID.unserialized, columns.referenceUploadID.serialized),
      new CellSerializer(columns.feedSupplierName.unserialized, columns.feedSupplierName.serialized),
      new CellSerializer(columns.supplierNumber.unserialized, columns.supplierNumber.serialized),
      new CellSerializer(columns.uploadType.unserialized, columns.uploadType.serialized),
      new CellSerializer(columns.status.unserialized, columns.status.serialized),
      new CellSerializer(columns.filename.unserialized, columns.filename.serialized),
      new CellSerializer(
        columns.validDateFrom.unserialized,
        columns.validDateFrom.serialized,
        undefined,
        undefined,
        null,
      ),
      new CellSerializer(columns.validDateTo.unserialized, columns.validDateTo.serialized, undefined, undefined, null),
      new CellSerializer(columns.notes.unserialized, columns.notes.serialized),
      new CellSerializer(columns.concepts.unserialized, columns.concepts.serialized),
      new CellSerializer(columns.uploadedAt.unserialized, columns.uploadedAt.serialized),
      new CellSerializer(columns.uploadedBy.unserialized, columns.uploadedBy.serialized),
    ]),
  ]);
};

export const serializeDeliveriesAuthorisedSuppliers = (serverData: ResourcesWrapper<DeliveriesAuthorisedSuppliersPayload>) => {
  const data = {
    [unserialized]: serverData.data.resources,
  };

  const serializer = getDefaultSerializer(data);

  return serializer.serialize();
};
