import { IntlKeys } from 'lib/localization/keys/__keys';

enum SlaughterhouseDataValuesOriginal {
  SlaughterhouseDataValues = 'Slaughterhouse data values',
}

enum SlaughterhouseDataValuesSerialized {
  SlaughterhouseDataValues = 'SlaughterhouseDataValues',
}

enum SlaughterhouseDataValuesUnserialized {
  SlaughterhouseDataValues = 'SlaughterhouseDataValues',
}

enum SlaughterhouseDataValuesOriginalHeaders {
  'value' = 'Value',
  'unit' = 'Unit',
}

enum SlaughterhouseDataValuesSerializedHeaders {
  'value' = 'value',
  'unit' = 'unit',
}

enum SlaughterhouseDataValuesUnserializedHeaders {
  'value' = 'value',
  'unit' = 'unit',
}

export const SLAUGHTERHOUSE_DATA_VALUES_FIELDS = {
  [SlaughterhouseDataValuesUnserialized.SlaughterhouseDataValues]: {
    original: SlaughterhouseDataValuesOriginal.SlaughterhouseDataValues,
    serialized: SlaughterhouseDataValuesSerialized.SlaughterhouseDataValues,
    unserialized: SlaughterhouseDataValuesUnserialized.SlaughterhouseDataValues,
    view: IntlKeys.referenceTablesSlaughterhouseDataValues,
    viewName: 'Slaughterhouse data values',

    columns: {
      [SlaughterhouseDataValuesSerializedHeaders.value]: {
        original: SlaughterhouseDataValuesOriginalHeaders.value,
        serialized: SlaughterhouseDataValuesSerializedHeaders.value,
        unserialized: SlaughterhouseDataValuesUnserializedHeaders.value,
        view: IntlKeys.referenceTablesSlaughterhouseDataValuesValue,
      },
      [SlaughterhouseDataValuesSerializedHeaders.unit]: {
        original: SlaughterhouseDataValuesOriginalHeaders.unit,
        serialized: SlaughterhouseDataValuesSerializedHeaders.unit,
        unserialized: SlaughterhouseDataValuesUnserializedHeaders.unit,
        view: IntlKeys.referenceTablesSlaughterhouseDataValuesUnit,
      },
    },
  },
}
