import { DELIVERIES_AUTHORISED_SUPPLIERS_FIELDS } from 'lib/excel/config/referenceTables/deliveriesAutorisedSuppliers/structure';
import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';

const { columns, serialized, original } = DELIVERIES_AUTHORISED_SUPPLIERS_FIELDS.DeliveriesAuthorisedSuppliers;

export const serializeDeliveriesAuthorisedSuppliersData = (data: DocumentData) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(original, serialized, [
      new CellSerializer(columns.feedSupplierName.original, columns.feedSupplierName.serialized),
      new CellSerializer(columns.supplierNumber.original, columns.supplierNumber.serialized),
    ]),
  ]);
};
