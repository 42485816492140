import { IntlKeys } from 'lib/localization/keys';

enum MovementEventsApprovedUbnsSerialized {
  MovementEventsApprovedUbns = 'MovementEventsApprovedUbns',
}

enum MovementEventsApprovedUbnsUnserialized {
  MovementEventsApprovedUbns = 'MovementEventsApprovedUbns',
}

export enum MovementEventsApprovedUbnsSerializedHeaders {
  id = 'id',
  referenceUploadID = 'referenceUploadID',
  ubn = 'ubn',
  uploadType = 'uploadType',
  filename = 'filename',
  status = 'status',
  validDateFrom = 'validDateFrom',
  validDateTo = 'validDateTo',
  notes = 'notes',
  concepts = 'concepts',
  uploadedAt = 'uploadedAt',
  uploadedBy = 'uploadedBy',
}

export const MovementEventsApprovedUbnsUnserializedHeaders = {
  id: 'id',
  referenceUploadID: ['movement_events_approved_ubns_upload', 'id'],
  ubn: 'ubn',
  businessUnit: 'business_unit',
  uploadType: ['movement_events_approved_ubns_upload', 'upload_type'],
  filename: ['movement_events_approved_ubns_upload', 'file_name'],
  status: ['movement_events_approved_ubns_upload', 'status'],
  validDateFrom: ['movement_events_approved_ubns_upload', 'valid_from'],
  validDateTo: ['movement_events_approved_ubns_upload', 'valid_to'],
  notes: ['movement_events_approved_ubns_upload', 'notes'],
  concepts: ['movement_events_approved_ubns_upload', 'concepts'],
  uploadedAt: ['movement_events_approved_ubns_upload', 'uploaded_at'],
  uploadedBy: ['movement_events_approved_ubns_upload', 'uploaded_by', 'full_name'],
} as const;

export const MOVEMENT_EVENTS_APPROVED_UBNS = {
  [MovementEventsApprovedUbnsSerialized.MovementEventsApprovedUbns]: {
    serialized: MovementEventsApprovedUbnsSerialized.MovementEventsApprovedUbns,
    unserialized: MovementEventsApprovedUbnsUnserialized.MovementEventsApprovedUbns,
    view: IntlKeys.referenceTablesMovementEventsApprovedUbns,

    columns: {
      [MovementEventsApprovedUbnsSerializedHeaders.id]: {
        serialized: MovementEventsApprovedUbnsSerializedHeaders.id,
        unserialized: MovementEventsApprovedUbnsUnserializedHeaders.id,
      },
      [MovementEventsApprovedUbnsSerializedHeaders.referenceUploadID]: {
        serialized: MovementEventsApprovedUbnsSerializedHeaders.referenceUploadID,
        unserialized: MovementEventsApprovedUbnsUnserializedHeaders.referenceUploadID,
      },
      [MovementEventsApprovedUbnsSerializedHeaders.ubn]: {
        serialized: MovementEventsApprovedUbnsSerializedHeaders.ubn,
        unserialized: MovementEventsApprovedUbnsUnserializedHeaders.ubn,
        view: IntlKeys.referenceTablesMovementEventsApprovedUbnsUbn,
      },
      [MovementEventsApprovedUbnsSerializedHeaders.status]: {
        serialized: MovementEventsApprovedUbnsSerializedHeaders.status,
        unserialized: MovementEventsApprovedUbnsUnserializedHeaders.status,
      },
      [MovementEventsApprovedUbnsSerializedHeaders.uploadType]: {
        serialized: MovementEventsApprovedUbnsSerializedHeaders.uploadType,
        unserialized: MovementEventsApprovedUbnsUnserializedHeaders.uploadType,
      },
      [MovementEventsApprovedUbnsSerializedHeaders.notes]: {
        serialized: MovementEventsApprovedUbnsSerializedHeaders.notes,
        unserialized: MovementEventsApprovedUbnsUnserializedHeaders.notes,
      },
      [MovementEventsApprovedUbnsSerializedHeaders.concepts]: {
        serialized: MovementEventsApprovedUbnsSerializedHeaders.concepts,
        unserialized: MovementEventsApprovedUbnsUnserializedHeaders.concepts,
        view: IntlKeys.concepts,
      },
      [MovementEventsApprovedUbnsSerializedHeaders.filename]: {
        serialized: MovementEventsApprovedUbnsSerializedHeaders.filename,
        unserialized: MovementEventsApprovedUbnsUnserializedHeaders.filename,
      },
      [MovementEventsApprovedUbnsSerializedHeaders.validDateFrom]: {
        serialized: MovementEventsApprovedUbnsSerializedHeaders.validDateFrom,
        unserialized: MovementEventsApprovedUbnsUnserializedHeaders.validDateFrom,
      },
      [MovementEventsApprovedUbnsSerializedHeaders.validDateTo]: {
        serialized: MovementEventsApprovedUbnsSerializedHeaders.validDateTo,
        unserialized: MovementEventsApprovedUbnsUnserializedHeaders.validDateTo,
      },
      [MovementEventsApprovedUbnsSerializedHeaders.uploadedAt]: {
        serialized: MovementEventsApprovedUbnsSerializedHeaders.uploadedAt,
        unserialized: MovementEventsApprovedUbnsUnserializedHeaders.uploadedAt,
      },
      [MovementEventsApprovedUbnsSerializedHeaders.uploadedBy]: {
        serialized: MovementEventsApprovedUbnsSerializedHeaders.uploadedBy,
        unserialized: MovementEventsApprovedUbnsUnserializedHeaders.uploadedBy,
      },
    },
  },
};
