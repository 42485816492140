import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { CellSerializer, ObjectWithProps } from 'lib/excel/serilizers/Cell';
import { SERVICE_FIELDS } from 'lib/excel/config/service';
import { ResourcesWrapper, ResourceWrapper } from 'lib/http/utils';
import { ADMIN_UPDATES } from 'lib/tables/config/admin/updates/config';
import { get, mapKeys, camelCase } from 'lodash';

export enum AdminUpdatesLogUnserializedHeaders {
  loggableId = 'loggable_id',
  loggableType = 'loggable_type',
  idValue = 'id_value',
  actionType = 'action_type',
  payload = 'payload',
  triggeredBy = 'triggered_by',
}

export enum AdminUpdatesLogSerializedHeaders {
  loggableId = 'loggableId',
  loggableType = 'loggableType',
  actionType = 'actionType',
  payload = 'payload',
  triggeredBy = 'triggeredBy',
}

export enum AdminUpdatesLoggableType {
  UserPassport = 'UserPassport',
  UserPassportConcept = 'UserPassportConcept',
  Organization = 'Organization',
  PermissionProfileTemplate = 'PermissionProfileTemplate',
  User = 'User',
  OrganizationPermissionProfile = 'OrganizationPermissionProfile',
}

export enum AdminUpdatesActionType {
  created = 'created',
  updated = 'updated',
  updated_by_simple_user = 'updated_by_simple_user',
  status_changed = 'status_changed',
  removed = 'removed',
  invite_accepted = 'invite_accepted',
  invited = 'invited',
  invitation_resent = 'invitation_resent'
}

export interface AdminUpdatesLogUnserialized {
  id: number;
  loggable_id: number;
  loggable_type: AdminUpdatesLoggableType;
  id_value: string;
  action_type: AdminUpdatesActionType;
  payload: ObjectWithProps;
  created_at: string;
  updated_at: string;
  triggered_by: {
    email: string;
    name: string;
  };
}

export interface AdminUpdatesLogSerialized {
  id: number;
  loggableId: number;
  loggableType: AdminUpdatesLoggableType;
  actionType: AdminUpdatesActionType;
  payload: ObjectWithProps;
  createdAt: string;
  updatedAt: string;
  triggeredBy: {
    email: string;
    name: string;
  };
}

const getSerializer = (data: DocumentData<AdminUpdatesLogUnserialized[]>) =>
  new DocumentSerializer(data, [
    new SheetSerializer(ADMIN_UPDATES.AdminUpdates.unserialized, ADMIN_UPDATES.AdminUpdates.serialized, [
      new CellSerializer(SERVICE_FIELDS.id.unserialized, SERVICE_FIELDS.id.serialized),
      new CellSerializer(AdminUpdatesLogUnserializedHeaders.loggableId, AdminUpdatesLogSerializedHeaders.loggableId),
      new CellSerializer(
        AdminUpdatesLogUnserializedHeaders.loggableType,
        AdminUpdatesLogSerializedHeaders.loggableType,
      ),
      new CellSerializer(AdminUpdatesLogUnserializedHeaders.actionType, AdminUpdatesLogSerializedHeaders.actionType),
      new CellSerializer(
        AdminUpdatesLogUnserializedHeaders.payload,
        AdminUpdatesLogSerializedHeaders.payload,
        (data, sheetData, excelRow, propertyOriginalPath, args) => {
          const value = get(excelRow, propertyOriginalPath);
          return mapKeys(value, (value, key) => camelCase(key));
        },
      ),
      new CellSerializer(AdminUpdatesLogUnserializedHeaders.triggeredBy, AdminUpdatesLogSerializedHeaders.triggeredBy),
      new CellSerializer(SERVICE_FIELDS.createdAt.unserialized, SERVICE_FIELDS.createdAt.serialized),
      new CellSerializer(SERVICE_FIELDS.updatedAt.unserialized, SERVICE_FIELDS.updatedAt.serialized),
    ]),
  ]);
export const serializeAdminUpdateLogs = (response: ResourcesWrapper<AdminUpdatesLogUnserialized>) => {
  const data = {
    [ADMIN_UPDATES.AdminUpdates.unserialized]: response.data.resources,
  };

  const serializer = getSerializer(data);

  return serializer.serialize();
};

export const serializeAdminUpdateLog = (response: ResourceWrapper<AdminUpdatesLogUnserialized>) => {
  const data = {
    [ADMIN_UPDATES.AdminUpdates.unserialized]: [response.data.resource],
  };

  const serializer = getSerializer(data);

  return serializer.serialize()[ADMIN_UPDATES.AdminUpdates.serialized][0];
};
