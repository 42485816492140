import { DELIVERIES_AUTHORISED_SUPPLIERS_FIELDS } from 'lib/excel/config/referenceTables/deliveriesAutorisedSuppliers/structure';
import { DisplayDocument } from 'lib/excel/display/DisplayDocument';
import { DisplayTab } from 'lib/excel/display/DisplayTab';
import { DisplayHeader } from 'lib/excel/display/DisplayHeader';

const { columns, view } = DELIVERIES_AUTHORISED_SUPPLIERS_FIELDS.DeliveriesAuthorisedSuppliers;

export const getDeliveriesAuthorisedSuppliersDisplayModel = () => {
  return new DisplayDocument([
    new DisplayTab(view, [
      new DisplayHeader({ headerName: columns.feedSupplierName.serialized, extractPropName: columns.feedSupplierName.view }),
      new DisplayHeader({ headerName: columns.supplierNumber.serialized, extractPropName: columns.supplierNumber.view }),
    ]),
  ]);
};
