import React, { useCallback, useMemo, useEffect } from 'react';
import moment, { Moment } from 'moment';
import { useFormikContext } from 'formik';
import { useIntl } from 'react-intl';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { get } from 'lodash';

import { IntlKeys } from 'lib/localization/keys';

import { StyledFormControl } from '../FilterMenu/fields/styled';
import { ObjectWithProps } from "../../lib/excel/serilizers/Cell";

export type DatePeriod = 'quarter' | 'halfYear' | 'year';

export type DateType = Date | Moment | string | null | undefined;

export type OptionValue = {
  dateFrom?: DateType;
  dateTo?: DateType;
};

export type OptionType = {
  label: { intlId: string, values?: ObjectWithProps };
  value: OptionValue;
};

export type AdditionalOptions = {
  onlyYearsRange?: {
    rangeStartYear: string;
    rangeEndYear: string;
  };
};

export type DatePeriodOptions = {
  firstMonth: number;
  gapWithinPeriodInMonths: number;
  monthsInPeriod: number;
};

export interface PeriodDatePickerProps {
  startYear: string;
  dateFromFieldName: string;
  dateToFieldName: string;
  type: DatePeriod;
  includeCurrentPeriod?: boolean;
  className?: string;
  disabled?: boolean;
  additionalOptions?: AdditionalOptions;
  initialDates?: OptionValue;
  getOptions?: (options: any) => void;
  current?: { dateFrom: string, dateTo: string };
}

export const previousYear = new Date().getFullYear() - 1;

export const isDate = (date: DateType): date is Date => date instanceof Date;

const DATE_FORMAT = 'YYYY-MM-DD';
const LABEL_DATE_FORMAT = 'D MMM';

export function monthsFromNow(startYear: string) {
  return () => Math.round(moment().endOf('month').diff(moment(startYear).startOf('year'), 'month', true))
}

export function getDatePeriodOptions(typeOfPeriod: DatePeriod) {
  switch (typeOfPeriod) {
    default:
    case 'quarter':
      return {
        firstMonth: 1,
        gapWithinPeriodInMonths: 2,
        monthsInPeriod: 3,
      };
    case 'halfYear':
      return {
        firstMonth: 1,
        gapWithinPeriodInMonths: 5,
        monthsInPeriod: 6,
      };
    case 'year':
      return {
        firstMonth: 1,
        gapWithinPeriodInMonths: 11,
        monthsInPeriod: 12,
      };
  }
}

export function getDataPeriodLabel(dateFrom: Moment, dateTo: Moment, typeOfPeriod: DatePeriod) {
  switch (typeOfPeriod) {
    default:
    case 'quarter':
      const quarter = `Q${ dateFrom.clone().quarter() }`;
      return {
        intlId: IntlKeys.dateRange,
        values: {
          start: `${ dateFrom.year() } ${ quarter }`,
          from: dateFrom.format(LABEL_DATE_FORMAT),
          to: dateTo.format(LABEL_DATE_FORMAT)
        }
      }
    case 'halfYear':
      const firstMonthNumberOfTheSecondHalf = 7;
      const halfOfTheYear = Number(dateFrom.format('M')) < firstMonthNumberOfTheSecondHalf ? 'H1' : 'H2';
      return {
        intlId: IntlKeys.dateRange,
        values: {
          start: `${ dateFrom.year() } ${ halfOfTheYear }`,
          from: dateFrom.format(LABEL_DATE_FORMAT),
          to: dateTo.format(LABEL_DATE_FORMAT)
        }
      }
    case 'year':
      return {
        intlId: IntlKeys.dateRange,
        values: {
          start: `${dateFrom.year()}`,
          from: dateFrom.format(LABEL_DATE_FORMAT),
          to: dateTo.format(LABEL_DATE_FORMAT)
        }
      }
  }
}

export function generateDatePeriods(date: Moment, monthFromEndDate: number, typeOfPeriod: DatePeriod, datePeriodOptions: DatePeriodOptions, includeCurrentPeriod?: boolean) {
  const datePeriodsArray = [] as OptionType[];

  const { firstMonth, gapWithinPeriodInMonths, monthsInPeriod } = datePeriodOptions;

  for (let i = monthFromEndDate; i >= firstMonth; i -= monthsInPeriod) {
    const clonedDate = date.clone();
    const dateFrom = clonedDate.startOf('month').clone();
    const dateTo = clonedDate.add(gapWithinPeriodInMonths, 'month').endOf('month').clone();

    if (!includeCurrentPeriod) {
      const isCurrentPeriod = moment().diff(moment(dateTo)) <= 0;
      if (isCurrentPeriod) continue;
    }

    const value = {
      dateFrom: dateFrom.format(DATE_FORMAT),
      dateTo: dateTo.format(DATE_FORMAT),
    };

    const label = getDataPeriodLabel(dateFrom, dateTo, typeOfPeriod);

    datePeriodsArray.push({
      value,
      label,
    });

    date.add(monthsInPeriod, 'month').startOf('month');
  }

  return datePeriodsArray;
}

export function generateOptions(
  {
    onlyYearsRange,
    includeCurrent,
    startYear,
    type,
    current
  }: {
    onlyYearsRange?: { rangeStartYear: string, rangeEndYear: string },
    includeCurrent?: boolean,
    startYear: string,
    type: DatePeriod,
    current?: {
      dateFrom: string,
      dateTo: string
    }
  }): OptionType[] {
  const optionsArray = [] as OptionType[];
  let date: Moment;

  if (onlyYearsRange) {
    const { rangeStartYear, rangeEndYear } = onlyYearsRange;
    const monthsFromEndDate = Math.round(
      moment(rangeEndYear).endOf('year').diff(moment(rangeStartYear).startOf('year'), 'month', true),
    );
    date = moment(rangeStartYear).startOf('year');
    const yearsRangeOptions = getDatePeriodOptions('year');
    const generatedRangeDatePeriods = generateDatePeriods(date, monthsFromEndDate, 'year', yearsRangeOptions);
    optionsArray.push(...generatedRangeDatePeriods);
  }

  date = moment(startYear).startOf('year');
  const datePeriodOptions = getDatePeriodOptions(type);
  const generatedDatePeriods = generateDatePeriods(date, monthsFromNow(startYear)(), type, datePeriodOptions);
  optionsArray.push(...generatedDatePeriods);

  if (includeCurrent && current) {
    const allPeriods: any[] = [];
    for (let year = 2019; year <= previousYear; year++) {
      let date = moment(`${ year }`).startOf('year');
      const quartersPeriodOptions = getDatePeriodOptions('quarter');
      const generatedQuartersPeriods = generateDatePeriods(date, 12, 'quarter', quartersPeriodOptions);
      allPeriods.push(...generatedQuartersPeriods);

      date = moment(`${ year }`).startOf('year');
      const halfYearsPeriodOptions = getDatePeriodOptions('halfYear');
      const generatedHalfYearsPeriods = generateDatePeriods(date, 12, 'halfYear', halfYearsPeriodOptions);
      allPeriods.push(...generatedHalfYearsPeriods);
    }
    const currentPeriod = allPeriods.find(p => p.value.dateFrom === current.dateFrom && p.value.dateTo === current.dateTo)
    if (currentPeriod) {
      const isCurrentPeriodLegal = optionsArray.some(o => o.value.dateFrom === current.dateFrom && o.value.dateTo === current.dateTo);
      if (!isCurrentPeriodLegal) {
        optionsArray.push(currentPeriod);
      }
    }
  }

  return optionsArray;
}


const PeriodDatePicker = <T extends Record<string, any>>({
                                                           startYear,
                                                           dateFromFieldName,
                                                           dateToFieldName,
                                                           type,
                                                           additionalOptions,
                                                           initialDates,
                                                           className = '',
                                                           includeCurrentPeriod = true,
                                                           disabled = false,
                                                           getOptions,
                                                           current
                                                         }: PeriodDatePickerProps) => {
  const { formatMessage } = useIntl();
  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    status,
    setStatus
  } = useFormikContext<T>();
  const { apiErrors } = status ?? {};
  const { onlyYearsRange } = additionalOptions ?? {};

  const dateFromKey = dateFromFieldName as keyof typeof values;
  const dateToKey = dateToFieldName as keyof typeof values;

  useEffect(() => {
    if (initialDates?.dateFrom && initialDates?.dateTo) {
      setFieldValue(dateFromFieldName, initialDates.dateFrom);
      setFieldValue(dateToFieldName, initialDates.dateTo);
    }
  }, [dateFromFieldName, dateToFieldName, initialDates, setFieldValue]);

  const _monthsFromNow = useMemo(monthsFromNow(startYear), [startYear]);

  const getError = useCallback((fieldName: keyof typeof values) => get(touched, fieldName) && get(errors, fieldName), [
    touched,
    errors,
  ]);

  const _getDatePeriodOptions = useCallback((typeOfPeriod: DatePeriod) => {
    switch (typeOfPeriod) {
      default:
      case 'quarter':
        return {
          firstMonth: 1,
          gapWithinPeriodInMonths: 2,
          monthsInPeriod: 3,
        };
      case 'halfYear':
        return {
          firstMonth: 1,
          gapWithinPeriodInMonths: 5,
          monthsInPeriod: 6,
        };
      case 'year':
        return {
          firstMonth: 1,
          gapWithinPeriodInMonths: 11,
          monthsInPeriod: 12,
        };
    }
  }, [formatMessage]);

  const handleBlur = useCallback(() => setFieldTouched(dateToFieldName, true, true), [
    dateToFieldName,
    setFieldTouched,
  ]);

  const removeApiErrorOnChange = useCallback(() => {
    if ((apiErrors && apiErrors?.[dateFromKey]) || apiErrors?.[dateToKey]) {
      const { [dateFromKey]: _, [dateToKey]: __, ...rest } = apiErrors;

      setStatus({
        ...status,
        apiErrors: {
          ...rest,
        },
      });
    }
  }, [apiErrors, dateFromKey, dateToKey, setStatus, status]);

  const handleDatePeriodChange = useCallback(
    (event: React.ChangeEvent<{}>, newValue: any) => {
      setFieldValue(dateFromFieldName, newValue?.value?.dateFrom);
      setFieldValue(dateToFieldName, newValue?.value?.dateTo);
      removeApiErrorOnChange();
    },
    [dateFromFieldName, dateToFieldName, removeApiErrorOnChange, setFieldValue],
  );

  const staticProps = useMemo(
    () => ({
      getOptionLabel: (option: OptionType) => formatMessage(
        { id: `${option.label.intlId}` },
        option.label.values,
      ),
    }),
    [formatMessage],
  );

  const _getDataPeriodLabel = useCallback(getDataPeriodLabel, [formatMessage]);

  const _generateDatePeriods = useCallback(generateDatePeriods, [_getDataPeriodLabel, includeCurrentPeriod, formatMessage]);

  const _generateOptions = useCallback(generateOptions, [onlyYearsRange, startYear, _getDatePeriodOptions, type, _generateDatePeriods, _monthsFromNow, formatMessage]);

  const enabledOptions = useMemo(() => _generateOptions({
    includeCurrent: false,
    onlyYearsRange,
    startYear,
    type,
    current
  }), [_generateOptions, formatMessage]);
  const allOptions = useMemo(() => _generateOptions({
    includeCurrent: true,
    onlyYearsRange,
    startYear,
    type,
    current
  }), [_generateOptions, formatMessage]);

  if (getOptions) {
    getOptions(enabledOptions);
  }

  const formattedDate = (date: DateType | undefined) => date && moment(date).utc().format(DATE_FORMAT);

  const currentValue = useMemo(() => {
    if (values?.[dateFromKey] && values?.[dateToKey]) {
      return allOptions.find((option) => {
        const {
          value: { dateFrom, dateTo },
        } = option;

        if (isDate(values?.[dateFromKey]) && isDate(values?.[dateToKey])) {
          return dateFrom === formattedDate(values?.[dateFromKey]) && dateTo === formattedDate(values?.[dateToKey])
            ? option
            : undefined;
        }

        return dateFrom === values?.[dateFromKey] && dateTo === values?.[dateToKey] ? option : undefined;
      });
    }
  }, [dateFromKey, dateToKey, allOptions, values]);

  const error = useMemo(() => getError(dateToKey) || getError(dateFromKey), [dateFromKey, dateToKey, getError]);
  const apiError = useMemo(() => apiErrors?.[dateToKey] || apiErrors?.[dateFromKey], [
    apiErrors,
    dateFromKey,
    dateToKey,
  ]);

  const CustomInput = useCallback(
    ({ InputProps: MuiInputProps, ...params }) => (
      <TextField
        { ...params }
        required={ true }
        variant="outlined"
        label={ formatMessage({ id: IntlKeys.timeRange }) }
        onBlur={ handleBlur }
        error={ Boolean(error) || apiError }
        helperText={ error || apiError }
        InputProps={ {
          ...MuiInputProps,
        } }
      />
    ),
    [apiError, error, formatMessage, handleBlur],
  );

  return (
    <StyledFormControl variant="outlined" className={ className }>
      <Autocomplete
        { ...staticProps }
        value={ currentValue || null }
        options={ allOptions }
        getOptionDisabled={ (option) => {
          return !enabledOptions.map(o => o.value).some(value => value.dateFrom === option.value.dateFrom && value.dateTo === option.value.dateTo);
        } }
        onChange={ handleDatePeriodChange }
        filterSelectedOptions
        renderInput={ CustomInput }
        disableClearable={ false }
        disabled={ disabled }
      />
    </StyledFormControl>
  );
};

export default PeriodDatePicker;

