import { TableConfig } from 'lib/tables/TableConfig';
import { TableTab } from 'lib/tables/TableTab';
import { HybridView } from 'lib/tables/HybridView';

import { DELIVERIES_AUTHORISED_SUPPLIERS } from 'store/entities/referenceTables/deliveriesAuthorisedSuppliers/config';

const { columns, view, serialized } = DELIVERIES_AUTHORISED_SUPPLIERS.DeliveriesAuthorisedSuppliers;

export const deliveriesAuthorisedSuppliersTableConfig = new TableConfig(serialized, [
  new TableTab({
    tableOptions: {
      sorting: true,
      search: false,
    },
    label: view,
    dataKey: serialized,
    rowMenu: () => null,
    rowContextMenu: undefined,
    headers: [
      new HybridView({
        settings: {
          title: columns.feedSupplierName.view,
          field: columns.feedSupplierName.serialized,
        },
      }),
      new HybridView({
        settings: {
          title: columns.supplierNumber.view,
          field: columns.supplierNumber.serialized,
        },
      }),
    ],
  }),
]);
