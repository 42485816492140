import { lazy } from 'react';

import { ChildRouteType, RouteType } from './index';
import { generateAppRoutes } from './utils';
import { PERMISSIONS } from 'store/auth/permissions';
import { FILTER_ENTITY_TYPES } from 'store/filters/reducer';
// import WithBreadcrumbs from './WithBreadcrumbs';
import { TitlesKeys } from 'lib/localization/keys/titles';
import { IntlKeys } from 'lib/localization/keys/__keys';
import { makeUrl } from 'routes/utils';

const SignIn = lazy(() => import('pages/auth/signIn/SignIn'));
const SignUp = lazy(() => import('pages/auth/signUp/SignUp'));
const PasswordRestore = lazy(() => import('pages/auth/password/Password'));
const PasswordRestoreExpired = lazy(() => import('pages/auth/password/PasswordExpired'));
const AcceptInvite = lazy(() => import('pages/auth/acceptInvite'));
const PasswordUpdate = lazy(() => import('pages/auth/passwordUpdate'));

const UploadExcelVariant = lazy(() => import('pages/upload/UploadExcelVariant'));
const MapPage = lazy(() => import('pages/map/Map'));

const InvoiceMessages = lazy(() => import('pages/invoiceMessages'));
const InvoiceDetails = lazy(() => import('pages/invoiceDetails'));
const Deviations = lazy(() => import('pages/certificates/kiwa/weekly/deviations'));
const KiwaDailyCertificates = lazy(() => import('pages/certificates/kiwa/daily'));
const FarmsCertificates = lazy(() => import('pages/certificates/kiwa/weekly/farms'));
const Recognitions = lazy(() => import('pages/certificates/kiwa/weekly/recognitions'));
const IKBCertificates = lazy(() => import('pages/certificates/ikb'));
const WelfareCertificates = lazy(() => import('pages/certificates/welfare'));
const Slaughter = lazy(() => import('pages/slaughter'));
const SlaughterDetails = lazy(() => import('pages/slaughter-details'));
const SlaughterPriceCorrections = lazy(() => import('pages/slaughter-price-corrections'));
const SlaughterChargeOrPremiums = lazy(() => import('pages/slaughter-charge-or-premiums'));
const Genetics = lazy(() => import('pages/genetics'));
const OrganizationPassports = lazy(() => import('pages/organizationPassports'));
const CfpCalculations = lazy(() => import('pages/cfpCalculations'));
const MyAlerts = lazy(() => import('pages/myAlerts'));
const MovementEvents = lazy(() => import('pages/movementEvents'));
const VeterinarianData = lazy(() => import('pages/veterinarianData'));
const DataCatalogue = lazy(() => import('pages/dataCatalogue'));
const VriHome = lazy(() => import('pages/vri/home'));
const VriEpg = lazy(() => import('pages/vri/epg'));
const VriHealthBalance = lazy(() => import('pages/vri/health-balance'));
const VriBalanceBase = lazy(() => import('pages/vri/balance-base'));
const VriReportingTool = lazy(() => import('pages/vri/reporting-tool'));
const VriUploads = lazy(() => import('pages/vri/uploads'));
const VriUploadDetails = lazy(() => import('pages/vri/database'));
const VriCalculatedHome = lazy(() => import('pages/vri-calculated/home'));
const VriCalculatedHealthBalance = lazy(() => import('pages/vri-calculated/health-balance'));
const VriCalculatedBalanceBase = lazy(() => import('pages/vri-calculated/balance-base'));
const VriCalculatedReportingTool = lazy(() => import('pages/vri-calculated/reporting-tool'));
const VriCalculatedEpg = lazy(() => import('pages/vri-calculated/epg'));
const Farm = lazy(() => import('pages/farms'));
const FarmStables = lazy(() => import('pages/farms/stables'));
const FarmCreateReports = lazy(() => import('pages/farms/reports/create'));
const FarmUpdateReport = lazy(() => import('pages/farms/reports/update'));
const FarmUpdates = lazy(() => import('pages/farms/updates'));
const Delivery = lazy(() => import('pages/delivery/messages'));
const DeliverySegment = lazy(() => import('pages/delivery/segment'));
const DeliverySegmentContents = lazy(() => import('pages/delivery/segmentContents'));
const Dashboard = lazy(() => import('pages/dashboard'));
const FeedProvenanceFileValidationTool = lazy(() => import('pages/admin/feedProvenanceFileValidationTool'));
const TemplateConfigs = lazy(() => import('pages/admin/templateConfigs'));
const TemplateConfigPage = lazy(() => import('pages/admin/templateConfigs/form'));
const AdminOrganizations = lazy(() => import('pages/admin/organizations'));
const AdminUserPassports = lazy(() => import('pages/admin/passports'));
const AdminCreateOrganization = lazy(() => import('pages/admin/createOrganization'));
const AdminUploads = lazy(() => import('pages/admin/uploads'));
const OrganizationDetails = lazy(() => import('pages/admin/organization'));
const AdminUsers = lazy(() => import('pages/admin/users'));
const AdminConcepts = lazy(() => import('pages/admin/concepts'));
const AdminUpdateLogs = lazy(() => import('pages/admin/updateLogs'));
const AdminPermissionProfileTemplates = lazy(() => import('pages/admin/permissionProfileTemplates'));
const AdminCreatePermissionProfileTemplate = lazy(() => import('pages/admin/permissionProfileTemplateManage/create'));
const AdminUpdatePermissionProfileTemplate = lazy(() => import('pages/admin/permissionProfileTemplateManage/update'));
const AnimalPassport = lazy(() => import('pages/animalPassport'));
const FarmerOrganizations = lazy(() => import('pages/organizations'));
const CertificatesCardView = lazy(() => import('pages/certificates/certificatesCardView'));
const JoindataParticipations = lazy(() => import('pages/joindata/participations'));
const JoindataRequestedParticipations = lazy(() => import('pages/joindata/requested-participations'));
const JoindataUnmatchedOrganizations = lazy(() => import('pages/joindata/unmatched-organizations'));
const Transports = lazy(() => import('pages/transports/main'));
const TransportLoadings = lazy(() => import('pages/transports/loadings'));
const TransportUnloadings = lazy(() => import('pages/transports/unloadings'));
const CompanyReportCreate = lazy(() => import('pages/farmerInput/companyReports/create'));
const CompanyReportEdit = lazy(() => import('pages/farmerInput/companyReports/edit'));
const CompanyReportAdminView = lazy(() => import('pages/farmerInput/companyReports/admin-view'));
const CompanyReportSurveyJsForm = lazy(() => import('pages/farmerInputSurveyJs/companyReportsSurveyJs/form'));
const CompanyData = lazy(() => import('pages/farmerInput/companyReports/home'));
const ByProductReportCreate = lazy(() => import('pages/farmerInput/byProducts/create'));
const ByProductReportEdit = lazy(() => import('pages/farmerInput/byProducts/edit'));
const ByProductReportAdminView = lazy(() => import('pages/farmerInput/byProducts/admin-view'));
const ByProductReportSurveyJsForm = lazy(() => import('pages/farmerInputSurveyJs/byProductsReportsSurveyJs/form'));
const ByProducts = lazy(() => import('pages/farmerInput/byProducts/home'));
const FermentationReportCreate = lazy(() => import('pages/farmerInput/fermentation/create'));
const FermentationReportEdit = lazy(() => import('pages/farmerInput/fermentation/edit'));
const FermentationReportAdminView = lazy(() => import('pages/farmerInput/fermentation/admin-view'));
const FermentationReportSurveyJsForm = lazy(() => import('pages/farmerInputSurveyJs/fermentationReportsSurveyJs/form'));
const Fermentation = lazy(() => import('pages/farmerInput/fermentation/home'));
const ManureReportCreate = lazy(() => import('pages/farmerInput/manure/create'));
const ManureReportEdit = lazy(() => import('pages/farmerInput/manure/edit'));
const ManureReportAdminView = lazy(() => import('pages/farmerInput/manure/admin-view'));
const ManureReportSurveyJsForm = lazy(() => import('pages/farmerInputSurveyJs/manureReportsSurveyJs/form'));
const Manure = lazy(() => import('pages/farmerInput/manure/home'));
const FarmerInputUpdatesLog = lazy(() => import('pages/farmerInput/updatesLog'));
const ManageUploads = lazy(() => import('pages/referenceTables/manageUploads/home'));
const ManageUploadEditPage = lazy(() => import('pages/referenceTables/manageUploads/edit'));
const FarmManagementCategories = lazy(() => import('pages/farmManagementData/categories'));
const FarmManagement = lazy(() => import('pages/farmManagementData/ftpUpdates'));
const CarbonFootprintsPage = lazy(() => import('pages/carbonFootprints/home'));
const CarbonFootprintEditPage = lazy(() => import('pages/carbonFootprints/edit'));
const MonitoringDashboardSuppliers = lazy(() => import('pages/monitoringDashboard/suppliers'));
const VriComparisonTool = lazy(() => import('pages/vriComparisonTool/home'));
const VriComparisonToolHealthBalance = lazy(() => import('pages/vriComparisonTool/healthBalance'));
const VriComparisonToolBalanceWithRespectToBase = lazy(() =>
  import('pages/vriComparisonTool/balanceWithRespectToBase'),
);
const VriComparisonToolVriTool = lazy(() => import('pages/vriComparisonTool/vriTool'));
const VriComparisonToolEpg = lazy(() => import('pages/vriComparisonTool/epg'));
const MonitoringDashboardUsersStatistic = lazy(() => import('pages/monitoringDashboard/users'));
const MonitoringDashboardData = lazy(() => import('pages/monitoringDashboard/data'));
const RawMaterialsOrigin = lazy(() => import('pages/rawMaterialsOrigin'));
const HfmOutputPage = lazy(() => import('pages/hfmOutput/home'));
const HfmOutputEditPage = lazy(() => import('pages/hfmOutput/edit'));

const ErrorsLogPage = lazy(() => import('pages/errorsLog'));

const EmptyComponent = () => null;

/**
 * path - partial url (relative to parent route path)
 * key - (should be unique) used to getUrl helper to find route object
 */
export const nestedRoutes: RouteType[] = [
  {
    path: '/',
    key: 'home',
    docTitle: TitlesKeys.dashboard,
    isDefault: true,
    // docTitle: ({ location }) => ` ${location.key}`,
    // filterType: FILTER_ENTITY_TYPES.BATCHES,
    component: Dashboard,
    routes: [
      {
        path: '/auth',
        key: 'auth',
        breadcrumbsLabel: 'Auth',
        docTitle: TitlesKeys.signIn,
        withBreadcrumbs: false,
        restrictions: { [PERMISSIONS.ACCESS_AUTHORIZED_CONTENT]: false },
        component: EmptyComponent,
        routes: [
          {
            path: '/accept-invite',
            key: 'authAcceptInvite',
            breadcrumbsLabel: 'Accept Invite',
            docTitle: TitlesKeys.signIn,
            withBreadcrumbs: false,
            restrictions: { [PERMISSIONS.ACCESS_AUTHORIZED_CONTENT]: false },
            component: AcceptInvite,
          },
          {
            path: '/sign-in',
            key: 'signIn',
            breadcrumbsLabel: 'Sign In',
            docTitle: TitlesKeys.signIn,
            withBreadcrumbs: false,
            restrictions: { [PERMISSIONS.ACCESS_AUTHORIZED_CONTENT]: false },
            component: SignIn,
          },
          {
            path: '/sign-up',
            key: 'signUp',
            breadcrumbsLabel: 'Sign Up',
            docTitle: TitlesKeys.signUp,
            withBreadcrumbs: false,
            restrictions: { [PERMISSIONS.ACCESS_AUTHORIZED_CONTENT]: false },
            component: SignUp,
          },
          {
            path: '/restore-password',
            key: 'authRestorePassword',
            breadcrumbsLabel: 'Restore Password',
            docTitle: TitlesKeys.password,
            withBreadcrumbs: false,
            restrictions: { [PERMISSIONS.ACCESS_AUTHORIZED_CONTENT]: false },
            component: PasswordRestore,
          },
          {
            path: '/restore-password-expired',
            key: 'authRestorePasswordExpired',
            breadcrumbsLabel: 'Restore Password Expired',
            docTitle: TitlesKeys.password,
            withBreadcrumbs: false,
            restrictions: { [PERMISSIONS.ACCESS_AUTHORIZED_CONTENT]: false },
            component: PasswordRestoreExpired,
          },
          {
            path: '/update-password',
            key: 'authUpdatePassword',
            breadcrumbsLabel: 'Update Password',
            docTitle: TitlesKeys.password,
            withBreadcrumbs: false,
            internalComponentAuth: true,
            component: PasswordUpdate,
          },
        ],
      },
      {
        path: '/map',
        key: 'map',
        breadcrumbsLabel: 'Map',
        docTitle: TitlesKeys.map,
        withBreadcrumbs: false,
        component: MapPage,
      },
      {
        path: '/admin',
        exact: true,
        key: 'admin',
        breadcrumbsLabel: 'Admin',
        docTitle: TitlesKeys.admin,
        withBreadcrumbs: false,
        component: EmptyComponent,
        restrictions: { [PERMISSIONS.ACCESS_ADMIN_PANEL]: true },
        routes: [
          {
            path: '/organizations',
            key: 'adminOrganizations',
            breadcrumbsLabel: 'Admin Organizations',
            docTitle: TitlesKeys.admin,
            component: AdminOrganizations,
            restrictions: { [PERMISSIONS.ACCESS_ADMIN_PANEL]: true },
            endpoint: 'admin/organizations',
            routes: [
              {
                path: '/create',
                key: 'adminCreateOrganization',
                breadcrumbsLabel: 'Create Organization',
                docTitle: IntlKeys.titleCreateOrganization,
                component: AdminCreateOrganization,
                restrictions: { [PERMISSIONS.ACCESS_ADMIN_PANEL]: true },
              },
              {
                path: '/:id/:userPassportId?',
                key: 'adminOrganizationDetails',
                docTitle: TitlesKeys.admin,
                withBreadcrumbs: false,
                component: OrganizationDetails,
                restrictions: { [PERMISSIONS.ACCESS_ADMIN_PANEL]: true },
              },
            ],
          },
          {
            path: '/uploads/:entity',
            key: 'adminUploads',
            breadcrumbsLabel: 'Uploads',
            docTitle: IntlKeys.titleUploads,
            component: AdminUploads,
            restrictions: { [PERMISSIONS.ACCESS_ADMIN_PANEL]: true },
          },
          {
            path: '/feed-provenance-file-validity-tool',
            key: 'feedProvenanceFileValidityTool',
            breadcrumbsLabel: 'Feed Provenance File Validity Tool',
            docTitle: IntlKeys.titleFeedProvenanceValidityTool,
            component: FeedProvenanceFileValidationTool,
            restrictions: { [PERMISSIONS.ACCESS_ADMIN_PANEL]: true },
          },
          {
            path: '/template-configs',
            key: 'templateConfigs',
            breadcrumbsLabel: 'Survey Templates Configuration',
            docTitle: IntlKeys.titleTemplateConfigs,
            component: TemplateConfigs,
            restrictions: { [PERMISSIONS.ACCESS_ADMIN_PANEL]: true },
            routes: [
              {
                path: '/new',
                key: 'templateConfigCreate',
                breadcrumbsLabel: 'New Survey Template Configuration',
                docTitle: IntlKeys.titleTemplateConfigNew,
                component: TemplateConfigPage,
                restrictions: { [PERMISSIONS.ACCESS_ADMIN_PANEL]: true },
              },
              {
                path: '/:id',
                key: 'templateConfigUpdate',
                breadcrumbsLabel: 'Edit Survey Template Configuration',
                docTitle: IntlKeys.titleTemplateConfigEdit,
                component: TemplateConfigPage,
                restrictions: { [PERMISSIONS.ACCESS_ADMIN_PANEL]: true },
              },
            ]
          },
          {
            path: '/users',
            key: 'adminUsers',
            breadcrumbsLabel: 'Admin Users',
            docTitle: TitlesKeys.admin,
            component: AdminUsers,
            restrictions: { [PERMISSIONS.ACCESS_ADMIN_PANEL]: true },
            endpoint: 'admin/users',
          },
          {
            path: '/concepts',
            key: 'adminConcepts',
            breadcrumbsLabel: 'Admin Concepts',
            docTitle: IntlKeys.concepts,
            component: AdminConcepts,
            restrictions: { [PERMISSIONS.ACCESS_ADMIN_PANEL]: true },
            endpoint: 'admin/concepts',
          },
          {
            path: '/passports',
            key: 'adminUserPassports',
            breadcrumbsLabel: 'Admin User Passports',
            docTitle: TitlesKeys.admin,
            component: AdminUserPassports,
            restrictions: { [PERMISSIONS.ACCESS_ADMIN_PANEL]: true },
            endpoint: 'user_passports',
          },
          {
            path: '/updates',
            key: 'adminUpdateLogs',
            breadcrumbsLabel: 'Admin Updates Log',
            docTitle: TitlesKeys.farmUpdates,
            component: AdminUpdateLogs,
            restrictions: { [PERMISSIONS.ACCESS_ADMIN_PANEL]: true },
            endpoint: 'admin/update_logs',
          },
          {
            path: '/permission-rules',
            key: 'permissionProfileTemplates',
            breadcrumbsLabel: 'Permission rules',
            docTitle: TitlesKeys.admin,
            component: AdminPermissionProfileTemplates,
            restrictions: { [PERMISSIONS.ACCESS_ADMIN_PANEL]: true },
            routes: [
              {
                path: '/create',
                key: 'createPermissionProfileTemplate',
                breadcrumbsLabel: 'Create Permission Profile Template',
                docTitle: TitlesKeys.admin,
                component: AdminCreatePermissionProfileTemplate,
                restrictions: { [PERMISSIONS.ACCESS_ADMIN_PANEL]: true },
              },
              {
                path: '/update/:id',
                key: 'updatePermissionProfileTemplate',
                breadcrumbsLabel: 'Update Permission Profile Template',
                docTitle: TitlesKeys.admin,
                component: AdminUpdatePermissionProfileTemplate,
                restrictions: { [PERMISSIONS.ACCESS_ADMIN_PANEL]: true },
              },
            ],
          },
        ],
      },
      {
        path: '/certificates',
        key: 'certificatesUrlRoot',
        component: CertificatesCardView,
        breadcrumbsLabel: 'Certificates',
        docTitle: IntlKeys.certificates,
        filterType: FILTER_ENTITY_TYPES.CERTIFICATES_ROOT,
        routes: [
          {
            path: '/kiwa',
            key: 'certificates',
            breadcrumbsLabel: 'Certificates',
            docTitle: TitlesKeys.certificatesDaily,
            component: KiwaDailyCertificates,
            filterType: FILTER_ENTITY_TYPES.CERTIFICATES_DAILY,
            endpoint: 'certificates',
            routes: [
              {
                path: '/deviations',
                key: 'deviations',
                breadcrumbsLabel: 'Deviations',
                docTitle: TitlesKeys.certificatesDeviations,
                component: Deviations,
                filterType: FILTER_ENTITY_TYPES.CERTIFICATES_WEEKLY_DEVIATIONS,
                endpoint: 'deviations',
              },
              {
                path: '/farms',
                key: 'certificatesFarms',
                breadcrumbsLabel: 'Farms',
                docTitle: TitlesKeys.certificatesFarms,
                component: FarmsCertificates,
                filterType: FILTER_ENTITY_TYPES.CERTIFICATES_WEEKLY_FARMS,
                endpoint: 'farms',
              },
              {
                path: '/recognitions',
                key: 'recognitions',
                breadcrumbsLabel: 'Recognitions',
                docTitle: TitlesKeys.certificatesRecognitions,
                component: Recognitions,
                filterType: FILTER_ENTITY_TYPES.CERTIFICATES_WEEKLY_RECOGNITIONS,
                endpoint: 'recognitions',
              },
            ],
          },
          {
            path: '/ikb',
            key: 'ikbCertificates',
            breadcrumbsLabel: 'IKB Certificates',
            docTitle: IntlKeys.titleCertificatesIKB,
            component: IKBCertificates,
            filterType: FILTER_ENTITY_TYPES.CERTIFICATES_IKB,
            endpoint: 'ikb_validity_certificates',
          },
          {
            path: '/welfare',
            key: 'welfareCertificates',
            breadcrumbsLabel: 'Welfare Certificates',
            docTitle: IntlKeys.titleCertificatesWelfare,
            component: WelfareCertificates,
            filterType: FILTER_ENTITY_TYPES.CERTIFICATES_WELFARE,
            endpoint: 'welfare_validity_certificates',
          },
        ],
      },
      {
        path: '/invoices',
        key: 'invoices',
        breadcrumbsLabel: 'Invoices',
        docTitle: IntlKeys.titleInvoices,
        component: InvoiceMessages,
        filterType: FILTER_ENTITY_TYPES.INVOICE_MESSAGES,
        endpoint: 'invoice_messages',
      },
      {
        path: '/invoice-details/:id',
        key: 'invoiceDetails',
        breadcrumbsLabel: 'Invoice Details',
        docTitle: TitlesKeys.invoiceDetails,
        component: InvoiceDetails,
        filterType: FILTER_ENTITY_TYPES.INVOICE_DETAILS,
        endpoint: 'deliveries',
        predefinedParams: ({ id }) => ({ filter: { invoice_message_id: id } }),
      },
      {
        path: '/slaughter',
        key: 'slaughter',
        breadcrumbsLabel: 'Slaughter',
        docTitle: TitlesKeys.slaughter,
        component: Slaughter,
        endpoint: 'slaughter_messages',
        filterType: FILTER_ENTITY_TYPES.SLAUGHTER_MESSAGES,
      },
      {
        path: '/slaughter-details/:id',
        key: 'slaughterDetails',
        breadcrumbsLabel: 'Slaughter details',
        docTitle: IntlKeys.titleSlaughterDetails,
        component: SlaughterDetails,
        endpoint: 'slaughters',
        predefinedParams: ({ id }) => ({ filter: { slaughter_message_id: id } }),
      },
      {
        path: '/slaughter-price-corrections',
        exact: true,
        key: 'slaughterPriceCorrections',
        breadcrumbsLabel: 'Slaughter Price Corrections',
        docTitle: IntlKeys.slaughterPriceCorrections,
        component: SlaughterPriceCorrections,
        filterType: FILTER_ENTITY_TYPES.SLAUGHTER_PRICE_CORRECTIONS,
        endpoint: 'slaughter_price_corrections',
      },
      {
        path: '/slaughter-price-corrections/charge-or-premiums/:id',
        key: 'slaughterChargeOrPremiums',
        breadcrumbsLabel: 'Slaughter Charge Or Premiums',
        docTitle: IntlKeys.slaughterChargeOrPremiums,
        component: SlaughterChargeOrPremiums,
      },
      {
        path: '/genetic',
        key: 'genetic',
        breadcrumbsLabel: 'Genetica',
        docTitle: IntlKeys.titleGenetics,
        component: Genetics,
        endpoint: 'genetics',
        filterType: FILTER_ENTITY_TYPES.GENETICS,
      },
      {
        path: '/raw-materials-origin',
        key: 'rawMaterialsOrigin',
        breadcrumbsLabel: 'Raw Materials Origin',
        docTitle: IntlKeys.titleRawMaterialsOrigin,
        component: RawMaterialsOrigin,
        filterType: FILTER_ENTITY_TYPES.RAW_MATERIALS_ORIGIN,
      },
      {
        path: '/delivery-messages',
        key: 'deliveryMessages',
        breadcrumbsLabel: 'Delivery Messages',
        docTitle: IntlKeys.titleDeliveryMessages,
        component: Delivery,
        filterType: FILTER_ENTITY_TYPES.DELIVERY_MESSAGES,
        endpoint: 'delivery_messages',
      },
      {
        path: '/delivery-segment/:deliveryMessageId',
        key: 'deliverySegment',
        breadcrumbsLabel: 'Delivery Segment',
        docTitle: TitlesKeys.deliverySegment,
        component: DeliverySegment,
        endpoint: makeUrl`delivery_messages/${'deliveryMessageId'}/delivery_segments`,
      },
      {
        path: '/delivery-message/:deliveryMessageId/delivery-segments/:deliverySegmentId/delivery-segment-contents',
        key: 'deliverySegmentContents',
        breadcrumbsLabel: 'Delivery Segment  Contents',
        docTitle: TitlesKeys.deliverySegmentContents,
        component: DeliverySegmentContents,
        endpoint: makeUrl`delivery_messages/${'deliveryMessageId'}/delivery_segments/${'deliverySegmentId'}/delivery_segment_contents`,
      },
      {
        path: '/organization-passports',
        key: 'organizationPassports',
        breadcrumbsLabel: 'Organization Passports',
        docTitle: IntlKeys.titleUserPassports,
        component: OrganizationPassports,
        endpoint: 'user_passports',
      },
      {
        path: '/movement-events',
        key: 'movementEvents',
        breadcrumbsLabel: 'Movement Events',
        docTitle: IntlKeys.titleMovementEvents,
        component: MovementEvents,
        endpoint: 'movement_events',
        filterType: FILTER_ENTITY_TYPES.MOVEMENT_EVENTS,
      },
      {
        path: '/veterinarian-data',
        key: 'veterinarianData',
        breadcrumbsLabel: 'Veterinarian Data',
        docTitle: IntlKeys.titleVeterinarianData,
        component: VeterinarianData,
        endpoint: 'veterinarian_data',
        filterType: FILTER_ENTITY_TYPES.VETERINARIAN_DATA,
      },
      {
        path: '/upload-excel',
        key: 'uploadExcel',
        breadcrumbsLabel: 'Upload Document',
        docTitle: TitlesKeys.uploadDocument,
        restrictions: {
          [PERMISSIONS.UPLOAD_DOCUMENTS]: true,
        },
        component: UploadExcelVariant,
      },
      {
        path: '/data-catalogue',
        key: 'dataCatalogue',
        breadcrumbsLabel: 'Data Catalogue',
        docTitle: IntlKeys.titleDataCatalogue,
        withBreadcrumbs: false,
        component: DataCatalogue,
      },
      {
        path: '/vri',
        key: 'vriHome',
        breadcrumbsLabel: 'VRI',
        docTitle: IntlKeys.titleVri,
        withBreadcrumbs: false,
        component: VriHome,
        routes: [
          {
            path: '/epg',
            key: 'vriEpg',
            breadcrumbsLabel: 'EPG',
            docTitle: TitlesKeys.vriEpg,
            withBreadcrumbs: false,
            component: VriEpg,
          },
          {
            path: '/health-balance',
            key: 'vriHealthBalance',
            breadcrumbsLabel: 'Gezondheidssaldo',
            docTitle: TitlesKeys.vriHealthBalance,
            withBreadcrumbs: false,
            component: VriHealthBalance,
          },
          {
            path: '/balance-base',
            key: 'vriBalanceBase',
            breadcrumbsLabel: 'Saldo t.o.v. Basis',
            docTitle: TitlesKeys.vriBalanceBase,
            withBreadcrumbs: false,
            component: VriBalanceBase,
          },
          {
            path: '/reporting-tool',
            key: 'vriReportingTool',
            breadcrumbsLabel: 'VRI Reporting Tool',
            docTitle: TitlesKeys.vriReportingTool,
            withBreadcrumbs: false,
            component: VriReportingTool,
          },
          {
            path: '/uploads',
            key: 'uploads',
            breadcrumbsLabel: 'Database',
            docTitle: TitlesKeys.vriDatabase,
            withBreadcrumbs: false,
            component: VriUploads,
            endpoint: 'report_uploads',
          },
          {
            path: '/database/:id',
            key: 'database',
            breadcrumbsLabel: 'Database Reports',
            docTitle: TitlesKeys.vriDatabaseReports,
            withBreadcrumbs: false,
            component: VriUploadDetails,
            endpoint: 'reports',
            predefinedParams: ({ id }) => ({ filters: { report_upload_id: id } }),
          },
        ],
      },
      {
        path: '/vri-calculated',
        key: 'vriCalculatedHome',
        breadcrumbsLabel: 'VRI',
        docTitle: IntlKeys.titleVriCalculated,
        withBreadcrumbs: false,
        component: VriCalculatedHome,
        routes: [
          {
            path: '/epg',
            key: 'vriCalculatedEpg',
            breadcrumbsLabel: 'Vri Epg',
            docTitle: TitlesKeys.vriEpg,
            withBreadcrumbs: false,
            component: VriCalculatedEpg,
          },
          {
            path: '/health-balance',
            key: 'vriCalculatedHealthBalance',
            breadcrumbsLabel: 'Gezondheidssaldo',
            docTitle: TitlesKeys.vriHealthBalance,
            withBreadcrumbs: false,
            component: VriCalculatedHealthBalance,
          },
          {
            path: '/balance-base',
            key: 'vriCalculatedBalanceBase',
            breadcrumbsLabel: 'Saldo t.o.v. Basis',
            docTitle: TitlesKeys.vriBalanceBase,
            withBreadcrumbs: false,
            component: VriCalculatedBalanceBase,
          },
          {
            path: '/reporting-tool',
            key: 'vriCalculatedReportingTool',
            breadcrumbsLabel: 'VRI Reporting Tool',
            docTitle: TitlesKeys.vriReportingTool,
            withBreadcrumbs: false,
            component: VriCalculatedReportingTool,
          },
        ],
      },
      {
        path: '/farm',
        key: 'myFarm',
        breadcrumbsLabel: 'myFarm',
        docTitle: IntlKeys.titleMyCompany,
        withBreadcrumbs: false,
        component: Farm,
        routes: [
          {
            path: '/:id/stables',
            key: 'farmStables',
            breadcrumbsLabel: 'farmStables',
            docTitle: TitlesKeys.farmStables,
            withBreadcrumbs: false,
            component: FarmStables,
          },
          {
            path: '/:id/reports',
            key: 'farmReports',
            breadcrumbsLabel: 'farmReports',
            docTitle: TitlesKeys.farmReports,
            withBreadcrumbs: false,
            component: FarmCreateReports,
          },
          {
            path: '/report/:id/:reportType/edit',
            key: 'farmUpdateReport',
            breadcrumbsLabel: 'farmUpdateReport',
            docTitle: TitlesKeys.farmReports,
            withBreadcrumbs: false,
            component: FarmUpdateReport,
          },
          {
            path: '/updates',
            key: 'farmUpdates',
            breadcrumbsLabel: 'farmUpdates',
            docTitle: IntlKeys.titleFarmUpdates,
            withBreadcrumbs: false,
            component: FarmUpdates,
            endpoint: 'farm_events',
            filterType: FILTER_ENTITY_TYPES.FARM_EVENTS,
          },
        ],
      },
      {
        path: '/animal-passports',
        key: 'animalPassport',
        breadcrumbsLabel: 'animalPassports',
        docTitle: IntlKeys.titleAnimalPassport,
        withBreadcrumbs: false,
        restrictions: { [PERMISSIONS.LEVEL2]: true },
        component: AnimalPassport,
        filterType: FILTER_ENTITY_TYPES.ANIMAL_PASSPORTS,
      },
      {
        path: '/organizations',
        key: 'farmerOrganizations',
        breadcrumbsLabel: 'Organizations',
        docTitle: IntlKeys.titleAllOrganizations,
        withBreadcrumbs: false,
        restrictions: { [PERMISSIONS.SIMPLE_USER]: true },
        component: FarmerOrganizations,
        routes: [
          {
            path: '/:id/:userPassportId?',
            key: 'farmerOrganizationDetails',
            breadcrumbsLabel: 'Organization',
            docTitle: IntlKeys.titleMyCompany,
            withBreadcrumbs: false,
            restrictions: { [PERMISSIONS.SIMPLE_USER]: true },
            component: OrganizationDetails,
          },
        ],
      },
      {
        path: '/joindata',
        key: 'joindataBase',
        breadcrumbsLabel: 'Joindata',
        docTitle: IntlKeys.titleMyJoinData,
        withBreadcrumbs: false,
        component: EmptyComponent,
        routes: [
          {
            path: '/participations',
            key: 'joindataParticipations',
            breadcrumbsLabel: 'Participations',
            docTitle: IntlKeys.participationPlural,
            withBreadcrumbs: false,
            restrictions: { [PERMISSIONS.LEVEL2]: true },
            component: JoindataParticipations,
          },
          {
            path: '/requested-participations',
            key: 'joindataRequestedParticipations',
            breadcrumbsLabel: 'Participations',
            docTitle: IntlKeys.requestedParticipations,
            withBreadcrumbs: false,
            restrictions: { [PERMISSIONS.LEVEL1]: true },
            component: JoindataRequestedParticipations,
          },
          {
            path: '/unmatched-organizations',
            key: 'joindataUnmatchedOrganizations',
            breadcrumbsLabel: 'Unmatched Organization',
            docTitle: IntlKeys.unmatchedOrganizations,
            withBreadcrumbs: false,
            restrictions: { [PERMISSIONS.LEVEL1]: true },
            component: JoindataUnmatchedOrganizations,
          },
        ],
      },
      {
        path: '/transports',
        key: 'transportsBase',
        breadcrumbsLabel: 'Joindata',
        docTitle: IntlKeys.transports,
        withBreadcrumbs: false,
        component: Transports,
        filterType: FILTER_ENTITY_TYPES.TRANSPORTS,
        endpoint: 'transports',
        routes: [
          {
            path: '/:id/loadings',
            key: 'transportLoadings',
            breadcrumbsLabel: 'Transport Loadings',
            docTitle: IntlKeys.transportLoadings,
            withBreadcrumbs: false,
            component: TransportLoadings,
          },
          {
            path: '/:id/unloadings',
            key: 'transportUnloadings',
            breadcrumbsLabel: 'Transport Unloadings',
            docTitle: IntlKeys.transportUnloadings,
            withBreadcrumbs: false,
            component: TransportUnloadings,
          },
        ],
      },
      {
        path: '/farmer-input',
        key: 'farmerInput',
        breadcrumbsLabel: 'farmerInput',
        docTitle: IntlKeys.titleFarmerInput,
        withBreadcrumbs: false,
        component: EmptyComponent,
        routes: [
          {
            path: '/updates-log',
            key: 'farmerInputUpdatesLog',
            breadcrumbsLabel: 'Farmer Input Updates Log',
            docTitle: IntlKeys.titleFarmUpdates,
            component: FarmerInputUpdatesLog,
            restrictions: { [PERMISSIONS.LEVEL1]: true },
          },
          {
            path: '/company-reports',
            key: 'companyData',
            breadcrumbsLabel: 'Company Data',
            docTitle: IntlKeys.titleFarmerInput,
            component: CompanyData,
            filterType: FILTER_ENTITY_TYPES.COMPANY_REPORT_FILTER,
            routes: [
              {
                path: '/:id',
                key: 'adminCompanyDataView',
                breadcrumbsLabel: 'Company Data',
                docTitle: IntlKeys.titleCompanyReportView,
                component: CompanyReportAdminView,
                restrictions: { [PERMISSIONS.LEVEL1]: true },
              },
              {
                path: '/new',
                key: 'companyDataCreate',
                breadcrumbsLabel: 'Company Data',
                docTitle: IntlKeys.titleCompanyReportNew,
                component: CompanyReportCreate,
                restrictions: { [PERMISSIONS.LEVEL2]: true },
              },
              {
                path: '/:id',
                key: 'companyDataUpdate',
                breadcrumbsLabel: 'Company Data',
                docTitle: IntlKeys.titleCompanyReportEdit,
                component: CompanyReportEdit,
                restrictions: { [PERMISSIONS.LEVEL2]: true },
              },
            ],
          },
          {
            path: '/product-reports',
            key: 'byProducts',
            breadcrumbsLabel: 'By Products',
            docTitle: IntlKeys.titleFarmerInput,
            component: ByProducts,
            filterType: FILTER_ENTITY_TYPES.FARMER_INPUT,
            routes: [
              {
                path: '/:id',
                key: 'adminByProductsView',
                breadcrumbsLabel: 'By Products',
                docTitle: IntlKeys.titleByProductsView,
                component: ByProductReportAdminView,
                restrictions: { [PERMISSIONS.LEVEL1]: true },
              },
              {
                path: '/new',
                key: 'byProductsCreate',
                breadcrumbsLabel: 'By Products',
                docTitle: IntlKeys.titleByProductsNew,
                component: ByProductReportCreate,
                restrictions: { [PERMISSIONS.LEVEL2]: true },
              },
              {
                path: '/:id',
                key: 'byProductsUpdate',
                breadcrumbsLabel: 'By Products',
                docTitle: IntlKeys.titleByProductsEdit,
                component: ByProductReportEdit,
                restrictions: { [PERMISSIONS.LEVEL2]: true },
              },
            ],
          },
          {
            path: '/fermentation-reports',
            key: 'fermentation',
            breadcrumbsLabel: 'Fermentation',
            docTitle: IntlKeys.titleFarmerInput,
            component: Fermentation,
            filterType: FILTER_ENTITY_TYPES.FARMER_INPUT,
            routes: [
              {
                path: '/:id',
                key: 'adminFermentationView',
                breadcrumbsLabel: 'Fermentation',
                docTitle: IntlKeys.titleFermentationView,
                component: FermentationReportAdminView,
                restrictions: { [PERMISSIONS.LEVEL1]: true },
              },
              {
                path: '/new',
                key: 'fermentationCreate',
                breadcrumbsLabel: 'Fermentation',
                docTitle: IntlKeys.titleFermentationNew,
                component: FermentationReportCreate,
                restrictions: { [PERMISSIONS.LEVEL2]: true },
              },
              {
                path: '/:id',
                key: 'fermentationUpdate',
                breadcrumbsLabel: 'Fermentation',
                docTitle: IntlKeys.titleFermentationEdit,
                component: FermentationReportEdit,
                restrictions: { [PERMISSIONS.LEVEL2]: true },
              },
            ],
          },
          {
            path: '/manure-reports',
            key: 'manure',
            breadcrumbsLabel: 'Manure',
            docTitle: IntlKeys.titleFarmerInput,
            component: Manure,
            filterType: FILTER_ENTITY_TYPES.FARMER_INPUT,
            routes: [
              {
                path: '/:id',
                key: 'adminManureView',
                breadcrumbsLabel: 'Manure',
                docTitle: IntlKeys.titleManureView,
                component: ManureReportAdminView,
                restrictions: { [PERMISSIONS.LEVEL1]: true },
              },
              {
                path: '/new',
                key: 'manureCreate',
                breadcrumbsLabel: 'Manure',
                docTitle: IntlKeys.titleManureNew,
                component: ManureReportCreate,
                restrictions: { [PERMISSIONS.LEVEL2]: true },
              },
              {
                path: '/:id',
                key: 'manureUpdate',
                breadcrumbsLabel: 'Manure',
                docTitle: IntlKeys.titleManureEdit,
                component: ManureReportEdit,
                restrictions: { [PERMISSIONS.LEVEL2]: true },
              },
            ],
          },
        ],
      },
      {
        path: '/farmer-input-survey-js',
        key: 'farmerInputSurveyJs',
        breadcrumbsLabel: 'farmerInput',
        docTitle: IntlKeys.titleFarmerInput,
        withBreadcrumbs: false,
        component: EmptyComponent,
        routes: [
          {
            path: '/company-reports',
            key: 'companyDataSurveyJs',
            breadcrumbsLabel: 'Farmer Input (SurveyJS)',
            docTitle: IntlKeys.titleFarmerInputSurveyJs,
            component: CompanyData,
            filterType: FILTER_ENTITY_TYPES.COMPANY_REPORT_FILTER,
            routes: [
              {
                path: '/new',
                key: 'companyDataSurveyJsCreate',
                breadcrumbsLabel: 'Company Data (SurveyJs)',
                docTitle: IntlKeys.titleCompanyReportNew,
                component: CompanyReportSurveyJsForm,
                restrictions: { [PERMISSIONS.LEVEL2]: true },
              },
              {
                path: '/:id',
                key: 'companyDataSurveyJsUpdate',
                breadcrumbsLabel: 'Company Data (SurveyJs)',
                docTitle: IntlKeys.titleCompanyReportEdit,
                component: CompanyReportSurveyJsForm,
                restrictions: { [PERMISSIONS.LEVEL2]: true },
              },
            ]
          },
          {
            path: '/product-reports',
            key: 'byProductsSurveyJs',
            breadcrumbsLabel: 'By Products (SurveyJS)',
            docTitle: IntlKeys.titleFarmerInputSurveyJs,
            component: ByProducts,
            filterType: FILTER_ENTITY_TYPES.FARMER_INPUT,
            routes: [
              {
                path: '/new',
                key: 'byProductsSurveyJsCreate',
                breadcrumbsLabel: 'By Products (SurveyJs)',
                docTitle: IntlKeys.titleByProductsNew,
                component: ByProductReportSurveyJsForm,
                restrictions: { [PERMISSIONS.LEVEL2]: true },
              },
              {
                path: '/:id',
                key: 'byProductsSurveyJsUpdate',
                breadcrumbsLabel: 'By Products (SurveyJs)',
                docTitle: IntlKeys.titleByProductsEdit,
                component: ByProductReportSurveyJsForm,
                restrictions: { [PERMISSIONS.LEVEL2]: true },
              },
            ]
          },
          {
            path: '/fermentation-reports',
            key: 'fermentationSurveyJs',
            breadcrumbsLabel: 'Fermentation (SurveyJs)',
            docTitle: IntlKeys.titleFarmerInputSurveyJs,
            component: Fermentation,
            filterType: FILTER_ENTITY_TYPES.FARMER_INPUT,
            routes: [
              {
                path: '/new',
                key: 'fermentationSurveyJsCreate',
                breadcrumbsLabel: 'Fermentation (SurveyJs)',
                docTitle: IntlKeys.titleFermentationNew,
                component: FermentationReportSurveyJsForm,
                restrictions: { [PERMISSIONS.LEVEL2]: true },
              },
              {
                path: '/:id',
                key: 'fermentationSurveyJsUpdate',
                breadcrumbsLabel: 'Fermentation (SurveyJs)',
                docTitle: IntlKeys.titleFermentationEdit,
                component: FermentationReportSurveyJsForm,
                restrictions: { [PERMISSIONS.LEVEL2]: true },
              },
            ],
          },
          {
            path: '/manure-reports',
            key: 'manureSurveyJs',
            breadcrumbsLabel: 'Manure (SurveyJs)',
            docTitle: IntlKeys.titleFarmerInputSurveyJs,
            component: Manure,
            filterType: FILTER_ENTITY_TYPES.FARMER_INPUT,
            routes: [
              {
                path: '/new',
                key: 'manureSurveyJsCreate',
                breadcrumbsLabel: 'Manure (SurveyJs)',
                docTitle: IntlKeys.titleManureNew,
                component: ManureReportSurveyJsForm,
                restrictions: { [PERMISSIONS.LEVEL2]: true },
              },
              {
                path: '/:id',
                key: 'manureSurveyJsUpdate',
                breadcrumbsLabel: 'Manure (SurveyJs)',
                docTitle: IntlKeys.titleManureEdit,
                component: ManureReportSurveyJsForm,
                restrictions: { [PERMISSIONS.LEVEL2]: true },
              },
            ],
          },
        ]
      },
      {
        path: '/manage-uploads',
        key: 'manageUploads',
        breadcrumbsLabel: 'Manage Uploads',
        docTitle: IntlKeys.titleManageUploads,
        withBreadcrumbs: false,
        component: ManageUploads,
        filterType: FILTER_ENTITY_TYPES.REFERENCE_TABLES,
        routes: [
          {
            path: '/:id/:uploadType',
            key: 'manageUploadUpdate',
            breadcrumbsLabel: 'Manage Uploads',
            docTitle: IntlKeys.titleManageUploadEdit,
            component: ManageUploadEditPage,
          },
        ],
      },
      {
        path: '/farm-management',
        key: 'farmManagement',
        breadcrumbsLabel: 'Farm Management Data',
        docTitle: IntlKeys.titleFarmManagementData,
        withBreadcrumbs: false,
        component: FarmManagement,
        filterType: FILTER_ENTITY_TYPES.FARM_MANAGEMENT_DATA,
        routes: [
          {
            path: '/:category',
            key: 'farmManagementCategories',
            breadcrumbsLabel: 'Farm Management Data',
            docTitle: IntlKeys.titleFarmManagementData,
            component: FarmManagementCategories,
            filterType: FILTER_ENTITY_TYPES.FARM_MANAGEMENT_CATEGORIES,
          },
        ],
      },
      {
        path: '/carbon-footprints',
        key: 'carbonFootprints',
        breadcrumbsLabel: 'Carbon Footprints',
        docTitle: IntlKeys.titleCarbonFootprints,
        withBreadcrumbs: false,
        component: CarbonFootprintsPage,
        filterType: FILTER_ENTITY_TYPES.CARBON_FOOTPRINT,
        routes: [
          {
            path: '/:id',
            key: 'carbonFootprintsEdit',
            breadcrumbsLabel: 'Carbon Footprints',
            docTitle: IntlKeys.titleCarbonFootprints,
            component: CarbonFootprintEditPage,
          },
        ],
      },
      {
        path: '/carbon-footprint-runs',
        key: 'carbonFootprintRuns',
        breadcrumbsLabel: 'Carbon Footprint Runs',
        docTitle: IntlKeys.titleCarbonFootprintRuns,
        withBreadcrumbs: false,
        component: CfpCalculations,
      },
      {
        path: '/my-alerts',
        key: 'myAlerts',
        breadcrumbsLabel: 'My Alerts',
        docTitle: IntlKeys.titleMyAlerts,
        withBreadcrumbs: false,
        component: MyAlerts,
      },
      {
        path: '/hfm-outputs',
        key: 'hfmOutputs',
        breadcrumbsLabel: 'HFM Output',
        docTitle: IntlKeys.titleHFMOutput,
        withBreadcrumbs: false,
        component: HfmOutputPage,
        filterType: FILTER_ENTITY_TYPES.HFM_OUTPUT,
        routes: [
          {
            path: '/:id',
            key: 'hfmOutputEdit',
            breadcrumbsLabel: 'HFM Output',
            docTitle: IntlKeys.titleHFMOutput,
            component: HfmOutputEditPage,
          },
        ],
      },
      {
        path: '/monitoring-dashboard',
        exact: true,
        key: 'monitoringDashboard',
        breadcrumbsLabel: 'Monitoring Dashboard',
        docTitle: IntlKeys.titleMonitoringDashboard,
        withBreadcrumbs: false,
        component: EmptyComponent,
        routes: [
          {
            path: '/suppliers',
            key: 'monitoringDashboardSuppliers',
            breadcrumbsLabel: 'Suppliers',
            docTitle: IntlKeys.titleMonitoringDashboard,
            component: MonitoringDashboardSuppliers,
            filterType: FILTER_ENTITY_TYPES.MONITORING_DASHBOARD,
          },
          {
            path: '/users',
            key: 'monitoringDashboardUsers',
            breadcrumbsLabel: 'Users',
            docTitle: IntlKeys.titleMonitoringDashboard,
            component: MonitoringDashboardUsersStatistic,
            filterType: FILTER_ENTITY_TYPES.MONITORING_DASHBOARD_STATISTIC,
          },
          {
            path: '/data',
            key: 'monitoringDashboardData',
            breadcrumbsLabel: 'Data',
            docTitle: IntlKeys.titleMonitoringDashboard,
            component: MonitoringDashboardData,
            filterType: FILTER_ENTITY_TYPES.MONITORING_DASHBOARD_DATA,
          },
        ],
      },
      {
        path: '/vri-comparison-tool',
        key: 'vriComparisonTool',
        breadcrumbsLabel: 'Vri Comparison Tool',
        docTitle: IntlKeys.titleVriComparisonTool,
        withBreadcrumbs: false,
        component: VriComparisonTool,
        routes: [
          {
            path: '/epg',
            key: 'vriComparisonToolEpg',
            breadcrumbsLabel: 'Vri Epg',
            docTitle: IntlKeys.titleVriComparisonTool,
            withBreadcrumbs: false,
            component: VriComparisonToolEpg,
          },
          {
            path: '/health-balance',
            key: 'vriComparisonToolHealthBalance',
            breadcrumbsLabel: 'Vri Health Balance',
            docTitle: IntlKeys.titleVriComparisonTool,
            withBreadcrumbs: false,
            component: VriComparisonToolHealthBalance,
          },
          {
            path: '/balance-with-respect-to-base',
            key: 'vriComparisonToolBalanceBase',
            breadcrumbsLabel: 'Vri Balance w.r.t. Base',
            docTitle: IntlKeys.titleVriComparisonTool,
            withBreadcrumbs: false,
            component: VriComparisonToolBalanceWithRespectToBase,
          },
          {
            path: '/vri-tool',
            key: 'vriComparisonToolVriTool',
            breadcrumbsLabel: 'Vri Tool',
            docTitle: IntlKeys.titleVriComparisonTool,
            withBreadcrumbs: false,
            component: VriComparisonToolVriTool,
          },
        ],
      },
      {
        path: '/errors-log',
        key: 'errorsLog',
        breadcrumbsLabel: 'Errors Log',
        docTitle: IntlKeys.titleErrorsLog,
        withBreadcrumbs: false,
        component: ErrorsLogPage,
        filterType: FILTER_ENTITY_TYPES.ERRORS_LOG,
      },
    ],
  },
];

const routes: ChildRouteType[] = generateAppRoutes(nestedRoutes).map(({ withBreadcrumbs = true, ...route }) => ({
  // restrictions: [Restrictions.AUTHORIZED],
  restrictions: { [PERMISSIONS.ACCESS_AUTHORIZED_CONTENT]: true },
  ...route,
  // uncomment here to enable breadcrumbs
  // component: withBreadcrumbs
  //   ? (props: object) => <WithBreadcrumbs route={route} {...props} />
  //   : route.component,
}));

if (process.env.NODE_ENV === 'development') {
  // Validate routes uniqueness
  const errors: any[] = [];

  //////////////////////////////////// check all keys and paths are unique ////////////////////////////////////////
  const keys = new Map(routes.map(({ key }) => [key, 0]));
  const paths = new Map(routes.map(({ path }) => [path, 0]));

  routes.forEach((route) => {
    keys.set(route.key, (keys.get(route.key) || 0) + 1);
    paths.set(route.path, (keys.get(route.path) || 0) + 1);
  });

  [keys, paths].forEach((_map) => {
    _map.forEach((val, key, map) => {
      if (val === 1) {
        // 1 entry is ok; more - duplicates; resolve manually
        map.delete(key);
      }
    });

    if (_map.size > 0) {
      errors.push([`Found possible duplicate routes: ${[..._map.keys()]}`]);
    }
  });
  //////////////////////////////////// check default route is only 1 //////////////////////////////////////////////
  const defaultCount = routes.reduce((acc, { isDefault }) => (isDefault ? acc + 1 : acc), 0);
  if (defaultCount > 1) {
    errors.push(['Multiple default routes found. Check routes']);
  }
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  if (errors.length) {
    errors.forEach((error) => {
      throw new Error(...error);
    });
  }
}

export default routes;
