import { DELIVERIES_AUTHORISED_SUPPLIERS_FIELDS } from 'lib/excel/config/referenceTables/deliveriesAutorisedSuppliers/structure';
import { DocumentData } from 'lib/excel/serilizers/Sheet';
import { DocumentViewer } from 'lib/excel/view/Document';
import { SheetViewer } from 'lib/excel/view/Sheet';
import { CellViewer } from 'lib/excel/view/Cell';

const { columns, serialized, view } = DELIVERIES_AUTHORISED_SUPPLIERS_FIELDS.DeliveriesAuthorisedSuppliers;

export const getDeliveriesAuthorisedSuppliersView = (data: DocumentData) => {
  return new DocumentViewer(data, [
    new SheetViewer(serialized, view, [
      new CellViewer({ serializedPropName: columns.feedSupplierName.serialized, viewPropName: columns.feedSupplierName.view }),
      new CellViewer({ serializedPropName: columns.supplierNumber.serialized, viewPropName: columns.supplierNumber.view }),
    ]),
  ]);
};
