import { TableConfig } from 'lib/tables/TableConfig';
import { TableTab } from 'lib/tables/TableTab';
import { HybridView } from 'lib/tables/HybridView';

import { SLAUGHTERHOUSE_DATA_VALUES } from 'store/entities/referenceTables/slaughterhouseDataValues/config';

const { columns, view, serialized } = SLAUGHTERHOUSE_DATA_VALUES.SlaughterhouseDataValues;

export const slaughterhouseDataValuesTableConfig = new TableConfig(serialized, [
  new TableTab({
    tableOptions: {
      sorting: true,
      search: false,
    },
    label: view,
    dataKey: serialized,
    rowMenu: () => null,
    rowContextMenu: undefined,
    headers: [
      new HybridView({
        settings: {
          title: columns.value.view,
          field: columns.value.serialized,
        },
      }),
      new HybridView({
        settings: {
          title: columns.unit.view,
          field: columns.unit.serialized,
        },
      }),
    ],
  }),
]);
