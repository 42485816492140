import { IntlKeys } from 'lib/localization/keys';

enum DeliveriesAuthorisedSuppliersSerialized {
  DeliveriesAuthorisedSuppliers = 'DeliveriesAuthorisedSuppliers',
}

enum DeliveriesAuthorisedSuppliersUnserialized {
  DeliveriesAuthorisedSuppliers = 'DeliveriesAuthorisedSuppliers',
}

export enum DeliveriesAuthorisedSuppliersSerializedHeaders {
  id = 'id',
  referenceUploadID = 'referenceUploadID',
  feedSupplierName = 'feedSupplierName',
  supplierNumber = 'supplierNumber',
  uploadType = 'uploadType',
  filename = 'filename',
  status = 'status',
  validDateFrom = 'validDateFrom',
  validDateTo = 'validDateTo',
  notes = 'notes',
  concepts = 'concepts',
  uploadedAt = 'uploadedAt',
  uploadedBy = 'uploadedBy',
}

export const DeliveriesAuthorisedSuppliersUnserializedHeaders = {
  id: 'id',
  referenceUploadID: ['deliveries_authorised_supplier_upload', 'id'],
  feedSupplierName: 'feed_supplier_name',
  supplierNumber: 'supplier_number',
  businessUnit: 'business_unit',
  uploadType: ['deliveries_authorised_supplier_upload', 'upload_type'],
  filename: ['deliveries_authorised_supplier_upload', 'file_name'],
  status: ['deliveries_authorised_supplier_upload', 'status'],
  validDateFrom: ['deliveries_authorised_supplier_upload', 'valid_from'],
  validDateTo: ['deliveries_authorised_supplier_upload', 'valid_to'],
  notes: ['deliveries_authorised_supplier_upload', 'notes'],
  concepts: ['deliveries_authorised_supplier_upload', 'concepts'],
  uploadedAt: ['deliveries_authorised_supplier_upload', 'uploaded_at'],
  uploadedBy: ['deliveries_authorised_supplier_upload', 'uploaded_by', 'full_name'],
} as const;

export const DELIVERIES_AUTHORISED_SUPPLIERS = {
  [DeliveriesAuthorisedSuppliersSerialized.DeliveriesAuthorisedSuppliers]: {
    serialized: DeliveriesAuthorisedSuppliersSerialized.DeliveriesAuthorisedSuppliers,
    unserialized: DeliveriesAuthorisedSuppliersUnserialized.DeliveriesAuthorisedSuppliers,
    view: IntlKeys.referenceTablesDeliveriesAuthorisedSuppliers,

    columns: {
      [DeliveriesAuthorisedSuppliersSerializedHeaders.id]: {
        serialized: DeliveriesAuthorisedSuppliersSerializedHeaders.id,
        unserialized: DeliveriesAuthorisedSuppliersUnserializedHeaders.id,
      },
      [DeliveriesAuthorisedSuppliersSerializedHeaders.referenceUploadID]: {
        serialized: DeliveriesAuthorisedSuppliersSerializedHeaders.referenceUploadID,
        unserialized: DeliveriesAuthorisedSuppliersUnserializedHeaders.referenceUploadID,
      },
      [DeliveriesAuthorisedSuppliersSerializedHeaders.feedSupplierName]: {
        serialized: DeliveriesAuthorisedSuppliersSerializedHeaders.feedSupplierName,
        unserialized: DeliveriesAuthorisedSuppliersUnserializedHeaders.feedSupplierName,
        view: IntlKeys.referenceTablesDeliveriesAuthorisedSuppliersFeedSupplierName,
      },
      [DeliveriesAuthorisedSuppliersSerializedHeaders.supplierNumber]: {
        serialized: DeliveriesAuthorisedSuppliersSerializedHeaders.supplierNumber,
        unserialized: DeliveriesAuthorisedSuppliersUnserializedHeaders.supplierNumber,
        view: IntlKeys.referenceTablesDeliveriesAuthorisedSuppliersSupplierNumber,
      },
      [DeliveriesAuthorisedSuppliersSerializedHeaders.status]: {
        serialized: DeliveriesAuthorisedSuppliersSerializedHeaders.status,
        unserialized: DeliveriesAuthorisedSuppliersUnserializedHeaders.status,
      },
      [DeliveriesAuthorisedSuppliersSerializedHeaders.uploadType]: {
        serialized: DeliveriesAuthorisedSuppliersSerializedHeaders.uploadType,
        unserialized: DeliveriesAuthorisedSuppliersUnserializedHeaders.uploadType,
      },
      [DeliveriesAuthorisedSuppliersSerializedHeaders.notes]: {
        serialized: DeliveriesAuthorisedSuppliersSerializedHeaders.notes,
        unserialized: DeliveriesAuthorisedSuppliersUnserializedHeaders.notes,
      },
      [DeliveriesAuthorisedSuppliersSerializedHeaders.concepts]: {
        serialized: DeliveriesAuthorisedSuppliersSerializedHeaders.concepts,
        unserialized: DeliveriesAuthorisedSuppliersUnserializedHeaders.concepts,
        view: IntlKeys.concepts,
      },
      [DeliveriesAuthorisedSuppliersSerializedHeaders.filename]: {
        serialized: DeliveriesAuthorisedSuppliersSerializedHeaders.filename,
        unserialized: DeliveriesAuthorisedSuppliersUnserializedHeaders.filename,
      },
      [DeliveriesAuthorisedSuppliersSerializedHeaders.validDateFrom]: {
        serialized: DeliveriesAuthorisedSuppliersSerializedHeaders.validDateFrom,
        unserialized: DeliveriesAuthorisedSuppliersUnserializedHeaders.validDateFrom,
      },
      [DeliveriesAuthorisedSuppliersSerializedHeaders.validDateTo]: {
        serialized: DeliveriesAuthorisedSuppliersSerializedHeaders.validDateTo,
        unserialized: DeliveriesAuthorisedSuppliersUnserializedHeaders.validDateTo,
      },
      [DeliveriesAuthorisedSuppliersSerializedHeaders.uploadedAt]: {
        serialized: DeliveriesAuthorisedSuppliersSerializedHeaders.uploadedAt,
        unserialized: DeliveriesAuthorisedSuppliersUnserializedHeaders.uploadedAt,
      },
      [DeliveriesAuthorisedSuppliersSerializedHeaders.uploadedBy]: {
        serialized: DeliveriesAuthorisedSuppliersSerializedHeaders.uploadedBy,
        unserialized: DeliveriesAuthorisedSuppliersUnserializedHeaders.uploadedBy,
      },
    },
  },
};
