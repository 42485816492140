import { MOVEMENT_EVENTS_APPROVED_UBNS_FIELDS } from 'lib/excel/config/referenceTables/movementEventsApprovedUbns/structure';
import { DisplayDocument } from 'lib/excel/display/DisplayDocument';
import { DisplayTab } from 'lib/excel/display/DisplayTab';
import { DisplayHeader } from 'lib/excel/display/DisplayHeader';

const { columns, view } = MOVEMENT_EVENTS_APPROVED_UBNS_FIELDS.MovementEventsApprovedUbns;

export const getMovementEventsApprovedUbnsDisplayModel = () => {
  return new DisplayDocument([
    new DisplayTab(view, [
      new DisplayHeader({ headerName: columns.ubn.serialized, extractPropName: columns.ubn.view }),
    ]),
  ]);
};
