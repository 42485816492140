import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { CellSerializer } from 'lib/excel/serilizers/Cell';
import { dateToFormattedString } from 'lib/excel/serilizers/utils';
import { ResourcesWrapper, ResourceWrapper } from 'lib/http/utils';
import { SERVICE_FIELDS } from 'lib/excel/config/service';
import _ from 'lodash';

export const UserPassportConcepts = 'UserPassportConcepts';

export interface UserPassportConceptSerialized {
  concept: string;
  id: number;
  dateFrom?: string;
  dateTo?: string;
  gln: string;
  // btw: string;
  vionRelationNumber: string;
  // new
  certificates: string[];
  certificatesOther: string;
  geneticSuppliers: string[];
  geneticSupplierOther: string;
  kiStations: string[];
  kiStationOther: string;
  sowLines: string[];
  sowLineOther: string;
  breedingProgram: string;
  sireLines: string[];
  sireLineOther: string;
  veterinaryPractice: string;
  veterinaryPracticeOther: string;
  feedSupplierOther: string;
  fmsNames: string[];
  fmsModuleAgrisysts: string[];
  fmsModuleAgroCoops: string[];
  fmsModuleAgrovisions: string[];
  feedingSystems: string[];
  feedingSystemOther: string;
  climateSystems: string[];
  climateSystemOther: string;
  //
  veterinarian: string;
  feedSuppliers: string;
  email: string;
  phone: string;
  place: string;
  postcode: string;
  street: string;
  vatNum: string;
  name: string;
  type: string;
  status: string;
  statusUpdatedBy: string;
  statusUpdatedAt: string;

  createdAt: string;
  updatedAt: string;
  updatedBy: {
    email: string;
    full_name: string;
  };
}

export interface UserPassportConceptPayload {
  concept?: string;
  id?: number;
  date_from?: string;
  date_to?: string;
  gln?: null | string;
  btw?: string;
  vion_relation_number?: string;
  // new
  certificates?: string[];
  certificates_other?: string;
  genetic_suppliers?: string[];
  genetic_supplier_other?: string;
  ki_stations?: string[];
  ki_station_other?: string;
  sow_lines?: string[];
  sow_line_other?: string;
  breeding_program?: string;
  sire_lines?: string[];
  sire_line_other?: string;
  veterinary_practice?: string;
  veterinary_practice_other?: string;
  feed_supplier_other?: string;
  fms_names?: string[];
  fms_module_agrisysts?: string[];
  fms_module_agro_coops?: string[];
  fms_module_agrovisions?: string[];
  feeding_systems?: string[];
  feeding_system_other?: string;
  climate_systems?: string[];
  climate_system_other?: string;
  //
  veterinarian?: string;
  feed_suppliers?: string;
  phone?: string;
  place?: string;
  postcode?: string;
  street?: string;
  status?: string;
  status_updated_by?: string;
  status_updated_at?: string;

  created_at?: string;
  updated_at?: string;
}

export const getSerializer = (data: DocumentData) => {
  const conceptFields = data.UserPassportConcepts
    .map(c => Object.keys(c))
    .reduce((acc, fields) => { fields.forEach(acc.add, acc); return acc }, new Set());
  const cellSerializers = [
    new CellSerializer(SERVICE_FIELDS.id.unserialized, SERVICE_FIELDS.id.serialized),
    ...(data ? Array.from(conceptFields.keys()).map(field => new CellSerializer(`${field}`, _.camelCase(`${field}`))): []),
  ]
  return new DocumentSerializer(data, [
    new SheetSerializer(UserPassportConcepts, UserPassportConcepts, cellSerializers),
  ]);
};

export const serializeUserPassportConcept = (serverData: ResourceWrapper<UserPassportConceptPayload>) => {
  const data = {
    [UserPassportConcepts]: [serverData.data.resource],
  };

  const serializer = getSerializer(data);

  return serializer.serialize()[UserPassportConcepts][0] as UserPassportConceptSerialized;
};

export const serializeUserPassportConcepts = (serverData: ResourcesWrapper<UserPassportConceptPayload>) => {
  const data = {
    [UserPassportConcepts]: serverData.data.resources,
  };

  const serializer = getSerializer(data);

  return serializer.serialize();
};

